import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { API_URL, IMAGE_URL } from "app/constants";
import { AuthService } from "app/shared/auth.service";
import { CalculateRetailOrderService } from "app/shared/calculateRetailOrder.service";
import { CommonService } from "app/shared/common.service";
import { EnrollSessionService } from "app/shared/enrollSession.service";
import { RestService } from "app/shared/rest.service";
import { SharedMethodService } from "app/shared/sharedMethod.service";
import { StepperService } from "app/shared/stepper.service";
import { ToastrService } from "ngx-toastr";
import { PrivacyDocumentPopupDialogComponent } from "./PrivacyDocumentDialog/privacy-document-dialog.component";

@Component({
  selector: 'app-newslettersignup',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './newslettersignup.component.html',
  styleUrls: ['./newslettersignup.component.scss']
})
export class NewsLetterSignupComponent implements OnInit{
  IMAGE_URL = IMAGE_URL;
  webalias: string;
  LoggedInUser: any;
  SignUpInfoForm: FormGroup;
  SubscribeClickCheck:boolean = false;
  dialogRef:any;
  @HostListener('window:beforeunload', ['$event'])
  clearStorage(event) {
    this._enrollSession.data.CustomerID === ''
    localStorage.clear();
    sessionStorage.clear();
  }
  customPreferences:any[] = [
    {id:"EnergyAndSleep",title:"Energy & Sleep",isChecked:false},
    {id:"MoodAndBrain",title:"Mood & Brain",isChecked:false},
    {id:"GutAndGlyphosateDetox",title:"Gut & Glyphosate Detox",isChecked:false},
    {id:"WeightManagement",title:"Weight Management",isChecked:false},
    {id:"GettingProductsPaidFor",title:"Getting Products Paid For",isChecked:false},
    {id:"EarningAdditionalIncome",title:"Earning Additional Income",isChecked:false},
    {id:"EnvironmentalTipsAndAdvocacy",title:"Environmental Tips & Advocacy",isChecked:false},
    {id:"CommunitySupportsAndInspiration",title:"Community Supports & Inspiration",isChecked:false}
  ]
  constructor(
    private _toastr: ToastrService,
    private _restService: RestService,
    private _activateRoute: ActivatedRoute,
    public _sharedMethodService: SharedMethodService,
    public _stepperService: StepperService,
    public _enrollSession: EnrollSessionService,
    private _matDialog: MatDialog,
    public _router: Router,
    public _auth: AuthService,
    public _calculateRetailOrderService: CalculateRetailOrderService,
    private _formBuilder: FormBuilder,
    public _commonService: CommonService
  ) {
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    var url = new URL(window.location.href);
    this.webalias = this._activateRoute.snapshot.queryParamMap?.get('giftcard') || url?.searchParams.get("giftcard") || sessionStorage.getItem('giftCardName');
    this.SignUpInfoForm = this.createSignUpInfoForm(this.LoggedInUser);
    let dataKeys = Object.keys(this._enrollSession.data)
    if(dataKeys.length === 0){
      this._enrollSession.data = { RetailOrderSummary: {}};
      this._enrollSession.data.RetailOrderSummary ??= {};
      this._enrollSession.data.RetailEnroll ??= {};
    }
    this._enrollSession.data.RetailOrderSummary['customerInfoData'] = this._enrollSession.data?.RetailOrderSummary?.customerInfoData || {};
    this._enrollSession.data.RetailOrderSummary['referredFormData'] = this._enrollSession.data?.RetailOrderSummary?.referredFormData || {};
    _enrollSession.data.RetailOrderSummary.referredFormData.referralCode = this.webalias;
  }

  ngOnInit(): void {
    if (this._auth.isLoggedIn()) {
      if (this._enrollSession.data.LoggedInUserData?.CustomerType == 20 || this._enrollSession.data.LoggedInUserData?.CustomerType == 22) {
        this.getImage(this._enrollSession.data.LoggedInUserData?.EnrollerID);
      } else {
        this.getImage(this._enrollSession.data.LoggedInUserData?.CustomerID);
      }
      this.getEnrollerDetails();
    } else {
      this.giftcardFunctionality();
    }
    this.SignUpInfoForm.valueChanges.subscribe(selectedValue => {
      this._enrollSession.data.RetailOrderSummary.customerInfoData = selectedValue;
      this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = selectedValue?.referralCode;
  });
    if (this._enrollSession.data.ReferredBy) {
      let referredInfo = this._enrollSession.data.ReferredBy;
      this.SignUpInfoForm.patchValue({
          referralCode: referredInfo ? referredInfo : (this._enrollSession.data.webAlias || this.webalias)
      });
  }
  }

  createSignUpInfoForm(customerDetail): FormGroup{
    return this._formBuilder.group({
      FirstName: [customerDetail.FirstName || '', [Validators.required]],
      LastName: [customerDetail.LastName || '', [Validators.required]],
      Email: [customerDetail.Email || '', [Validators.required, Validators.email, Validators.pattern(/^([\w-\.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/)]],
      Phone: [customerDetail.MobilePhone || '',[Validators.pattern(/^\d{3}-?\d{3}-?\d{4}$/)]],
      referralCode: [this.webalias || '', Validators.required],
    })
  }
  giftcardFunctionality() {
    if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
      const request = '?WebAlias=' + this.webalias;
      this._restService.GetCustomerSite(request).subscribe((result: any) => {
        if (result.Data != null) {
          this._enrollSession.data.webAlias = this.webalias;
          sessionStorage.setItem('giftCardName', this._enrollSession.data.webAlias);
          this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
          this.getImage(result.Data.customerIDField);
        } else {
          this._toastr.error('Invalid WebAlias.', 'Error');
        }
      }, error => {
        this._toastr.error('Somthing is wrong Please try again', 'Error');
      });
    }
  }
  getImage(id) {
    this._enrollSession.customerImage = API_URL + "/NonProfitEnrollment/GetCustomerImageData?customerId=" + id || IMAGE_URL+"/images/avatars/profile_icon.png";
  }
  getEnrollerDetails() {
    let enrollerInfo = JSON.parse(localStorage.getItem('EnrollerData'));
    let enrollername = enrollerInfo?.EnrollerFirstName + " " + enrollerInfo?.EnrollerLastName;
    return enrollername;
  }
  isBPCustomerExist() {
    let customerType = [4, 5, 6, 7, 8, 10, 17, 19, 21, 24, 25];
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let customerFound = customerType.find((element) => element === this.LoggedInUser.CustomerType);
    return customerFound ? true : false;
  }
  RedirectToSupport(){
    this._router.navigate(['documents/support']);
  }
  ShopNow(){
    this._router.navigate(['AllProducts']);
  }
  OpenToPrivacyPolicyPopup(){
    this.dialogRef = this._matDialog.open(PrivacyDocumentPopupDialogComponent, {
      width: '80%',
      maxWidth: '80%',
      height: '250px',
      panelClass: '',
      disableClose: true
  });
  this.dialogRef.afterClosed()
      .subscribe(response => {

      });
  }
  
  SubscribeButton(){
    if(this.SignUpInfoForm){
      if(this.SignUpInfoForm.invalid){
        this.validateAllFormFields(this.SignUpInfoForm);
        this._toastr.error('Please fill all required fields', 'error');
        this._commonService.SubscribeClickCheck = false;
        this._commonService.EmailCheck = false;
        return;
      }
    }
    const isAnyPreferenceSelected = this.customPreferences.some(pref => pref.isChecked);
    if (!isAnyPreferenceSelected) {
      this._toastr.warning('Please select at least 1 preference', '');
      return;
    }
    if (this.SignUpInfoForm.valid && !this._commonService.SubscribeClickCheck) {
      const mailrequest = this._enrollSession.data.RetailOrderSummary.customerInfoData.Email;
      this.getCustomerSite(this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode,1);
      this._restService.GetCustomersByEmail(mailrequest).subscribe((result: any) => {
        if (result && result.Customer) {
          this._toastr.error('Email Already Registered', 'Error');
          this.SignUpInfoForm.get("Email").reset();
          
          this._commonService.EmailCheck = true;
          this._commonService.SubscribeClickCheck = true;
          return;
        } else {
          this._commonService.EmailCheck = false;
          this._commonService.createCustomer(11, true,this.customPreferences);
        }
      });

    }
  }
  getCustomerSite(webAlias, type): void {
    try {
        if (webAlias) {
            const request = '?WebAlias=' + webAlias;
            this._restService.GetCustomerSite(request).subscribe((result: any) => {
                if (type === 1) {
                    if (result.Data) {
                        this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                        this._enrollSession.data.RetailEnroll.SponsorId = result.Data.customerIDField;
                        this._enrollSession.data.RetailEnroll.EnrollerId = result.Data.customerIDField;
                        this._enrollSession.data.RetailEnroll.webAliasField = result.Data.webAliasField;
                        this._enrollSession.data.CustomerWebAlias = result.Data.webAliasField;
                        this._enrollSession.data.ValidGiftCardCode = this._enrollSession.data.RetailEnroll.webAliasField;
                        this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
                        this._enrollSession.data.ReferredBy = result.Data.webAliasField;
                        this._enrollSession.data.RetailEnroll.isValidwebalias = true;
                        this.getImage(this._enrollSession.data.RetailEnroll.EnrollerId);
                    } else {
                        this._enrollSession.data.RetailEnroll.isValidwebalias = 'false';
                        this._toastr.error('Invalid WebAlias.', 'Error');
                        this._enrollSession.data.CustomerWebAlias = '';
                        this._enrollSession.data.ReferredBy = '';
                        this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';

                    }
                }
                else {
                    if (result.Data) {
                        this._enrollSession.data.ReferredByGiftCard = '';
                        this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                        this._toastr.error('This Referral Code is not Available', 'Error');
                    }
                    else {
                        
                    }
                }
            }, (err) => {
                console.log(err);
                this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                this._toastr.error('Somthing is wrong Please try again.', 'Error');
            }, () => {

            });
        }
    } catch (e) {
        this._toastr.error('Somthing is wrong Please try again.', 'Error');
    }
}
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control: any = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else {
            this.validateAllFormFields(control);
        }
    });
}
}