import { Component, OnInit } from '@angular/core';
import { IMAGE_URL } from 'app/constants';

@Component({
  selector: 'app-supportpage',
  templateUrl: './supportpage.component.html',
  styleUrls: ['./supportpage.component.scss']
})
export class SupportpageComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;
  faqItems = [
    { question: 'Placing an Order', answer: `Go to <a href="https://ishoppurium.com/">ishoppurium.com</a> and login to your account.  If you cannot remember your password use the “Reset Password” function to have a password link sent to you.` },

    {
      question: 'Cancel an Existing Order', answer: `If you need to modify your order, sign in at <a href="https://ishoppurium.com/">ishoppurium.com</a> and go to the "My Account" tab to display order history. <br><br>The EDIT option becomes available approximately 20 minutes after completing your order. Act quickly as it goes away once the order begins processing.
    ` },

    {
      question: 'Track Your Order', answer: `Log into <a href="https://ishoppurium.com/">ishoppurium.com</a>. The “My Account” tab displays order history, including tracking number. Your tracking number will also be emailed to you once your order has been shipped.<br><br>FedEx Delivery Manager App<br><br>Most of Purium's orders are sent via FedEx. Download their Delivery Manager App to use these great features:<br><br>
    Get tracking updates (via text message)<br>
    Request package be held at “hold facility”<br>
    Request a special delivery time (for a fee)<br>
    Add delivery notes<br>
    Set vacation time for all deliveries<br>
    Request to hold your package for 14 days (this is the longest consecutive time period; must wait 6 days to do another hold)<br>
    Use the FedEx “Virtual Assistant” and Customer Chat` },

    {
      question: 'Return an Item or Order', answer: `To return or exchange products for any reason, please follow these steps:<br><br>

                        <strong>Prepare Your Return:</strong> Clearly mark "RMA" and your order number on the outside of the box. 
                        Return all contents within 60 days of receiving your order. Include any specific details inside the package.<br>
                        
                        Multiple Boxes: If returning more than one box, ensure each box is labeled with "RMA - Order Number."<br><br>
                        
                        <strong>Return Requirements:</strong> You must return each item for which you wish to receive a refund. 
                        Even if a product is fully used, the container(s) must be included for a refund to be issued. 
                        Returns must arrive at our corporate office within 60 days of the original receipt date. 
                        Late returns will be rejected unless prior approval for credit has been obtained.<br><br>

                        <strong>Shipping Recommendations:</strong><br><br>
                        We highly recommend using a traceable shipping method for returns, as 
                        Purium Health Products is not responsible for lost or damaged items. If sending products via U.S. mail, 
                        please use registered, certified, or return receipt mail.<br><br>

                        <strong>Processing Time:</strong><br><br>
                        Returns typically take 2-3 weeks to process. Refunds can only be issued to the credit card used for the 
                        original order. Please confirm whether the credit card is still valid; if not, your refund will be 
                        issued via check from Purium Health Products. You will receive an email confirmation from the 
                        Returns Department once your refund is processed.<br><br>

                        <strong>Will Call Returns:</strong><br><br>
                        Returns will be accepted at Will Call; however, they will not be processed on the spot.<br><br>

                        <strong>Return Address:</strong><br><br>
                        Please send all products you wish to return or request a refund to:<br><br>
                        US Returns<br>
                        Purium<br>
                        2610 Homestead Place<br>
                        Rancho Dominguez, CA 90220<br><br>

                        Canada Returns<br>
                        Purium<br>
                        130 - 1959 152nd Street<br>
                        STE 512<br>
                        Surrey, BC V4A 0C4<br>
    ` },

    { question: 'Creating, Editing, or Canceling Monthly Subscriptions', answer: `Go to <a href="https://ishoppurium.com/">ishoppurium.com</a> and login to your account. Click on the Monthly Subscription tab to modify.` },

    {
      question: 'Enrolling or Upgrading to Brand Partner', answer: `To Enroll as a Brand Partner - If you are new to Purium (not in our system), please enroll at puriumenrollment.com<br><br>

    To Upgrade from a Customer to a Brand Partner - Login at <a href="https://ishoppurium.com/">ishoppurium.com</a> and choose the Upgrade to Brand Partner button.<br>` },

    {
      question: 'Redeem Rewards Points', answer: `If you have Points to redeem, they will appear as a Payment option as you checkout.<br><br>

    Rewards points can be redeemed in 1000 point increments.<br><br>
    
    If there is an issue, please fill out the Web Form:
  <a href="https://bit.ly/PuriumCustomerCare">https://bit.ly/PuriumCustomerCare</a>, choose Issue “5 -Commissions, Promos & Business Questions” and include as much info as possible in “Issue Details” for agents to resolve quickly.` },

    {
      question: 'Will Call Details & Instructions', answer: `Ready for pickup anytime Monday - Friday 8:00 am - 5:30 pm!.<br><br>

    Note: Will Call Order must be picked up within 10 days of receiving email. If not, the order will be cancelled and refunded. <br><br>
    
    Will Call Hours<br>
    Monday - Friday<br>
    8am - 5:30pm PT <br><br>
    
    Will Call Address:<br>
    2610 Homestead Place<br>
    Rancho Dominguez, CA 90220<br><br>
    
    Park in visitor parking, ring the doorbell near the Will Call sign & show your ID.<br><br>
    
    
    Customers who purchased the order must show ID and be physically present to pick-up and, unless other arrangements have been previously made through customer care.<br><br>
    
     <a href="https://bit.ly/PuriumCustomerCare">https://bit.ly/PuriumCustomerCare</a>.` },

    {
      question: 'DREAMS Phone App Support', answer: `Telephone: 1 (800) 794-8158<br>
    Email: support@mysecureoffice.com
    ` }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
