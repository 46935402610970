import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from '../enrollSession.service';
import { IMAGE_URL } from 'app/constants';

@Component({
    selector: 'app-order-sub-item',
    templateUrl: './Orders-Sub-Item-Custom.component.html',
    styleUrls: ['./Orders-Sub-Item-Custom.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class OrderSubItemComponentCustom implements OnInit {
    IMAGE_URL = IMAGE_URL;
    customersForm: FormGroup;
    dialogTitle: string;
    action: string;
    userDetails: any;
    DynamikKitItem: any = [];
    SmartOrders: any[] = [];
    selectedCartItem: any;
    customerType: string;
    item: any;
    DynamikItems: any = [];
    type:any;
    qty:any;
    constructor(
        public matDialogRef: MatDialogRef<OrderSubItemComponentCustom>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _sharedMethodService: SharedMethodService ,
        private _toastr: ToastrService,
        public _enrollSession: EnrollSessionService
    ) {
        this.action = _data.action;
        this.dialogTitle = this._data.item.Description;
        this.item = this._data.item;
        if(this._data.type){
          this.type=this._data.type;
        }
        else{
            this.type=1;
        }
        for (const item of this.item.KitMembers) {
            if (item.kitMemberItemsField.length > 1) {
                let kitItem = item.kitMemberItemsField.find(x => x.itemCodeField == '2001-BB' || x.itemCodeField == '9827' || x.itemCodeField=='2001-BB-C');
                if(kitItem)
                 {
                    this.DynamikItems.push(kitItem.itemCodeField);
                 }
                 else
                 {
                    this.DynamikItems.push(item.kitMemberItemsField[0].itemCodeField);
                 }
            } else {
                this.DynamikItems.push('0');
            }

        }

        this.selectedCartItem = JSON.parse(localStorage.getItem('SmartOrderItems')) || [];
    }



    isItemRestrcited(product, selectedItems): any {
        let isRestricted = false;
        let filterItem: any;
        filterItem = _.find(selectedItems, (item) => { 
            return item.ItemCode === product.ItemCode; 
        });
        if (product.RestrictQty && product.RestrictQty > 0 && (filterItem && filterItem.Quantity >= product.RestrictQty)) {
            isRestricted = true;
        }
        return isRestricted;
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
        if(this.type==1){
            this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
            this._sharedMethodService.subOrderItem = true;
        }
      
        
    }

    InserDynamicKitItemNew(): any {
        for (const [index, kitmember] of this.item.KitMembers.entries()) {
            if (kitmember.kitMemberItemsField.length > 1) {
                for (const key of kitmember.kitMemberItemsField.keys()) {
                    if (kitmember.kitMemberItemsField[key].itemCodeField === this.DynamikItems[index]) {
                        this.DynamikKitItem.push(kitmember.kitMemberItemsField[key]);
                        break;
                    }
                }
            }
        }
        if (!this.isItemRestrcited(this.item, this.selectedCartItem)) {
            const subitem = this.item;
            // This for Parent item fetch
            const itemObj3 = {
                ItemCode: this.item.ItemCode,
                Description: this.item.Description,
                Quantity: 1,
                Price: this.item.Price,
                BusinessVolume: this.item.BusinessVolume,
                ParentId: this.item.ItemCode,
                IsUSOnly: this.item.IsUSOnly,
                TinyPicture: this.item.TinyPicture,
                SmallPicture: this.item.SmallPicture,
                LongDetail: this.item.LongDetail,
                LongDetail2: this.item.LongDetail2,
                LongDetail3: this.item.LongDetail3,
                LongDetail4: this.item.LongDetail4
            };
            this._sharedMethodService.ContinueRetailOrder(itemObj3, 1, this.type);

            // This is for Multiple sub items
            this.DynamikKitItem.forEach((item) => {
                const itemObj = {
                    ItemCode: item.itemCodeField,
                    Description: item.descriptionField,
                    Quantity: 1,
                    Price: 0,
                    BusinessVolume: 0,
                    ParentId: subitem.ItemCode,
                    IsUSOnly: subitem.IsUSOnly,
                    TinyPicture: 'https://api.puriumenrollment.com/api/NonProfitEnrollment/GetImageData?fileName=' + item.itemCodeField + '.png',
                    SmallPicture: 'https://api.puriumenrollment.com/api/NonProfitEnrollment/GetImageData?fileName=' + item.itemCodeField + '.png',
                    LongDetail: item.LongDetail,
                    LongDetail2: item.LongDetail2,
                    LongDetail3: item.LongDetail3,
                    LongDetail4: item.LongDetail4
                };
                this._sharedMethodService.ContinueRetailOrder(itemObj, 1, this.type);
    
            });

            this.item.KitMembers.forEach((item) => {
                if (item.kitMemberItemsField.length === 1) {
                    // This for single item fetch            
                    const singleItem = item.kitMemberItemsField[0];
                    const itemObj2 = {
                        ItemCode: singleItem.itemCodeField,
                        Description: singleItem.descriptionField,
                        Quantity: 1,
                        Price: 0,
                        BusinessVolume: 0,
                        ParentId: subitem.ItemCode,
                        IsUSOnly: subitem.IsUSOnly,
                        TinyPicture: 'https://api.puriumenrollment.com/api/NonProfitEnrollment/GetImageData?fileName=' + singleItem.itemCodeField + '.png',
                        SmallPicture: 'https://api.puriumenrollment.com/api/NonProfitEnrollment/GetImageData?fileName=' + singleItem.itemCodeField + '.png',
                        LongDetail: singleItem.LongDetail,
                        LongDetail2: singleItem.LongDetail2,
                        LongDetail3: singleItem.LongDetail3,
                        LongDetail4: singleItem.LongDetail4
                    };
                  
                    this._sharedMethodService.ContinueRetailOrder(itemObj2, 1, this.type);
                }
                this.matDialogRef.close();
            });
        } else {
            this._toastr.error('Info', 'You can buy only ' + this.item.RestrictQty + ' quantity of this item.');
        }

    }  
    onClick() {
        this.matDialogRef.close();
        this._sharedMethodService.subOrderItem = false;
    }  
    onChange(newValue,index) {
        this.DynamikItems[index]=newValue;
        
    }
}
