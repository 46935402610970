<section id="ultpackLanding" >
<app-header></app-header>
<div class="page-wrapper">
   <div class="hero-section wf-section" id="hero-section">
        <div class="hero-container w-container">
            <div class="hero-text-div">
                <h1 class="hero-h1">ULTIMATE LIFESTYLE <br> TRANSFORMATION</h1>
                <h2 class="hero-h2">Get the results you’re looking for with Purium’s best selling program!</h2>
                <a class="cta-button w-button m-t-0" (click)="goToLink()">I’m Ready to Get Started</a>
                <div class="mobile-hero-image-div"><img [src]="IMAGE_URL+'/images/ultpackLanding/New-Mobile-Hero-Image.jpg'" loading="lazy" alt="image" class="mobile-hero-image"></div>
                <a class="cta-button mobile-cta-button w-button" (click)="goToLink()">I’m Ready to Get Started</a>
            </div>
        </div>
    </div>
    <div class="benefits-section wf-section">
        <div class="benefits-container w-container">
            <div class="benefits-headings-div">
                <h1 class="section-h1 benefits-h1">THE QUALITY OF THE FOOD THAT YOU EAT, WILL EQUAL<br>‍THE QUALITY OF THE LIFE THAT YOU LIVE</h1>
                <div class="ult-div-block">
                    <h2 class="section-h2 benefits-h2 benefits-h2-color">If you’re looking to uplevel your life physically and emotionally, Purium’s 30-day Ultimate Lifestyle Transformation (ULT) will give you the results you’re looking for. <br>
                       <br>
                       Our proven system is impacting thousands of lives through superfood nutrition and the Purium lifestyle. Our guided program incorporates delicious organic meals - and a simple to follow schedule with recipes - that will have you eating cleaner and greener while feeling totally satisfied! <br>
                       <br>The ULT is Purium’s best-selling pack for a reason. You may
                    </h2>
                </div>
            </div>
            <div class="best-life-div w-container">
                <div class="w-layout-grid best-life-grid">
                    <div id="w-node-f5992c6c-f534-19d7-7676-1e0f35dae978-e476978c" class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Increase Energy</div>
                    </div>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Improve digestion</div>
                    </div>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Balance mood</div>
                    </div>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Enhance Sleep</div>
                    </div>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Control Weight</div>
                    </div>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Build Muscle</div>
                    </div>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Detox Glyphosate</div>
                    </div>
                </div>
            </div>
              <a class="cta-button benefits-section-button w-button" (click)="goToLink()">I’m Ready To Get Started!</a>
            <div class="full-length-img-div"><img src="" loading="lazy" sizes="100vw" srcset="" alt="" class="full-width-img"></div>
        </div>
    </div>
    <div class="best-life-section wf-section">
        <div class="best-life-container w-container">
             <h1 class="section-h2 best-life-h2 best-life-container-color">Whether you’re a busy parent, student, entrepreneur, or athlete - Purium’s <br>ULT is right for you.</h1><br>
            <h1 class="section-h3 best-life-container-color">Whether you eat vegan, gluten free or whatever’s put in front of you - <br> Purium’s ULT will meet your standards.</h1>
           </div>
    </div>
    <div id="Reviews" class="reviews-section wf-section">
        <div class="icons-section wf-section">
            <div class="icons-container w-container">
                <h1 class="section-h1 logo-section-h1">HIGH QUALITY STANDARDs</h1>
                <div class="w-layout-grid icon-grid w-70 m-l-15">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Vegan-Icon.svg'" loading="lazy" alt="image" class="icons">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Non-GMO-Icon.svg'" loading="lazy" alt="image" class="icons">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/USDA-Organic-Icon.png'" loading="lazy" alt="image" class="icons">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Dairy-Free-Icon.svg'" loading="lazy" alt="image" class="icons">
                    <div class="icons-section-h5">Vegan</div>
                    <div class="icons-section-h5">Non GMO</div>
                    <div class="icons-section-h5">USDA Organic</div>
                    <div class="icons-section-h5">Dairy Free</div>
                </div>
                <div class="w-layout-grid icon-grid icon-grid-mobile">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Vegan-Icon.svg'" loading="lazy" alt="image" class="icons"><img [src]=" IMAGE_URL+'/images/ultpackLanding/Non-GMO-Icon.svg'" loading="lazy" alt="image" class="icons">
                    <div class="icons-section-h5">Vegan</div>
                    <div class="icons-section-h5">Non GMO</div>
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/USDA-Organic-Icon.png'" loading="lazy" alt="image" class="icons"><img [src]=" IMAGE_URL+'/images/ultpackLanding/Dairy-Free-Icon.svg'" loading="lazy" alt="image" class="icons">
                    <div class="icons-section-h5">Organic</div>
                    <div class="icons-section-h5">Dairy Free</div>
                </div>
            </div>
        </div>
        <h1 class="section-h1 reviews-h--mobile">1000&#x27;s OF LIVES TRANSFORMED</h1>
        <div  class="review--animation-main-div w-container">
            <div class="review--animation-div review--animation-div-top-row">
                <div class="review--animation-card">
                    <div class="review--animation-card-name-icon">
                        <div class="reviews-icon">
                            <h1 class="reviews-icone-letter">A</h1>
                        </div>
                        <div class="review-name-div">
                            <span class="review-name h5-heading">Anna</span>
                            <div class="review-location">Verified Customer</div>
                        </div>
                    </div>
                    <div class="review-star-image">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/5-star-review.svg'" loading="lazy" alt="image" class="_5-star-img">
                    </div>
                    <div class="review-text-div">
                        <p class="review-paragraph">I ordered the 30 day Purium package and the overall packaging is great, three days into changing my life I lost three pounds and I am ready for more change and continue my healthy journey.</p>
                    </div>
                </div>
                <div class="review--animation-card">
                    <div class="review--animation-card-name-icon">
                        <div class="reviews-icon">
                            <h1 class="reviews-icone-letter">K</h1>
                        </div>
                        <div class="review-name-div">
                            <span class="review-name h5-heading">Kelsey</span>
                            <div class="review-location">Verified Customer</div>
                        </div>
                    </div>
                    <div class="review-star-image">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/5-star-review.svg'" loading="lazy" alt="image" class="_5-star-img">
                    </div>
                    <div class="review-text-div">
                        <p class="review-paragraph">
                            Absolutely life changing!! The ULT program has transformed my body, mind and soul! It has restored, recharged and revived me! Purium will forever be a part of my life!</p>
                    </div>
                </div>
                <div class="review--animation-card">
                    <div class="review--animation-card-name-icon">
                        <div class="reviews-icon">
                            <h1 class="reviews-icone-letter">E</h1>
                        </div>
                        <div class="review-name-div">
                            <p class="review-name h5-heading">Emma</p>
                            <div class="review-location">Verified Customer</div>
                        </div>
                    </div>
                    <div class="review-star-image">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/5-star-review.svg'" loading="lazy" alt="image" class="_5-star-img">
                    </div>
                    <div class="review-text-div">
                        <p class="review-paragraph">I have really loved all the products. The shake is really delicious and I have really enjoyed the energy it supplies. Thank you for such a great program and amazing products.</p>
                    </div>
                </div>
                <div class="review--animation-card">
                    <div class="review--animation-card-name-icon">
                        <div class="reviews-icon">
                            <h1 class="reviews-icone-letter">P</h1>
                        </div>
                        <div class="review-name-div">
                            <p class="review-name h5-heading">Pam</p>
                            <div class="review-location">Verified Customer</div>
                        </div>
                    </div>
                    <div class="review-star-image">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/5-star-review.svg'" loading="lazy" alt="image" class="_5-star-img">
                    </div>
                    <div class="review-text-div">
                        <p class="review-paragraph">Love the taste! Love the way I feel! These products are fantastic! I highly recommend Purium products!!!</p>
                    </div>
                </div>
            </div>
            <div class="review--animation-div review--animation-div-top-row">
                <div class="review--animation-card">
                    <div class="review--animation-card-name-icon">
                        <div class="reviews-icon">
                            <h1 class="reviews-icone-letter">M</h1>
                        </div>
                        <div class="review-name-div">
                            <span class="review-name h5-heading">Mandy</span>
                            <div class="review-location">Verified Customer</div>
                        </div>
                    </div>
                    <div class="review-star-image">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/5-star-review.svg'" loading="lazy" alt="image" class="_5-star-img">
                    </div>
                    <div class="review-text-div">
                        <p class="review-paragraph">Health Changer! This was the best decision! My family and I did the ULT for 2 months. It takes time to change eating habits and routine but it was well worth it!</p>
                    </div>
                </div>
                <div class="review--animation-card">
                    <div class="review--animation-card-name-icon">
                        <div class="reviews-icon">
                            <h1 class="reviews-icone-letter">P</h1>
                        </div>
                        <div class="review-name-div">
                            <span class="review-name h5-heading">Patricia</span>
                            <div class="review-location">Verified Customer</div>
                        </div>
                    </div>
                    <div class="review-star-image">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/5-star-review.svg'" loading="lazy" alt="image" class="_5-star-img">
                    </div>
                    <div class="review-text-div">
                        <p class="review-paragraph">Great plan, easy to follow! This is not complicated. Steps are simple and easy to follow. Within days you start feeling the difference.</p>
                    </div>
                </div>
                <div class="review--animation-card">
                    <div class="review--animation-card-name-icon">
                        <div class="reviews-icon">
                            <h1 class="reviews-icone-letter">C</h1>
                        </div>
                        <div class="review-name-div">
                            <span class="review-name h5-heading">Charla</span>
                            <div class="review-location">Verified Customer</div>
                        </div>
                    </div>
                    <div class="review-star-image">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/5-star-review.svg'" loading="lazy" alt="image" class="_5-star-img">
                    </div>
                    <div class="review-text-div">
                        <p class="review-paragraph">These products are such great quality. This program helped with better, more restful sleep, and less bloat. Anyone who is looking to be a better version of themself should definitely give it a try!<br></p>
                    </div>
                </div>
                <div class="review--animation-card">
                    <div class="review--animation-card-name-icon">
                        <div class="reviews-icon">
                            <h1 class="reviews-icone-letter">T</h1>
                        </div>
                        <div class="review-name-div">
                            <span class="review-name h5-heading">Tiffany</span>
                            <div class="review-location">Verified Customer</div>
                        </div>
                    </div>
                    <div class="review-star-image">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/5-star-review.svg'" loading="lazy" alt="image" class="_5-star-img">
                    </div>
                    <div class="review-text-div">
                        <p class="review-paragraph">Weight loss and energy galore! Love these products!!!…. So much so that I’ve already started auto ship!</p>
                    </div>
                </div>
            </div>
        </div>
        <a class="cta-button customer-stories-section-button w-button" (click)="goToLink()">I’m Ready To Start My Transformation!</a>
    </div>
    <div class="sustainable-lifestyle-section wf-section">
        <div class="sustainable-lifestyle-container w-container">
            <h1 class="section-h1">A RESPONSIBLE LIFESTYLE</h1>
            <h2 class="section-h2 sustainable-lifestyle-h2">At Purium, we believe in taking responsibility for your health and the health of the planet. That’s why our programs go way beyond nutrition. We are committed to planetary solutions that dramatically reduce the impact our food choices make - and the carbon “foodprint” we leave behind.
                <br><br>
                When you partner with Purium, you’ll be proud to represent the most forward thinking, ecologically responsible nutrition company the world has ever seen.
            </h2>
          </div>
    </div>
    
    <div id="Whats-Inside" class="what-s-inside-section wf-section">
        <div class="what-s-inside-container w-container">
            <h1 class="section-h1">What's included in my ULT?</h1>
           <div class="w-layout-grid what-s-inside-product-grid">
                <div id="w-node-_0e5a0c04-1872-677e-617e-7c6efa923049-e476978c" class="what-s-inside-product-div">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Super-Amino-23-Product-Img.png'" loading="lazy" alt="image" class="what-s-inside-product-img">
                    <div class="what-s-inside-product-text-div">
                        <h3 class="what-s-inside-product-text-h3">BUILD</h3>
                        <h4 class="what-s-inside-product-text-h4">Super Amino 23</h4>
                        <h4 class="what-s-inside-product-text-link view-label" (click)="openModel(3551)">View Nutrition Label</h4>
                    </div>
                </div>
                <div class="what-s-inside-product-div">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Power-Shake-Product-img.png'" loading="lazy" alt="image" class="what-s-inside-product-img">
                    <div class="what-s-inside-product-text-div">
                        <h3 class="what-s-inside-product-text-h3">NOURISH</h3>
                        <h4 class="what-s-inside-product-text-h4">Power Shake</h4>
                        <h4 class="what-s-inside-product-text-link" (click)="openModel(2001)">View Nutrition Label</h4>
                    </div>
                </div>
                <div class="what-s-inside-product-div">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Biome-Product-Img.png'" loading="lazy" alt="image" class="what-s-inside-product-img">
                    <div class="what-s-inside-product-text-div">
                        <h3 class="what-s-inside-product-text-h3">DETOXIFY</h3>
                        <h4 class="what-s-inside-product-text-h4">Biome Medic</h4>
                        <h4 class="what-s-inside-product-text-link" (click)="openModel(2270)">View Nutrition Label</h4>
                    </div>
                </div>
                <div class="what-s-inside-product-div">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Apothe-Cherry-Product-Img-1.png'" loading="lazy" alt="image" class="what-s-inside-product-img">
                    <div class="what-s-inside-product-text-div">
                        <h3 class="what-s-inside-product-text-h3">REGENERATE</h3>
                        <h4 class="what-s-inside-product-text-h4">Apothe-Cherry</h4>
                        <h4 class="what-s-inside-product-text-link" (click)="openModel(2111)">View Nutrition Label</h4>
                    </div>
                </div>
                <div class="what-s-inside-product-div">
                    <img [src]=" IMAGE_URL+'/images/ultpackLanding/Super-Cleansr-Product-Img.png'" loading="lazy" alt="image" class="what-s-inside-product-img">
                    <div class="what-s-inside-product-text-div">
                        <h3 class="what-s-inside-product-text-h3">CLEANSE</h3>
                        <h4 class="what-s-inside-product-text-h4">Super Cleansr</h4>
                        <h4 class="what-s-inside-product-text-link" (click)="openModel(2260)">View Nutrition Label</h4>
                    </div>
                </div>
                <div class="what-s-inside-product-div"><img [src]=" IMAGE_URL+'/images/ultpackLanding/Whats-Inside-Customer-Image.jpg'" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" srcset=" assets/images/ultpackLanding/Whats-Inside-Customer-Image-p-500.jpeg 500w,  assets/images/ultpackLanding/Whats-Inside-Customer-Image.jpg 653w" alt="image" class="what-s-inside-product-img"></div>
            </div>
            <a class="cta-button what-s-inside-section-button w-button" (click)="goToLink()">I’m Ready To Get Started!</a>
        </div>
    </div>
    <div class="content-section wf-section">
        <div class="content-container w-container">
            <h1 class="section-h1 content-section-h1">Choose a path below that matches your needs</h1>
            <h2 class="section-h2 content-section-h2">Available is 3 different easy-to-follow schedules</h2>
            <div class="placemat-div">
                <div class="placemat-text-div">
                    <h3 class="section-h3 content-section-h3">Simple</h3>
                    <p class="placemat-p">This path is all about subtraction, by way of addition. You flood your body with nutrient-rich superfoods without being restrictive with your diet. By meeting your nutritional needs with high-quality foods, you naturally stop craving foods and habits that stem from undernourishment.</p>
                </div>
                <div class="placemat-image-div"><img [src]=" IMAGE_URL+'/images/ultpackLanding/Simple-Placemat-Img.png'" loading="lazy" alt="image" class="placemat-image"></div>
            </div>
            <div class="placemat-div">
                <div class="placemat-text-div">
                    <h3 class="section-h3 content-section-h3">Accelerated</h3>
                    <p class="placemat-p">This path is all about accelerated change. By consciously eliminating foods and habits that are not serving your best health and flooding your body with nutrient-rich superfoods, a new fully nourished state of normal is established.</p>
                </div>
                <div class="placemat-image-div"><img [src]=" IMAGE_URL+'/images/ultpackLanding/Accelerated-Placemat-Img.png'" loading="lazy" alt="image" class="placemat-image"></div>
            </div>
            <div class="placemat-div">
                <div class="placemat-text-div">
                    <h3 class="section-h3 content-section-h3">Intermittent Fasting</h3>
                    <p class="placemat-p">This path is all about Intermittent Fasting. Incorporate nutrient-rich superfoods while consuming substantially all your calories in a 6-8 hour window.</p>
                </div>
                <div class="placemat-image-div"><img [src]=" IMAGE_URL+'/images/ultpackLanding/Accelerated-Placemat-Img.png'" loading="lazy" alt="image" class="placemat-image"></div>
            </div>
            <h1 class="section-h1 content-section-h1">Lifestyle Guide</h1>
            <h2 class="section-h2 content-section-h2 lifestyle-guide-h2">This jam-packed lifestyle guide will give you everything you need to thrive over your 30-day transformation.</h2><img [src]=" IMAGE_URL+'/images/ultpackLanding/Lifestyle-guide-Img.png'" loading="lazy" sizes="100vw" srcset=" assets/images/ultpackLanding/Lifestyle-guide-Img-p-500.png 500w,  assets/images/ultpackLanding/Lifestyle-guide-Img-p-800.png 800w,  assets/images/ultpackLanding/Lifestyle-guide-Img.png 980w" alt="image" class="lifestyle-img">
            <a class="cta-button benefits-section-button w-button" (click)="goToLink()">I’m Ready To Get Started!</a>
        </div>
    </div>
    <div class="customer-stories-section wf-section">
        <div class="customer-stories-container w-container">
            <h1 class="section-h1 f-s-60">REAL LIFE TRANSFORMATION STORIES</h1>
            <div class="customer-stories-video-div">
                <div class="customer-stories-video">
                    <div class="html-embed w-embed w-iframe">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/transformation_1.png'" loading="lazy" width="400" height="400" sizes="(max-width: 1067px) 100vw, 350px" srcset=" assets/images/ultpackLanding/transformation_1.png 500w,  assets/images/ultpackLanding/transformation_1.png 514w" alt="image">
                    </div>
                    <div class="html-embed html-embed-mobile w-embed w-iframe">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/transformation_1.png'" loading="lazy"  width="340" height="400" sizes="(max-width: 767px) 100vw, 350px" srcset=" assets/images/ultpackLanding/transformation_1.png 500w,  assets/images/ultpackLanding/transformation_1.png 514w" alt="image">

                    </div>
                </div>
                <div class="customer-stories-text m-l-5">
                    <h3 class="customer-stories-h3">Wanda S</h3>
                    <h2 class="customer-stories-p">&quot;<i class="f-w-400 customer-stories-h3-color">I started using these products in Feb 2022, it's [been] a little over 6 months and I have lost a total of 40 lbs. I am in complete amazement because I have used everything else out there and never have I seen results like this…</i>&quot;<br></h2>
                </div>
            </div>
            <div class="customer-stories-video-div customer-stories-video-div-tracie">
                <div class="customer-stories-video">
                    <div class="html-embed w-embed w-iframe"><img [src]=" IMAGE_URL+'/images/ultpackLanding/transformation_2.png'" width="400" height="400" loading="lazy" sizes="(max-width: 767px) 100vw, 350px" srcset=" assets/images/ultpackLanding/transformation_2.png 500w,  assets/images/ultpackLanding/transformation_2.png 514w" alt="image"></div>

                    <div class="html-embed html-embed-mobile w-embed w-iframe">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/transformation_2.png'" loading="lazy" width="340" height="400" sizes="(max-width: 767px) 100vw, 350px" srcset=" assets/images/ultpackLanding/transformation_2.png 500w,  assets/images/ultpackLanding/transformation_2.png 514w" alt="image">
                    </div>
                </div>
                <div class="customer-stories-text m-l-5">
                    <h3 class="customer-stories-h3">Haley</h3>
                    <p class="customer-stories-p">&quot;<i class="f-w-400 customer-stories-h3-color">The girl on the left was extremely tired, fatigued, anxious, and depressed.The girl on the right is [energized], migraine-free, medication free, and thriving… I could not understand why I looked healthy and thin but did not feel good at all… Healing my gut changed everything for me. I now have the energy to stay awake during the day AND be productive after work. I now enjoy my weekends with friends and family.</i>&quot;<br></p>
                </div>
            </div>
            <a class="cta-button customer-stories-section-button w-button" (click)="goToLink()">I’m Ready To Start My Transformation!</a>
        </div>
    </div>
    <div id="Pricing" class="pricing-section wf-section">
        <div class="pricing-container w-container">
            <h1 class="section-h1 f-s-60">HOW MUCH DOES IT COST?</h1>
            <h2 class="section-h2 f-w-400 f-s-24">&quot;Your health is an investment, not an expense.&quot; - Dave Sandoval</h2>
            <h2 class="section-h2 f-w-400 f-s-24">For most people, Purium’s protein shakes - and their ability to satisfy your hunger - can replace unhealthy expensive meals that not only cost you money, but may also cost you your health.</h2>
            <div class="pricing-11-dollars-text f-s-24">The 30-day Ultimate Lifestyle Transformation is</div>
            <div class="pricing-11-dollars-div">
                <div class="pricing-11-dollars-text-in-div">$12/Day</div>
            </div>
            <div class="pricing-11-dollars-spend-text f-s-30">Where does your money go now?</div>
            
            <div class="w-layout-grid pricing-11-dollars-spend-grid pricing-spend-grid">
                <img [src]=" IMAGE_URL+'/images/ultpackLanding/Coffee-Item-Img.jpg'" loading="lazy" alt="image" class="pricing-11-dollars-spend-grid-img">
                <img [src]=" IMAGE_URL+'/images/ultpackLanding/Fast-Food-Item-Img.jpg'" loading="lazy" alt="image" class="pricing-11-dollars-spend-grid-img">
                <img [src]=" IMAGE_URL+'/images/ultpackLanding/Wine-Item-Img.jpg'" loading="lazy" alt="image" class="pricing-11-dollars-spend-grid-img">
                <div class="pricing-11-dollars-spend-grid-text"></div>
                    <div id="w-node-_12a4c701-424e-1b8c-825c-07cbfc82185a-e476978c" class="pricing-11-dollars-spend-grid-text">Coffee &amp; Pastry</div>
                <div class="pricing-11-dollars-spend-grid-text">Fast Food</div>
                <div class="pricing-11-dollars-spend-grid-text">Bottle of Wine</div>
            </div>

            <!--Mobile view -->

            <div class="w-layout-grid pricing-11-dollars-spend-grid pricing-11-dollars-spend-grid-mobile align-center">
                <img [src]=" IMAGE_URL+'/images/ultpackLanding/Coffee-Item-Img.jpg'" width="270" height="230" loading="lazy" alt="image" class="pricing-11-dollars-spend-grid-img">
                <div id="w-node-_879a1d43-61e8-0a44-0d18-c7a3728f64e5-e476978c" class="pricing-11-dollars-spend-grid-text">
                    Coffee &amp; Pastry</div>
                <img [src]=" IMAGE_URL+'/images/ultpackLanding/Fast-Food-Item-Img.jpg'" width="250" height="230" loading="lazy" alt="image" class="pricing-11-dollars-spend-grid-img">
                
                <div class="pricing-11-dollars-spend-grid-text">Fast Food</div>
                <img [src]=" IMAGE_URL+'/images/ultpackLanding/Wine-Item-Img.jpg'" width="250" height="230" loading="lazy" alt="image" class="pricing-11-dollars-spend-grid-img">
               
                <div class="pricing-11-dollars-spend-grid-text">Bottle of Wine</div>
            </div>
            <div class="pricing-11-dollars-spend-text f-s-30">It’s time to prioritize your health!</div>
            <a class="cta-button customer-stories-section-button w-button w-35" (click)="goToLink()">I’m Ready To Feel Amazing!</a>
        </div>
    </div>
    <div id="Community" class="support-section wf-section">
        <div class="support-container w-container">
            <h1 class="section-h1 f-s-60">HOW WILL I BE SUPPORTED?</h1>
            <p class="support-section-p align-center f-s-24 w-90">Stay motivated and inspired with Purium&#x27;s Lifestyle Transformation Facebook Group Support.</p>
            <div class="support-section-list-image-div">
                <div class="support-list-div">
                    <h2 class="section-h2 support-section-h2 f-s-30">Plus:</h2><br>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Delicious Healthy Recipes</div>
                    </div><br>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Lifestyle Education</div>
                    </div><br>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Community Support</div>
                    </div>
                </div><img [src]=" IMAGE_URL+'/images/ultpackLanding/support-img.png'" loading="lazy" sizes="(max-width: 767px) 100vw, 350px" srcset=" assets/images/ultpackLanding/support-img.png 500w,  assets/images/ultpackLanding/support-img.png 514w" alt="image" class="support-img">
            </div>

           
            
            <div class="support-section-list-image-div support-section-list-image-div-mobile">
                <img [src]=" IMAGE_URL+'/images/ultpackLanding/support-img.png'" loading="lazy" sizes="(max-width: 558px) 92vw, (max-width: 767px) 514px, 100vw" srcset=" assets/images/ultpackLanding/support-img.png 500w,  assets/images/ultpackLanding/support-img.png 514w" alt="image" class="support-img">
                <div class="support-list-div">
                    <h2 class="section-h2 support-section-h2 f-s-30">Plus:</h2>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Delicious Healthy Recipes</div>
                    </div><br>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Lifestyle Education</div>
                    </div><br>
                    <div class="best-life-grid-item">
                        <img [src]=" IMAGE_URL+'/images/ultpackLanding/Best-Life-Icon.svg'" loading="lazy" alt="image" class="best-life-grid-icon">
                        <div class="best-life-text">Community Support</div>
                    </div>
                </div>
            </div>
            <h2 class="section-h2 support-section-h2 more-support-h2 f-s-30">More Support</h2>
            <p class="support-section-p">Our community of passionate health advocates help encourage, support and empower you during every step of your transformative journey.</p>
            <div class="support-links-main-div">
                <div class="support-links-div">
                    <a [href]="BucketUrl + 'Web_Schedule_Tracker.pdf'" target="_blank" class="support-link f-s-24 f-w-700">Schedule & Tracker</a><br>
                    <a [href]="BucketUrl + 'Quick_Reference_RD0123.png'" target="_blank" class="support-link f-s-24 f-w-700">Quick Reference Schedule</a><br>
                    <a [href]="BucketUrl + 'WEB_PHP_Lifestyle_Guide_RD1222.pdf'" target="_blank" class="support-link f-s-24 f-w-700">Lifestyle Guide</a>
                </div>
                <div class="support-links-div">
                    <a href="https://www.instagram.com/puriumhp/" target="_blank" class="support-link f-s-24 f-w-700">FB Support Group</a><br>
                    <a href="https://www.isharepurium.com/events" target="_blank" class="support-link f-s-24 f-w-700">Products & Passions Zooms</a><br>
                    <a href="http://blog.puriumcorp.com/" target="_blank" class="support-link f-s-24 f-w-700">Blog with tips, recipes &amp; FAQs</a><br>
                </div>
            </div>
        </div>
    </div>
    <div id="Subscription" class="subscription-section wf-section">
        <div class="subscription-container w-container">
            <h1 class="section-h1 f-s-60 text-transform">SUBSCRIBE &amp; SAVE with Smart Order</h1><br>
            <h2 class="section-h2 subscription-h2 f-s-24">Never worry about being without your superfoods. With Smart Order you’ll enjoy <br>
                an ultra-convenient way to get all of your superfoods - every month - to maintain <br>your progress!
            </h2>
            <h3 class="section-h3 subscription-h3">What is Smart Order?</h3>
            <h2 class="section-h2 subscription-h2 f-s-24">A recurring order to support maintenance that is automatically shipped on a <br>
                chosen date between the 1-21st of each month.
            </h2>
            <h3 class="section-h3 subscription-h3">How does Smart Order Work?</h3>
            <div class="subscription-video subscription-video-desktop w-embed w-iframe"><iframe title="iframe" src="https://player.vimeo.com/video/607654227?h=b870e90b85&title=0&byline=0&portrait=0" width="100%" height="500" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe></div>
            <div class="subscription-video subscription-video-mobile w-embed w-iframe"><iframe title="iframe" src="https://player.vimeo.com/video/607654227?h=b870e90b85&title=0&byline=0&portrait=0" width="100%" height="250" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe></div><br>
           <h2 class="section-h2 subscription-h2 f-s-24"><i>Get 25% off your first purchase and all future purchases, when you subscribe.</i>
            </h2>
            <a class="cta-button customer-stories-section-button w-button w-35" (click)="goToLink()">I’m Ready To Transform!</a><br>
            <h2 class="section-h2 subscription-h2 f-s-24"><i>“Good food is not expensive. Medical bills are expensive. <br>
                Missed days at work are expensive. Prescriptions are expensive.”
            </i>
            </h2>
        </div>
    </div>
  
    <div class="footer-section wf-section">
        <div class="footer-container w-container">
            <img src=" assets/images/ultpackLanding/logo-7.png" loading="lazy" alt="image" class="footer-logo">
            <div class="footer-text">The Transformation Company.</div>
        </div>
    </div>
</div>
<div id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content" id="add_to_me">
        <span class="close" id="add_after_me" (click)="displayNone()">&times;</span>
    </div>

</div>
<div class="footer-div">
<app-footercomponent></app-footercomponent>
</div>
</section>