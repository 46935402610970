<div class="dialog-content-wrapper" id="two-way-wrapper">
    <div style="display:flex" class="pop-up-background">
        <div class="pop-up-div" fxLayout="column">
            <div fxLayout="row">
                <div fxLayoutAlign="start center" fxFlex="80">
                    <strong class="sub-heading">{{dialogTitle}}</strong>
                </div>
                <div fxLayoutAlign="end center" fxFlex="20">
                    <mat-icon class="cursor-pointer" (click)="onClick()">close</mat-icon>
                </div>
            </div>
            <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar style="max-height: none; overflow: initial;">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start" class="p-b-10">
                    <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.sm="50" fxFlex.md="50" class="p-r-20">
                        <div class="item-img kitImage">
                            <img [src]="item.SmallPicture" [attr.Error]="'this.src=\'' + IMAGE_URL + '/img/noimage.png\''">
                        </div>
                    </div>
                    <div fxFlex="100" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50">
                        <div class="item-info m-t-25">
                            <span [innerHTML]="item.ShortDetail"></span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center start">
                    <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.gt-sm="50" fxFlex.gt-md="50" class="p-r-20">
                        <div class="item-Price">
                            <strong class="fontText">Price: ${{item.Price | number : '1.2-2'}}</strong>
                        </div>
                    </div>
                    <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
                        <div class="item-BusinessVolume">
                            <strong class="fontText">BV: {{item.BusinessVolume}}</strong>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center stretch" class="m-t-10">
                    <div *ngFor="let item of item.KitMembers let i = index">
                        <ng-container *ngIf="item.kitMemberItemsField.length > 1">
                            <label class="m-t-10">Choose 1  <span class="sub-heading">{{item.descriptionField }}</span> items :</label>
                            <select [value]="DynamikItems[i]" class="form-field w-input" (change)="onChange($event.target.value,i)">
                                <option *ngFor="let kitMember of item.kitMemberItemsField" [value]="kitMember.itemCodeField" [selected]="kitMember.itemCodeField=='2001-BB'  || kitMember.itemCodeField=='9827' || kitMember.itemCodeField=='2001-BB-C'">
                                    {{kitMember.descriptionField}}
                                </option>
                            </select>
                        </ng-container>
                    </div>
                </div>
                <div mat-dialog-actions class="m-0 p-16 pop-up-button-div" fxLayout="row" fxLayoutAlign="end">
                    <button mat-button class="pop-up-button pop-up-button-no w-button customiseButton" (click)="InserDynamicKitItemNew()">Add to Cart</button>
                </div>
            </div>

        </div>
    </div>
</div>


