<div class="dialog-content-wrapper" id="two-way-wrapper" *ngIf="siteType!='ultlifestyle'">
    <div style="display:flex" class="pop-up-background">
        <div class="pop-up-div" fxLayout="column">
            <div fxLayout="row">
                <div fxLayoutAlign="start center" fxFlex="80">
                    <strong class="sub-heading">{{dialogTitle}}</strong>
                </div>
                <div fxLayoutAlign="end center" fxFlex="20">
                    <mat-icon class="cursor-pointer" (click)="onClick()">close</mat-icon>
                </div>
            </div>
            <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar style="max-height: none; overflow: initial;">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start" class="p-b-10">
                    <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.sm="50" fxFlex.md="50" class="p-r-20">
                        <div class="item-img kitImage">
                            <img [src]="item.SmallPicture" [attr.Error]="'this.src=\'' + IMAGE_URL + '/img/noimage.png\''">
                        </div>
                    </div>
                    <div fxFlex="100" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50">
                        <div class="item-info m-t-25">
                            <span [innerHTML]="item.ShortDetail"></span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center start">
                    <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.gt-sm="50" fxFlex.gt-md="50" class="p-r-20">
                        <div class="item-Price">
                            <strong class="fontText">Price: ${{item.Price |number : '1.2-2'}}</strong>
                        </div>
                    </div>
                    <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
                        <div class="item-BusinessVolume">
                            <strong class="fontText">BV: {{item.BusinessVolume}}</strong>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center stretch" class="m-t-10">
                    <div *ngFor="let item of item.KitMembers let i = index">
                        <ng-container *ngIf="item.kitMemberItemsField.length > 1">
                            <label class="m-t-10">Choose 1  <span class="sub-heading">{{item.descriptionField }}</span> items :</label>
                            <select [value]="DynamikItems[i]" class="form-field w-input" (change)="onChange($event.target.value,i)">
                                <option *ngFor="let kitMember of item.kitMemberItemsField" [value]="kitMember.itemCodeField" 
                                [selected]="kitMember.itemCodeField=='2001-BB'   || kitMember.itemCodeField=='9827' || kitMember.itemCodeField=='2001-BB-C'">
                                    {{kitMember.descriptionField}}
                                </option>
                            </select>
                        </ng-container>
                    </div>
                </div>
                <div mat-dialog-actions class="m-0 p-16 pop-up-button-div" fxLayout="row" fxLayoutAlign="end">
                    <button mat-button class="pop-up-button pop-up-button-no w-button customiseButton" (click)="InserDynamicKitItemNew()">Add to Cart</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="dialog-content-wrapper" id="two-way-wrapper" *ngIf="siteType=='ultlifestyle'">
    <div style="display:flex" class="pop-up-background">
        <div class="pop-up-div" fxLayout="column">
            <div fxLayout="row">
                <div fxLayoutAlign="start center" fxFlex="80">
                    <strong class="sub-heading">{{dialogTitle}}</strong>
                </div>
                <div fxLayoutAlign="end center" fxFlex="20">
                    <mat-icon class="cursor-pointer" (click)="onClick()">close</mat-icon>
                </div>
            </div>
            <div mat-dialog-content class="p-24 pb-0 m-0 boxHeightCustom" fusePerfectScrollbar
                >
                <ng-container id="k" *ngFor="let items of item let k = index">
                    <h1 class="checkout-h1" fxLayoutAlign="center center" *ngIf="items.ItemCode=='11244' || items.ItemCode=='11245' || items.ItemCode=='11246' || items.ItemCode=='11247' || items.ItemCode=='11249' || items.ItemCode=='11281'">Month 1-Today's Order</h1>
                    <h1 class="checkout-h1" fxLayoutAlign="center center" *ngIf="items.ItemCode=='11254' || items.ItemCode=='11255' || items.ItemCode=='11256' || items.ItemCode=='11257' || items.ItemCode=='11259' || items.ItemCode=='11283'">Month 2-3</h1>
                    <h1 class="checkout-h1" fxLayoutAlign="center center" *ngIf="items.ItemCode=='11264' || items.ItemCode=='11265' || items.ItemCode=='11266' || items.ItemCode=='11267' || items.ItemCode=='11269' || items.ItemCode=='11285'">Month 4+</h1>
                    <div class="p-b-10 image_div">
                        <div fxFlex="100" fxFlex.gt-lg="100" fxFlex.sm="100" fxFlex.md="100" class="p-r-20">
                            <div class="item-img kitImage">
                                <img [src]="items.SmallPicture" [attr.Error]="'this.src=\'' + IMAGE_URL + '/img/noimage.png\''">
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.lg="50" fxFlex.sm="50" fxFlex.md="50">
                            <div class="item-info m-t-25">
                                <span [innerHTML]="items.ShortDetail"></span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center start">
                        <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.gt-sm="50" fxFlex.gt-md="50" class="p-r-20">
                            <div class="item-Price">
                                <strong class="fontText">Price: ${{items.Price |number : '1.2-2'}}</strong>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.gt-lg="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
                            <div class="item-BusinessVolume">
                                <strong class="fontText">BV: {{items.BusinessVolume}}</strong>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="m-t-10">
                        <div *ngFor="let item of items.KitMembers let i = index">
                            <ng-container *ngIf="item.kitMemberItemsField.length > 1">
                                <label class="m-t-10">Choose 1 <span class="sub-heading">{{item.descriptionField
                                        }}</span> items :</label>
                                <select [value]="DynamikItems[items.ItemCode][i]" class="form-field w-input"
                                    (change)="onChange($event.target.value,i,items.ItemCode)">
                                    <option *ngFor="let kitMember of item.kitMemberItemsField"
                                        [value]="kitMember.itemCodeField" [selected]="kitMember.itemCodeField=='2001-BB' || kitMember.itemCodeField=='2261' || kitMember.itemCodeField=='2001-BB-C'">
                                        {{kitMember.descriptionField}}
                                    </option>
                                </select>
                            </ng-container>
                        </div>

                    </div>
                </ng-container>
                <div mat-dialog-actions class="m-0 p-16 pop-up-button-div" fxLayout="row" fxLayoutAlign="end">
                    <button mat-button class="pop-up-button pop-up-button-no w-button customiseButton"
                        (click)="InserDynamicKitItemNew()">Add to Cart</button>
                </div>
            </div>

        </div>
    </div>
</div>


