<section id="transformationLanding">
    <div class="page-wrapper">
        <!---------------------------------- header-banner-section -------------------------------->
        <div class="top-banner row mt-10 wp-block-heading has-text-align-center"
                [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}"
                id="header-banner">
                <div fxFlex="90" fxFlex.lt-md="100" fxLayout="row">
                    <button class="cursor-pointer" (click)="prevBanner()"> ← </button>
                    <ngx-slick-carousel class="sliding" #slickModalBanner="slick-carousel" [config]="slideConfig2">
                    <div ngxSlickItem *ngFor="let slide of bannerstext; let i = index" class="text-padding"  [ngClass]="{'text-underline-banner': (slide.url !== undefined && slide.url !== '' && slide.url !== null)}">
                        <a class="bannerText" [ngClass]="{'text-cursor': (slide.url !== undefined && slide.url !== '' && slide.url !== null)}" target="_blank" [href]="slide.url"><span id="textcolor">{{slide.text}}</span></a>
                    </div>
                    </ngx-slick-carousel>
                    <button class="cursor-pointer" (click)="nextBanner()"> → </button>
                </div>
        </div>

        <!---------------------------------- first-section-heading-dyanmic-section ----------------------------->
        <div class="first-section">
            <nav id="HeaderLogo" class="navbar navbar-expand-lg navbar-light navbarheader">
                <div>
                <div class="m-auto">
                    <a class="navbar-brand">
                        <!------------------------------    not logged in   ------------------------------------>
                    <div fxLayoutAlign="center none" *ngIf="!_auth.isLoggedIn()" class="profileIcon">
                        <ul class="navbar-nav ul-login bg-c display-flex"
                            *ngIf="!_auth.isLoggedIn()"
                            id="bg-image">
                            <!-- <li class="nav-item">
                                <button class="cart-icon" type="button"
                                    (click)="_sharedMethodService.toggleSidenav(sideNav)">
                                    <img [src]="IMAGE_URL+'/icons/add-to-cart.png'" alt="" class="cart-w-20 pt-4px">
                                    <sup class="f-w-600 c-green" *ngIf="getQuanity() > 0" [textContent]="getQuanity()"></sup>
                                </button>
                            </li> -->
                            <li class="nav-item profile-icon" [ngClass]="{'noClick':_sharedMethodService.isOpenDialog}">
                                <button type="button" class="btn btn-secondary dropdown-toggle logintext"
                                    (click)="login()" data-bs-toggle="collapse" data-bs-target="#navLoginContent"
                                    aria-controls="navLoginContent" aria-haspopup="true" aria-expanded="false">
                                    <img [src]="IMAGE_URL+'/icons/icon-profile.png'" alt="" class="w-20">
                                </button>
                            </li>
                        </ul>  
                    </div>

                <!------------------------------    logged in user   ------------------------------------>
                    <div class="mx-width-100" *ngIf="_auth.isLoggedIn()">
                        <div *ngIf="!_enrollSession.mobileViewMethod()">
                            <div class="navbar-nav bg-c"
                                *ngIf="_auth.isLoggedIn()" id="user_details">

                                <!-- Account Button For Desktop -->
                                <div class="btn-group" style="display: flex;flex-direction: column;">

                                    <div id="dropdownMenuButton" style="display: flex;border-radius: 100px;flex-direction: column;">
                                        <button type="button" class="accountDesktop"
                                            [ngClass]="{'buttonopened': isshowmenu}"
                                            (click)="isshowmenu = !isshowmenu">
                                            Account&nbsp;&nbsp;&nbsp;&nbsp;
                                            <mat-icon
                                            class="keyboard_arrow_right" *ngIf="isshowmenu">keyboard_arrow_down</mat-icon>
                                        </button>
                                        <div *ngIf="isshowmenu" class="dropdown-content dropdownAccountBar">
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item cursor-pointer myaccountText"
                                                (click)="ClicktoOrderHistory()">
                                                <!-- My Account -->
                                                <span>Order History</span>
                                            </a>
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item cursor-pointer myaccountText"
                                                (click)="ClicktoSubscription()">
                                                <span>Subscription</span>
                                            </a>
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item cursor-pointer myaccountText upgradeTxt" *ngIf="isUpgradableCustomer()" (click)="ClicktoUpgrade()">
                                                <span>Become a Brand Partner</span>
                                            </a>
                                            <div class="" *ngIf="isUpgradableCustomer()">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item myaccountText"
                                                [ngClass]="{'myaccountText':!isshowreward,'myaccountTextReward':isshowreward}"
                                                (click)="isshowreward = !isshowreward">
                                                <span>Rewards Points</span><mat-icon class="keyboard_arrow_right"
                                                    *ngIf="!isshowreward">keyboard_arrow_right</mat-icon><mat-icon
                                                    class="keyboard_arrow_down"
                                                    *ngIf="isshowreward">keyboard_arrow_down</mat-icon>
                                                <!-- Submenu -->
                                                <a *ngIf="isshowreward" class="dropdown-item reward-points">
                                                    <span class="p-0"><b>{{_sharedMethodService.balanceField | number : '1.0-0'}}</b></span><br>
                                                    <span class="reward-point-text">Rewards Points Available</span>
                                                </a>
                                                <a *ngIf="isshowreward" class="dropdown-item reward-point-text color-green" (click)="ClickToLearnMoreRewardPoints()">Learn More</a>
                                            </a>
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item cursor-pointer myaccountText"
                                                (click)="ClicktoSettings()">
                                                <span>Settings</span>
                                            </a>
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <span class="dropdown-item cursor-pointer logouttext"
                                                (click)="logout()">Sign Out</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="btn-group" style="display: flex;
                    flex-direction: row;" *ngIf="_enrollSession.mobileViewMethod() && _auth.isLoggedIn()">

                        <div id="dropdownMenuButton" style="display: flex;border-radius: 100px;
                        flex-direction: column;">
                            <button type="button" class="accountMobile" *ngIf="!SideAccountBar"
                                (click)="toggleAccountSideBar()">
                                Account
                            </button>
                            <div *ngIf="SideAccountBar" class="closemenuButton"><mat-icon
                                    (click)="toggleAccountSideBar()">clear</mat-icon></div>
                        </div>
                    </div>
                    
                    <img (click)="RedirectToHome()" *ngIf="!_enrollSession.mobileViewMethod()" [src]="IMAGE_URL+'/images/logos/Purium Side Logo and Transformation Company Desktop.png'" alt="" class="logo-width">
                    <img (click)="RedirectToHome()" *ngIf="_enrollSession.mobileViewMethod()" [src]="IMAGE_URL+'/images/logos/logo new.png'" alt="" class="logo-width">
                    </a>
                </div>
                <!------------------------------    Summary Cart   ------------------------------------>
            <!-- <ng-container *ngIf="!_enrollSession.mobileViewMethod()">
                <mat-sidenav #sideNav [mode]="mode.value || 'over'" closed>
                    <summary-cart [routPath]="routPath" [customerInfoForm]="customerInfoForm"
                        [accountInfoForm]="accountInfoForm" [shippingForm]="shippingForm"
                        [billingAddressForm]="billingAddressForm" [shippingMethodForm]="shippingMethodForm"
                        [referredForm]="referredForm" [paymentTypeForm]="paymentTypeForm"></summary-cart>
                </mat-sidenav>
            </ng-container> -->
            

            <!------------------------------    Summary Cart button  ------------------------------------>
            <!-- <ng-container *ngIf="_enrollSession.mobileViewMethod()">
                <summary-cart-button [routPath]="routPath" [customerInfoForm]="customerInfoForm"
                [accountInfoForm]="accountInfoForm" [shippingForm]="shippingForm"
                [billingAddressForm]="billingAddressForm" [shippingMethodForm]="shippingMethodForm"
                [referredForm]="referredForm" [paymentTypeForm]="paymentTypeForm"></summary-cart-button>
            </ng-container> -->
                <div>
                    <!-- -------------------------    Account Side Bar for Mobile  ----------------------------->
                    <mat-sidenav class="menuSide" [mode]="mode.value || 'over'" *ngIf="SideAccountBar" closed>
                        <div>
                            <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0" id="nav">
                                <li class="nav-item sidebarFlex" (click)="isshowAccount = !isshowAccount">
                                    <a id="btn" class="nav-link sideBarColor m-l5">Account</a><mat-icon
                                        class="m-0 keyboard_arrow_right_menuBar" *ngIf="!isshowAccount" id="arrowIcon">keyboard_arrow_right</mat-icon>
                                        <mat-icon
                                        class="m-0 keyboard_arrow_right_menuBar" *ngIf="isshowAccount">keyboard_arrow_down</mat-icon>
                                </li>
                                <div *ngIf="isshowAccount"  class="w-100">
                                    <div class="menu-bar-divider-account"></div>
                                <li class="nav-item sidebarFlex" (click)="ClicktoOrderHistory()">
                                    <a id="btn" class="nav-link sideBarColor m-l5" >Order
                                        History</a>
                                </li>
                                <div class="menu-bar-divider-account"></div>
                                <li class="nav-item sidebarFlex" (click)="ClicktoSubscription()">
                                    <a id="btn" class="nav-link sideBarColor m-l5" >Subscription</a>
                                </li>
                                <div class="menu-bar-divider-account" *ngIf="isUpgradableCustomer()"></div>
                                <li class="nav-item sidebarFlex upgradeTxt" *ngIf="isUpgradableCustomer()" (click)="ClicktoUpgrade()">
                                    <a id="btn" class="nav-link sideBarColor m-l5" >Become a Brand Partner</a>
                                </li>
                                <div class="menu-bar-divider-account"></div>
                                <li id="btn" class="nav-item sidebarFlex" (click)="isshowreward = !isshowreward;">
                                    <a class="nav-link sideBarColor m-l5">Rewards Points</a><mat-icon
                                        class="keyboard_arrow_right_menuBar"
                                        *ngIf="!isshowreward">keyboard_arrow_right</mat-icon><mat-icon
                                        class="keyboard_arrow_right_menuBar" *ngIf="isshowreward">keyboard_arrow_down</mat-icon>
                                </li>
                                <li class="nav-item" *ngIf="isshowreward">
                                    <a *ngIf="isshowreward" class="nav-link reward-points">
                                        <b>{{_sharedMethodService.balanceField | number : '1.0-0'}} Rewards Points Available</b>
                                    </a>
                                    <a *ngIf="isshowreward" class="nav-link color-green margin-text" target="_blank" href="https://backoffice.mypurium.com/puriumrewards">Learn More</a>
                                </li>
                                <div class="menu-bar-divider-account"></div>
                                <li class="nav-item sidebarFlex" (click)="ClicktoSettings()">
                                    <a id="btn" class="nav-link sideBarColor m-l5" >Settings</a>
                                </li>
                                <div class="menu-bar-divider-account"></div>
                                <li id="btn" class="nav-item sidebarFlex" (click)="logout();">
                                    <a class="nav-link sideBarColor m-l5">Sign Out</a>
                                </li>
                                <div class="menu-bar-divider-account"></div>
                                </div>
                            </ul>
                        </div>
                    </mat-sidenav>
                </div>
                </div>
            </nav>
          <div class="first-section-bg">
            <!---------------------------------- welcome-to-transformation-page-section -------------------------------->
            <div class="welcome-to-transformation-page">
                <div class="border-line-div"></div>
                <ng-container *ngIf="_enrollSession.mobileViewMethod()">
                    <div class="container header-padding-full">
                        <div fxFlex="100" class="mt-10 wp-block-heading has-text-align-center"
                            id="welcome-banner">
                            <div fxFlex="79" class="m-auto">
                                <span class="welcome-text" *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">Hi {{_enrollSession.data.LoggedInUserData?.FirstName ||
                                    userData?.FirstName || _enrollSession.data.LoggedInUserData?.Company}},</span>
                                    <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                                <span *ngIf="!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()" class="p-15">The #1 Solution to Look, Feel, & Perform Your Best</span>
                                <span *ngIf="_auth.isLoggedIn() && !isBPCustomerExist()" class="welcome-text">{{getEnrollerDetails()}}'s #1 Solution to Look, Feel, & Perform Your Best</span>
                                <span *ngIf="_auth.isLoggedIn() && isBPCustomerExist()" class="welcome-text">YOUR #1 Solution to Look, Feel, & Perform Your Best</span>
                                <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && ((!_sharedMethodService.shareLinkCart()))" class="welcome-text">{{(_enrollSession.data.RetailEnroll?.webAlias)}}'s #1 Solution to Look, Feel, & Perform Your Best</span>
                                <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && (_sharedMethodService.shareLinkCart())" class="welcome-text">{{(_enrollSession.data.RetailEnroll?.webAlias)}}'s #1 Solution to Look, Feel, & Perform Your Best</span>
                            </div>
                            <span fxFlex="21" *ngIf="_auth.isLoggedIn()" id="userImagespan"><img [src]="_enrollSession.customerImage || IMAGE_URL+'/images/avatars/profile_icon.png'"  [attr.Error]="'this.src=\'' + IMAGE_URL + '/images/avatars/profile_icon.png\''" class="userImage"></span>
                            <span fxFlex="21" *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()" id="userImagespan"><img [src]="_enrollSession.customerImage || IMAGE_URL+'/images/avatars/profile_icon.png'"  [attr.Error]="'this.src=\'' + IMAGE_URL + '/images/avatars/profile_icon.png\''" class="userImage"></span>
                        </div>
                    </div><br *ngIf="!_enrollSession.mobileViewMethod()">
                </ng-container>
                <ng-container *ngIf="!_enrollSession.mobileViewMethod()">
                <div class="container row" [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}">
                    <div fxFlex="100" class="mt-10 wp-block-heading has-text-align-center"
                        id="welcome-banner">
                        <div fxFlex="79"  class="m-auto" [ngClass]="{'p-15':!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()}">
                            <span class="welcome-text" *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">Hi {{_enrollSession.data.LoggedInUserData?.FirstName ||
                                userData?.FirstName || _enrollSession.data.LoggedInUserData?.Company}},</span>
                                <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                            <span *ngIf="!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()" class="p-15">The #1 Solution to Look, Feel, & Perform Your Best</span>
                            <span *ngIf="_auth.isLoggedIn() && !isBPCustomerExist()" class="welcome-text">{{getEnrollerDetails()}}'s #1 Solution to Look, Feel, & Perform Your Best</span>
                            <span *ngIf="_auth.isLoggedIn() && isBPCustomerExist()" class="welcome-text">YOUR #1 Solution to Look, Feel, & Perform Your Best</span>
                            <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()" class="welcome-text">Welcome to {{(_enrollSession.data.RetailEnroll?.webAlias)}}'s #1 Solution to Look, Feel, & Perform Your Best</span>
                        </div>
                        <span fxFlex="21" *ngIf="_auth.isLoggedIn()" id="userImagespan"><img [src]="_enrollSession.customerImage || IMAGE_URL+'/images/avatars/profile_icon.png'"  [attr.Error]="'this.src=\'' + IMAGE_URL + '/images/avatars/profile_icon.png\''" class="userImage"></span>
                        <span fxFlex="21" *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()" id="userImagespan"><img [src]="_enrollSession.customerImage || IMAGE_URL+'/images/avatars/profile_icon.png'"  [attr.Error]="'this.src=\'' + IMAGE_URL + '/images/avatars/profile_icon.png\''" class="userImage"></span>
                    </div>
                </div>
                </ng-container>
                <div class="border-line-div"></div>
                <div class="container sub-heading smallScreenPadding"><img [src]="IMAGE_URL+'/images/Transformation/welcomeToTranformationText.png'" alt=""></div>
                <div class="container product-image smallScreenPadding"><img [src]="'assets/images/Transformation/welcomeToTranfomationProduct1.png'" alt=""></div>
            </div>
            
            <!--------------------- second-section-why-purium's-ULT-section ------------------------------->
        <div class="container smallScreenPadding second-section">
            <div class="ultimate-life-transformation mt-4">
                <div class="steps">
                    <img [src]="IMAGE_URL+'/images/Transformation/Vector1.png'" alt="">
                    <div class="heading">ultimate</div>
                    <div class="fixed-width-border"></div>
                    <div class="para">
                        The highest standard for pure and premium quality and results, elevating every aspect of your well-being to its fullest potential.
                    </div>
                </div>
                <div class="steps">
                    <img [src]="IMAGE_URL+'/images/Transformation/Vector2.png'" alt="">
                    <div class="heading">lifestyle</div>
                    <div class="fixed-width-border"></div>
                    <div class="para">
                        This experience moves beyond a product; it’s a guide to reshaping everyday habits, helping you live with more energy, clarity, and confidence.
                    </div>
                </div>
                <div class="steps">
                    <img [src]="IMAGE_URL+'/images/Transformation/Vector3.png'" alt="">
                    <div class="heading">transformation</div>
                    <div class="fixed-width-border"></div>
                    <div class="para">
                        Designed to carry you from where you are today to where you deserve to be. This is your turning point—a complete, holistic path to feeling better, moving better, and becoming the very best version of yourself.
                    </div>
                </div>
            </div>
            <div class="container smallScreenPadding mt-4">
                <!-- <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>BEGIN YOUR JOURNEY TODAY&nbsp;&nbsp;&nbsp;</div>
                    <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a> -->
                <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                    <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a>
            </div>
         </div>
        </div>
        </div>

        <!--------------------- second-section-why-purium's-ULT-section ------------------------------->
        <div class="first-section">
            <div class="container smallScreenPadding second-section">
                <div class="large-heading mt-5">If you are experiencing...</div>
                <div class="symptoms-container mt-4">
                    <ul class="custom-list">
                        <li>Bloated Belly</li>
                        <li>Poor Digestion</li>
                        <li>Food Sensitivities</li>
                        <li>Mood Swings</li>
                        <li>Food/Beverage Cravings</li>
                        <li>Sleep Issues</li>
                        <li>Brain Fog</li>
                        <li>Fatigue</li>
                        <li>Parasites</li>
                        <li>Elimination Challenges</li>
                    </ul>
                </div>
                <div class="bottom-text mt-4">
                    ...this program is for you.
                </div>
                <div class="btm-para">
                    Don’t settle for quick fixes and embrace a solution that’s truly all-encompassing.
                </div>
            </div>
        </div>

        <!---------------------------------- fourth-section-look-feel-section -------------------------------------->
        <div class="container smallScreenPadding fourth-section-look-feel mt-4">
        <div class="display-flex">
            <div class="image">
                <img [src]="IMAGE_URL+'/images/Transformation/lookfeel_1.png'" alt="">
            </div>
            <div class="w-p-50">
                <div class="text1">Join the thousands of people who have transformed the way they </div><div class="text2"> look, feel and perform</div>
            </div>
            <div class="image">
                <img [src]="IMAGE_URL+'/images/Transformation/lookfeel_2.png'" alt="">
            </div>
        </div>
        <div class="mt-4">
            <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a>
        </div>
        </div>

        <!---------------------------------- third-section-purium-reviews-section ---------------------------------->
        <div class="container smallScreenPadding third-section-purium-reviews mt-4">
            <div class="carousel-container">
                <ngx-slick-carousel 
                #slickModal="slick-carousel" 
                [config]="slideConfig" 
                class="carousel customCarousel">
                
                <!-- Slide Item -->
                <div ngxSlickItem *ngFor="let slide of purium_reviews" class="slide">
                    <div class="testimonial-card">
                        <img [src]="slide.image" alt="unable to load please try again">
                    </div>
                </div>
                </ngx-slick-carousel>
                
                <!-- Custom Navigation -->
                <div class="navigation-arrows">
                <button (click)="slickModal.slickPrev()" class="arrow-btn">←</button>
                <div class="slick-dots1"></div>
                <div class="slick-dots1"></div>
                <div class="slick-dots1"></div>
                <div class="slick-dots1"></div>
                <button (click)="slickModal.slickNext()" class="arrow-btn">→</button>
                </div>
            </div>
        </div>

        <!---------------------------------- how-it-works-section ---------------------------------->
        <div class="section-container-lifestyle">
            <!-- Banner Section -->
            <div class="banner">
              <h2 class="">
                <span class="text1">It's not a diet.</span>
                <em class="highlight-italic text2">&nbsp; It's a lifestyle</em>
              </h2>
            </div>
          
            <!-- Content Section -->
            <div class="container smallScreenPadding content">
              <p>
                Wake up rejuvenated, feeling energetic all day, and free from bloat and brain fog – without restricting your lifestyle. 
                Fuel your body with ULT's nutrient-rich superfoods. As your body thrives, your taste buds shift to crave what fuels you. 
                Comfort foods? They won’t even compare.
              </p>
            </div>
        </div>


        <!----------------------------- Ninth-section-transform-your-life-with-the-ULT-Core-Nutrition -------------->
        <div class="ninth-section">
            <div class="container smallScreenPadding">
            <div class="sub-heading2 mt-2">IT'S SIMPLE.</div>
            <div class="main-heading mt-3"><span></span><span>Nourish. Transform. Thrive.</span></div>
            <div class="sub-heading1 mt-5">What makes the ULT unique is its simplicity and flexibility. Begin your day with delicious greens, protein, and detox capsules, and end it with soothing tart cherry juice to support restful sleep. This program focuses on nourishing your body, not depriving it. Whether you enjoy the shakes as meal replacements or alongside your meals and snacks, the nutrient-rich superfoods naturally curb cravings. Designed to fit seamlessly into any lifestyle, the ULT makes better health easy and accessible. </div>
            <div class="drink-cycle">
                <img [src]="'assets/images/Transformation/drinks-cycle.png'" alt="">
            </div>
            </div>
        </div>

        <!---------------------------------- seventh-section-meet-core-products-section ---------------------------->
        <div class="container smallScreenPadding seventh-section-meet-core-products">
            <div class="heading-video">
                The quality of food you eat is equal to the quality of life you live.
            </div>
            <div class="iframevideo">
                <iframe class="iframeClass"
                src="https://player.vimeo.com/video/870473644" frameborder="0"
                data-resize-to-parent="true" allow="clipboard-write"></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <script src="https://v0.wordpress.com/js/next/videopress-iframe.js?m=1674852142"></script>
        </div>
        <div class="container smallScreenPadding fourth-section-look-feel">
            <a (click)="BuyNowToday('11240')" class="main-btn ult-btn mt-5"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                <img class=""
            [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70"
            alt=""></a>
        </div>


        <!---------------------------------- eighth-section-core-products-carusel-section -------------------------->
        <div class="container smallScreenPadding eighth-section-core-products-carusel mb-4">
            <!-- <div class=""> -->
                <div fxFlex="90" fxFlex.lt-lg="100" fxFlex.lt-md="100" fxLayout="row" fxLayoutAlign="center center" class="margin-tb-lr">
                    <div>
                        <button (click)="slickModal1.slickPrev()" class="arrow-btn">←</button>
                    </div>
                    <ngx-slick-carousel 
                        #slickModal1="slick-carousel" 
                        [config]="!_enrollSession.mobileViewMethod() ? slideConfig1 : slideConfig2" 
                        class="carousel customCarousel">
                        <div ngxSlickItem *ngFor="let slide of meetCoreProducts" class="slide">
                        <div class="testimonial-card">
                            <img [src]="slide.image" alt="unable to load please try again">
                        </div>
                        </div>
                    </ngx-slick-carousel>
                    <div>
                        <button (click)="slickModal1.slickNext()" class="arrow-btn">→</button>
                    </div>
                </div>
            <!-- </div> -->
        </div>

        <!---------------------------------- New section product features -------------------------------->
        <div class="container smallScreenPadding">
           <img [src]="'assets/images/Transformation/product-feature-section.png'" alt="" *ngIf="!_enrollSession.mobileViewMethod()">
           <div class="product-features-section" *ngIf="_enrollSession.mobileViewMethod()">
                <div class="header-section">Every carefully <br> selected ingredient ...</div>
                <div class="para-section mt-4">...every thoughtfully guided step, reflects a commitment to sustainable practices, rigorous quality testing, and innovative formulation. It’s all designed to help you feel better, move better, and become the best version of yourself. This is your opportunity to embrace a new standard of wellness—one that’s truly ultimate.
                </div>
                <div class="line-divider"></div>
            <ngx-slick-carousel 
                #slickModal2="slick-carousel" 
                [config]="slideConfig3" 
                class="carousel customCarousel">
              <div ngxSlickItem>
                <div class="display-flex">
                    <img [src]="IMAGE_URL+'/images/Transformation/white Organic 1.png'" alt="">
                    <img [src]="IMAGE_URL+'/images/Transformation/white Vegan 1.png'" alt="">
                </div>
                <div class="display-flex">
                    <img [src]="IMAGE_URL+'/images/Transformation/white_Regenerati_Farming_Practices 1.png'" alt="">
                    <img [src]="IMAGE_URL+'/images/Transformation/w_Plastic_Free_Initiative.png'" alt="">
                </div>
              </div>
              <div ngxSlickItem>
                <div class="display-flex">
                    <img [src]="IMAGE_URL+'/images/Transformation/white Gluten-Free 1.png'" alt="">
                    <img [src]="IMAGE_URL+'/images/Transformation/white Non-GMO 1.png'" alt="">
                </div>
                <div class="display-flex">
                    <img [src]="IMAGE_URL+'/images/Transformation/w_Compostable_Packaging.png'" alt="">
                    <img [src]="IMAGE_URL+'/images/Transformation/w Generating Low.png'" alt="">
                </div>
               </div>
            </ngx-slick-carousel>
            
            <!-- Custom Navigation -->
            <div class="navigation-arrows">
              <button (click)="slickModal2.slickPrev()" class="arrow-btn">←</button>
              <div class="slick-dots1"></div>
              <div class="slick-dots1"></div>
              <button (click)="slickModal2.slickNext()" class="arrow-btn">→</button>
            </div>
          </div>
        </div>

        <!---------------------------------- fifth-section-make-commitment-section --------------------------------->
        <div class="fifth-section-make-commitment">
            <div [ngClass]="{'bg-image-desk': !_enrollSession.mobileViewMethod(), 'bg-image-mob': _enrollSession.mobileViewMethod()}">
                <div class="">
                    <img [src]="IMAGE_URL+'/images/Transformation/make-Curve-1.png'" alt="">
                </div>
                <div class="container smallScreenPadding make-commit-section">
                    <p class="intro">it takes 90 Days to create a habit</p>
                    <div>
                        <span class="text1">Transformation Happens in Phases</span>
                    </div>
                </div>
                <div class="iframeVideo">
                    <iframe class="iframeClass" src="https://player.vimeo.com/video/884136301?loop=1&muted=1" frameborder="0"
                        data-resize-to-parent="true" allow="clipboard-write"></iframe>
                </div>
            </div>
            <div class="container smallScreenPadding">
                <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>subscribe today&nbsp;&nbsp;&nbsp;</div>
                    <img class=""
                [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70"
                alt=""></a>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <script src="https://v0.wordpress.com/js/next/videopress-iframe.js?m=1674852142"></script>
        </div>

        <!---------------------------------- sixth-section-prepare-for-positive-change ----------------------------->
        <div class="container smallScreenPadding sixth-section">
            <div class="first-heading">Commit to permanent-lasting change with</div>
            <div class="main-heading transformation-phases"><span class="main-heading1 heading-text">Lifestyle Subscription
                <!-- <sup><img class="cursor-pointer" (click)="SubscriptionTextPopup()" [src]="IMAGE_URL+'/images/Transformation/transformation-info-icon.png'" alt="info-img"></sup> -->
            </span><span class="main-heading2"></span></div>
            <div class="normal-text mt-4">
                After 3 months with the ULT, you can choose to continue this 2-shake-a-day lifestyle or shift to a maintenance plan with only 1 shake a day. Most people love the results so much they never want to stop! So why wait to start feeling amazing? 
            </div>
            <div class="transformation-phases mt-5">
                <div class="stepper-section mt-4">
                    <div class="stepper-circle"><img [src]="IMAGE_URL+'/images/ultlifestyleLanding/White Blender 1.png'" alt="white blender">
                        <div class="dot">1</div>
                    </div>
                    <div class="content" *ngIf="_enrollSession.mobileViewMethod()">
                        <div class="main-heading">Today’s order</div>
                        <div class="description">Along with 30-days worth of products, your first pack includes with a portable, rechargeable hand blender.
                        </div>
                    </div>
                    <div class="stepper-circle"><img [src]="IMAGE_URL+'/images/Transformation/transformation-cube-icon.png'" alt="cube-icon">
                        <div class="dot">2</div>
                    </div>
                    <div class="content" *ngIf="_enrollSession.mobileViewMethod()">
                        <div class="main-heading">Month 2</div>
                        <div class="description">Stay consistent with your new super foods routine in Phase 2.</div>
                    </div>
                    <div class="stepper-circle"><img [src]="IMAGE_URL+'/images/Transformation/transformation-cycle.png'" alt="cycle">
                        <div class="dot">3</div>
                    </div>
                    <div class="content" *ngIf="_enrollSession.mobileViewMethod()">
                        <div class="main-heading">Month 3</div>
                        <div class="description">Month 3 has the same superfoods as Month 2, yet the benefits continue to grow.
                        </div>
                    </div>
                    <div class="stepper-circle"><img [src]="IMAGE_URL+'/images/Transformation/transformation-settings.png'" alt="settings">
                        <div class="dot">4</div>
                    </div>
                    <div class="content" *ngIf="_enrollSession.mobileViewMethod()">
                        <div class="main-heading">Month 4 & Beyond</div>
                        <div class="description">Month 4 is automatically set to transition into a maintenance pack of Purium’s Core 4 products. 
                        </div>
                    </div>
                </div>
                <div class="stepper-content mt-2" *ngIf="!_enrollSession.mobileViewMethod()">
                    <div class="content">
                        <div class="main-heading">Today’s order</div>
                        <div class="description">Along with 30-days worth of products, your first pack includes with a portable, rechargeable hand blender.
                        </div>
                    </div>
                    <div class="content">
                        <div class="main-heading">Month 2</div>
                        <div class="description">Stay consistent with your new super foods routine in Phase 2.</div>
                    </div>
                    <div class="content">
                        <div class="main-heading">Month 3</div>
                        <div class="description">Month 3 has the same superfoods as Month 2, yet the benefits continue to grow.
                        </div>
                    </div>
                    <div class="content">
                        <div class="main-heading">Month 4 & Beyond</div>
                        <div class="description">Month 4 is automatically set to transition into a maintenance pack of Purium’s Core 4 products. 
                        </div>
                    </div>
                </div>
            </div>
            <div class="subscription-details-link">
                <span (click)="SubscriptionTextPopup()">Click here to learn subscription details.</span>
            </div>
            <div>
                <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                    <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a>
            </div>
        </div>
         <div class="divider-line-grey"></div>

        <!---------------------------------- tenth-section-11240-product-section -------------------------------->
        <div class="container smallScreenPadding tenth-section-11240-product">
            <div class="heading">
                <span class="text1">Pure. Premium. <span class="text2">Affordable.</span></span>
            </div>
            <div class="sub-heading">
                Lower your food expenses and improve the quality of your life for under $13 a day. Plus, with <br *ngIf="!_enrollSession.mobileViewMethod()">Purium’s 60-Day Money-Back Guarantee, you can feel confident in the commitment to yourself.
            </div>
            <div class="reviews-price">
            <div class="reviews-section">
              <p><img [src]="IMAGE_URL+'/images/Transformation/stars-img.png'" width="100" class="star-img" /><span class="review-text"> {{reviews}} Reviews</span></p>
              <div class="dropdown-bar">
              <button class="accordion" [ngClass]="{ 'active': activeSection === 'productBenefits' }" (click)="toggleSection('productBenefits')">PRODUCT BENEFITS</button>
                <div class="reviews-section-wrapper panel" id="productBenefits"
                    [ngClass]="{ 'active': activeSection === 'productBenefits' }">
                    <div class="reviews-section w-100">
                        <p class="sections-dropdown" #content [innerHtml]="ultLongDetail" (scroll)="onScroll(1)"></p>
                    </div>
                    <div class="custom-scrollbar1" #customScrollbar (click)="scrollTo($event, 1)">
                        <div class="custom-scroll-thumb1" #thumb (touchstart)="startDrag($event, 1)" (mousedown)="startDrag($event, 1)"></div>
                    </div>
                </div>
              <button class="mt-3 accordion" [ngClass]="{ 'active': activeSection === 'labelInfo' }" (click)="toggleSection('labelInfo')">LABEL INFO</button>
              <div class="reviews-section-wrapper panel" id="labelInfo"
                    [ngClass]="{ 'active': activeSection === 'labelInfo' }">
                    <div class="reviews-section w-100">
                        <p class="sections-dropdown" #content2 [innerHtml]="ultLongDetail3" (scroll)="onScroll(2)"></p>
                    </div>
                    <div class="custom-scrollbar2" #customScrollbar2 (click)="scrollTo($event, 2)">
                        <div class="custom-scroll-thumb2" #thumb2 (touchstart)="startDrag($event, 2)" (mousedown)="startDrag($event, 2)"></div>
                    </div>
                </div>
          
              <button class="mt-3 accordion" [ngClass]="{ 'active': activeSection === 'learnMore' }" (click)="toggleSection('learnMore')">LEARN MORE</button>
              <div class="reviews-section-wrapper panel" id="learnMore"
                    [ngClass]="{ 'active': activeSection === 'learnMore' }">
                    <div class="reviews-section w-100">
                        <p class="sections-dropdown" #content3 [innerHtml]="ultLongDetail4" (scroll)="onScroll(3)"></p>
                    </div>
                    <div class="custom-scrollbar3" #customScrollbar3>
                        <div class="custom-scroll-thumb3" #thumb3 (touchstart)="startDrag($event, 3)" (mousedown)="startDrag($event, 3)"></div>
                    </div>
                </div>
                </div>
            </div>
          
            <!-- Pricing and Product Image Section -->
            <div class="pricing-section">
                <div class="displayflex">
                    <div class="m-0"><span class="text1price">Your Price: </span><br><span class="text2price"><strong>${{YourPrice | number: '1.2-2'}} USD</strong></span></div>
                    <div><span class="text3price">Retail Price: </span><br><span class="text4price"><del class="c-green">${{RetailPrices | number: '1.2-2'}} USD</del></span></div>
                </div>
                <p class="shipping-text">Shipping Calculated at Checkout</p>
                <p class="sub-head-text"><span class="arrow-btn">→</span><span>This pack less than $13/day and replaces up to 2 meals a day!</span></p>
                <div class="ult-image">
                <img [src]="ultImage" alt="Product Pack" class="product-image"><br>
                </div>
                <div>
                    <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                        <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a>
                </div>
            </div>
            </div>

            <div class="border-line-div"></div>
            <div class="bottom-part">
                <div class="heading">
                    <span class="text1">Alternative Shake Options</span>
                </div>
                <div class="display-column-flex">
                <div class="width-50 display-flex">
                    <div class="left-side">
                        <div class="custom-svg-container1">
                            <div class="ult-image">
                                <img [src]="ultCustomizableImage" alt="Product Pack" class="product-image"><br>
                            </div>
                        </div>
                    </div>
                    <div class="right-side">
                        <div class="text-ult">
                            Ultimate Lifestyle<br> Transformation -<br>Customizable
                        </div>
                        <div class="pricings mt-4">
                            <div>Loyal Customer: ${{YourCustomizablePrice}} USD</div>
                            <div>Retail: ${{RetailCustomizablePrices}} USD</div>
                        </div>
                        <div class="mt-4">
                            <a (click)="BuyNowToday('11244')" class="ult-btn">subscribe today</a>
                        </div>
                    </div>
                </div>
                <div class="border-vertical-line"></div>
                <div class="width-50 display-flex">
                    <div class="left-side">
                        <div class="custom-svg-container2">
                            <div class="ult-image">
                                <img [src]="ultDarkImage" alt="Product Pack" class="product-image"><br>
                            </div>
                        </div>
                    </div>
                    <div class="right-side">
                        <div class="text-ult-brain">
                            Ultimate Lifestyle<br> Transformation +<br>Dark Berry Protein
                        </div>
                        <div class="pricings mt-4">
                            <div>Loyal Customer: ${{YourDarkPrice}} USD</div>
                            <div>Retail: ${{RetailDarkPrices}} USD</div>
                        </div>
                        <div class="mt-4">
                            <a (click)="BuyNowToday('11280')" class="ult-btn">subscribe today</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="border-line-div"></div>

          </div>
          
          <!---------------------------- Eleventh-section-banefit-phases-section --------------------------->
        <div class="eleventh-section">
            <div class="">
                <img [src]="IMAGE_URL+'/images/Transformation/make-Curve-1.png'" alt="">
            </div>
            <div class="container smallScreenPadding main-heading">Prepare for <span class="text-color">positive</span> change</div>
            <div class="container smallScreenPadding mt-5" *ngIf="!_enrollSession.mobileViewMethod()">
            <div class="phase-sections">
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6">
                        <img [src]="IMAGE_URL+'/images/Transformation/phasestep1.svg'" alt="">
                    </div>
                </div>
                <img [src]="'assets/images/Transformation/Frame 166.png'" alt="">
                <img [src]="IMAGE_URL+'/images/Transformation/phasesstep2.svg'" class="mt-2" alt="">
                <img [src]="'assets/images/Transformation/Frame 173.png'" class="mt-2" alt="">
                <div class="row mt-1">
                    <div class="col-6"></div>
                    <div class="col-6">
                        <img [src]="'assets/images/Transformation/phasestep3.svg'" alt="">
                    </div>
                </div>
            </div>
            <div class="phase-sections">
                <img [src]="'assets/images/Transformation/Frame 174.png'" class="mt-2" alt="">
                <img [src]="IMAGE_URL+'/images/Transformation/phasestep4.svg'"  class="mt-2" alt="">
            </div>
            <div class="phase-sections mt-1">
                <img [src]="'assets/images/Transformation/Frame 175.png'" alt="">
            </div>
            </div>
          <div class="container smallScreenPadding mt-4" *ngIf="_enrollSession.mobileViewMethod()">
            <ngx-slick-carousel #slickModal3="slick-carousel" [config]="slideConfig4" class="carousel customCarousel">
                <div ngxSlickItem *ngFor="let slide of productBenefitFrames">
                  <div class="plr-10">
                    <img [src]="slide.heading" alt="product-benefit-image" class="carouselImg1">
                    <img class="mt-3 carouselImg2" [src]="slide.image" alt="product-benefit-image">
                  </div>
                </div>
            </ngx-slick-carousel>
            <!-- Custom Navigation -->
            <div class="navigation-arrows">
                <button (click)="slickModal3.slickPrev()" class="arrow-btn">←</button>
                <div class="slick-dots1"></div>
                <div class="slick-dots1"></div>
                <div class="slick-dots1"></div>
                <div class="slick-dots1"></div>
                <button (click)="slickModal3.slickNext()" class="arrow-btn">→</button>
            </div>
          </div>
          <div>
                <a (click)="BuyNowToday('11240')" class="container smallScreenPadding main-btn ult-btn"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                    <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a>
          </div>
        </div>
        
        <!---------------------------------- twelth-tranformation-section --------------------------------------->
        <div class="container smallScreenPadding twelth-tranformation-section">
            <div class="heading">
                <span class="text1">Congrats to All The Inspiring <span class="text2">Transformations</span></span>
            </div>
            <img [src]="IMAGE_URL+'/images/Transformation/stars-img.png'" width="100" class="star-img" />
            <div class="sub-heading">Based on 900+ Reviews</div>
            <div class="iframevideo">
                <iframe class="iframeClass"
                src="https://player.vimeo.com/video/1039831801" frameborder="0"
                data-resize-to-parent="true" allow="clipboard-write"></iframe>
            </div>
            <div>
                <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                    <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a>
            </div>
        </div>

        <!---------------------------------- thirteen-tranformation-carusel-section --------------------------------------->
        <div class="thirteen-tranformation-carusel">
            <div class="">
                <img [src]="IMAGE_URL+'/images/Transformation/make-Curve-1.png'" alt="">
            </div>
            <div class="container smallScreenPadding sub-heading">
                Good for you. good for our planet. 
            </div>
            <div class="container smallScreenPadding box-flex">
                <div class="inside-box-flex">
                    <div class="m-auto-0">
                        <div class="upper-text">
                            Organic<br> Ingredients
                        </div>
                        <div class="border-line-div"></div>
                        <div class="below-text">
                            Made with organic, non-GMO 
                            ingredients, which reduces the use
                            of synthetic pesticides, herbicides,
                            and fertilizers. These chemicals can 
                            harm ecosystems, pollute water
                            sources, and degrade soil quality.
                        </div>
                    </div>
                    <div class="m-auto-0">
                        <div class="upper-text">
                            Sustainable <br>Farming Practices 
                        </div>
                        <div class="border-line-div"></div>
                        <div class="below-text">
                            Practicing sustainable
                            agriculture, which helps
                            preserve biodiversity, enhance
                            soil health, and reduce carbon
                            emissions compared to
                            conventional farming.
                        </div>
                    </div>
                    <div class="m-auto-0">
                        <div class="upper-text">
                            Minimal <br>Processing
                        </div>
                        <div class="border-line-div"></div>
                        <div class="below-text">
                            Emphasizes whole food 
                            nutrition with minimal 
                            processing, which requires less
                            energy and fewer resources
                            than heavily processed foods.
                        </div>
                    </div>
                </div>
                <div class="inside-box-flex" [ngClass]="{'mt-5': !_enrollSession.mobileViewMethod()}">
                    <div class="m-auto-0">
                        <div class="upper-text">
                            Eco-Friendly <br>Packaging
                        </div>
                        <div class="border-line-div"></div>
                        <div class="below-text">
                            We use biodegradable and 
                            compostable packaging for many of 
                            its products, reducing plastic waste
                            and its impact on the environment.
                        </div>
                    </div>
                    <div class="m-auto-0">
                        <div class="upper-text">
                            Focus on Plant-<br>Based Nutrition
                        </div>
                        <div class="border-line-div"></div>
                        <div class="below-text">
                            By promoting plant-based
                            superfoods, Purium contributes
                            to reducing greenhouse gas 
                            emissions, water use, and land 
                            degradation associated with 
                            livestock farming.
                        </div>
                    </div>
                    <div class="m-auto-0">
                        <div class="upper-text">
                            Ethical <br>Sourcing
                        </div>
                        <div class="border-line-div"></div>
                        <div class="below-text">
                            Ingredients are often sourced from
                            regions where farming practices 
                            prioritize sustainability and respect for
                            local ecosystems.
                        </div>
                    </div>
                </div>
            </div>
            <div class="container smallScreenPadding mt-5">
                <img [src]="IMAGE_URL+'/images/Transformation/purium-waste.svg'">
            </div>
            <div class="container smallScreenPadding heading mt-5">
                <span class="text1">Purium is the <span class="text2">solution</span></span>
            </div>
            <!-- <div class="container smallScreenPadding carousel-container">
                <div class="beforeAfter">
                    <div class="elfsight-app-c3a4d58e-87d7-4dcf-b1ed-53892c5a3987"></div>
                    <script src="https://apps.elfsight.com/p/platform.js" defer></script>
                </div>
                <div class="navigation-arrows">
                    <button (click)="attachCustomNavigation()" class="arrow-btn custom-left-arrow">←</button>
                    <div class="slick-dots1"></div>
                    <div class="slick-dots1"></div>
                    <div class="slick-dots1"></div>
                    <div class="slick-dots1"></div>
                    <button (click)="attachCustomNavigation()" class="arrow-btn custom-right-arrow">→</button>
                </div>
            </div> -->
            <div class="container smallScreenPadding">
                <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                    <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a>
            </div>
        </div>

        <!---------------------------------- fourteen-products-ult-section --------------------------------------->
        <div class="container smallScreenPadding fourteen-products-ult">
            <div class="upper-part display-flex">
                <div class="left-side">
                    <div class="custom-svg-container">
                        <div class="ult-image">
                            <img [src]="ultImage" alt="Product Pack" class="product-image"><br>
                        </div>
                    </div>
                </div>
                <div class="right-side">
                    <div class="text-ult">
                        Ultimate Lifestyle<br> Transformation
                    </div>
                    <p class="mt-4"><img [src]="IMAGE_URL+'/images/Transformation/stars-img.png'" width="100" class="star-img" /><span class="review-text"> {{reviews}} Reviews</span></p>
                    <div class="pricings mt-4">
                        <div>Loyal Customer: ${{YourPrice}} USD</div>
                        <div>Retail: ${{RetailPrices}} USD</div>
                    </div>
                    <div class="mt-4">
                        <a (click)="BuyNowToday('11240')" class="main-btn ult-btn"><div>SUBSCRIBE TODAY&nbsp;&nbsp;&nbsp;</div>
                            <img class="" [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70" alt=""></a>
                    </div>
                </div>
            </div>
            <div class="border-line-div"></div>
            <div class="bottom-part">
                <div class="heading">
                    <span class="text1">Alternative Shake Options</span>
                </div>
                <div class="display-column-flex">
                <div class="width-50 display-flex">
                    <div class="left-side">
                        <div class="custom-svg-container1">
                            <div class="ult-image">
                                <img [src]="ultCustomizableImage" alt="Product Pack" class="product-image"><br>
                            </div>
                        </div>
                    </div>
                    <div class="right-side">
                        <div class="text-ult">
                            Ultimate Lifestyle<br> Transformation -<br>Customizable
                        </div>
                        <div class="pricings mt-4">
                            <div>Loyal Customer: ${{YourCustomizablePrice}} USD</div>
                            <div>Retail: ${{RetailCustomizablePrices}} USD</div>
                        </div>
                        <div class="mt-4">
                            <a (click)="BuyNowToday('11244')" class="ult-btn">subscribe today</a>
                        </div>
                    </div>
                </div>
                <div class="border-vertical-line"></div>
                <div class="width-50 display-flex">
                    <div class="left-side">
                        <div class="custom-svg-container2">
                            <div class="ult-image">
                                <img [src]="ultDarkImage" alt="Product Pack" class="product-image"><br>
                            </div>
                        </div>
                    </div>
                    <div class="right-side">
                        <div class="text-ult-brain">
                            Ultimate Lifestyle<br> Transformation +<br>Dark Berry Protein
                        </div>
                        <div class="pricings mt-4">
                            <div>Loyal Customer: ${{YourDarkPrice}} USD</div>
                            <div>Retail: ${{RetailDarkPrices}} USD</div>
                        </div>
                        <div class="mt-4">
                            <a (click)="BuyNowToday('11280')" class="ult-btn">subscribe today</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="border-line-div"></div>
        </div>

        <!---------------------------------- footer-section-para --------------------------------------------------->
        <div class="subscription-details-link2 container smallScreenPadding">
            <span (click)="SubscriptionTextPopup()">Click here to learn subscription details.</span>
        </div>
    </div>

    <div fxHide.lt-sm fxShow style="margin-right: 30px;">
        <app-footercomponent></app-footercomponent>
    </div>
    <div fxHide fxShow.lt-sm>
        <app-footercomponent></app-footercomponent>
    </div>
</section>