import { Component, OnInit } from '@angular/core';
import { EnrollSessionService } from '../enrollSession.service';
import { SharedMethodService } from '../sharedMethod.service';
import { RestService } from '../rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { IMAGE_URL } from 'app/constants';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;
public stats:string ='';
  location: Location;
  productList: any[] = [];
  routPath: any = "Subscribe";
  EnhanceTranformation: any[] = [];
  onSearchEnhance: any;
  panelOpenState: boolean = true;
  siteType: any;
  url: any;
  webalias: any;
  customerDetail: any;
  customertype: any;
  allProductList: any;
  smarOrderallProductList: any;

  constructor(private _restService: RestService,
    public _enrollSession: EnrollSessionService,
    public _sharedMethodService: SharedMethodService,
    private router: Router,
    private _location: Location,
    private _activateRoute: ActivatedRoute,
    private _toastr: ToastrService,
    ) {
    this._enrollSession.data.customertype = 22;
    this.location = this._location;
    if (this._enrollSession.editTransformationPack != 'true' && !this._enrollSession.data.IsLoggedIn) {
      this._enrollSession.data = {};
    }
    
    if(sessionStorage.getItem('onUltLifestyleFlow') == 'true' && this._enrollSession.data.IsLoggedIn)
    {
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
      this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
      sessionStorage.setItem('onUltLifestyleFlow', 'false');
    }

    if (this._enrollSession.data && this._enrollSession.editTransformationPack != 'true' && Object.keys(this._enrollSession.data).length === 0) {
      this._enrollSession.data.IsShowCart = false;
      this._enrollSession.data.page = this.location.path();
      this._enrollSession.data.TotalItemLength = 0;
      this._enrollSession.data.RetailEnroll = {};
      this._enrollSession.data.InitOrder = [];
      this._enrollSession.data.InitialOrdersSubTotal = 0;
      this._enrollSession.data.RetailOrderSummary = {};
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this._enrollSession.data.EligibleForFreeUpgrade = false;
      this._enrollSession.data.RetailEnroll.ShippingMethod = '';
      this._enrollSession.data.usePrimaryCard = null;
      this._enrollSession.data.primaryCreditCardField = {};
      this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
      this._enrollSession.data.IsUpGrade =
        this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
      if (this._enrollSession.data.IsUpGrade) {
        localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
      }
      this._enrollSession.data.Volume109 = 0;
      this._enrollSession.data.SmartOrderBVTotal = 0;
      this._enrollSession.data.InitialOrdersQVTotal = 0;
      this._enrollSession.data.AddOnOrdersQVTotal = 0;
    }
    this.url = window.location.href;
    this.webalias = this._activateRoute.snapshot.queryParamMap.get('giftcard');
    this.siteType = this._activateRoute.snapshot.queryParamMap.get('site');
    if (this.siteType) {
      sessionStorage.setItem("siteType", this.siteType);
      var snapshot = this._activateRoute.snapshot;
      const params = { ...snapshot.queryParams };
      delete params.site
      this.router.navigate([], { queryParams: params });
    } else {
      this.siteType = sessionStorage.getItem("siteType");
    }
    if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
      const request = '?WebAlias=' + this.webalias;
      this._restService.GetCustomerSite(request).subscribe((result: any) => {
          if (result.Data != null) {
              this._enrollSession.data.webAlias = this.webalias;
              this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
          } else {
              this._toastr.error('Invalid WebAlias.', 'Error');
          }
      }, error => {
          this._toastr.error('Somthing is wrong Please try again', 'Error');
      });
  }
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    sessionStorage.setItem("siteType",'');

    this._restService.getshopStats().subscribe((res:any)=>{
      this.stats = res; 
    })
  }
  redirectToUltlifestyle() {
    if (sessionStorage.getItem("SSOToken")) {
      window.location.href = 'https://php-ult-smartorder.wsicloud.net?SSOToken=' + encodeURIComponent(sessionStorage.getItem("SSOToken")) + (this._enrollSession.data.webAlias? "&giftcard="+this._enrollSession.data.webAlias:'');
    } else {
      window.location.href = 'https://php-ult-smartorder.wsicloud.net/' + (this._enrollSession.data.webAlias? "?giftcard="+this._enrollSession.data.webAlias:'');
    }
  }
    
  redirectToProduct() {
    this.router.navigate(['/AllProducts'],{ queryParams: {giftcard: this._enrollSession.data.webAlias}});
  }

}
