import { NgModule } from '@angular/core';
import {ShippingModule} from './Shipping/Shipping.module';
import { ConfirmationModule } from './Confirmation/Confirmation.module';
import { SubscribeModule } from './Subscriptions/Subscribe.module';
import { CheckoutModule } from './Checkout/Checkout.module';
import { SummaryCartModule } from './summary-cart/summary-cart.module';
import { PipesModule } from './pipe/pipe.module';
import { StepperModule } from './stepper/stepper.module';
import { TodaysOrderModule } from './Todays-order/Todays-order.module';
import { footerModule } from 'app/shared/footer/footer.module';
import { PasswordResetModule } from './password-reset/password-reset.module';
import { HomeModule } from 'app/shared/home/home.module';
import { UltpackModule } from './ultpack/ultpack.module';
import { UltlifestyleModule } from './ultlifestyle/ultlifestyle.module';
import { EnhanceModule } from './Orders/Enhance.module';
import { OrderReviewModule } from './order-review/order-review.module';
import { CalendarsModule } from './calendar/calendar.module';
import { FAQModule } from './faq/faq.module';
import { HomePageModule } from './home/homepage.module';
import { CollectionModule } from './collections/collections.module';
import { SummaryCartModuleButton } from './summary-cart-button/summary-cart-button.module';
import { ResetShopifyPasswordModule } from './reset-shopify-password/reset-shopify-password.module';
import { Subscribe2Module } from './Subscribe2/Subscribe2.module';
import { ReviewPageModule } from './ReviewPage/ReviewPage.module';
import { NewsLetterSignupModule } from './newslettersignup/newslettersignup.module';
import { AmyAndDaveModule } from './amyAndDave/amyAndDave.module';
import { TransformationModule } from './transformation/transformation.module';

@NgModule({
  imports: [
        TodaysOrderModule,
        EnhanceModule,
        SubscribeModule,
        ShippingModule,
        Subscribe2Module,
        ConfirmationModule,
        CheckoutModule,
        SummaryCartModule,
        SummaryCartModuleButton,
        PipesModule,
        StepperModule,
        footerModule,
        PasswordResetModule,
        ResetShopifyPasswordModule,
        HomeModule,
        OrderReviewModule,
        CalendarsModule,
        FAQModule,
        HomePageModule,
        CollectionModule,
        ReviewPageModule,
        NewsLetterSignupModule,
        AmyAndDaveModule,
        TransformationModule
    ],
    exports: [
        TodaysOrderModule,
        OrderReviewModule,
        EnhanceModule,
        SubscribeModule,
        ShippingModule,
        Subscribe2Module,
        ConfirmationModule,
        CheckoutModule,
        PipesModule,
        footerModule,
        PasswordResetModule,
        ResetShopifyPasswordModule,
        UltpackModule,
        UltlifestyleModule,
        CalendarsModule,
        FAQModule,
        HomePageModule,
        CollectionModule,
        ReviewPageModule,
        NewsLetterSignupModule,
        AmyAndDaveModule,
        TransformationModule
    ],
    declarations: [
  ]
})
export class PagesModule
{

}
