<div class="page-wrapper">
  <div class="ult-checkout-section wf-section">
    <app-header></app-header>
    <app-stepper *ngIf="_enrollSession.mobileView"></app-stepper>
    <div class="collection-padding-susbcribe-date" id="shipping-page">
      <app-stepper *ngIf="!_enrollSession.mobileView"></app-stepper>
      <ng-container
        *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length>0 && siteType!='ultlifestyle'">
        <div>
          <h1 class="checkout-h1 shipping-h1">Choose a Process Type</h1>
        </div>
        <div class="radio_button_custom">
          <mat-radio-group [(ngModel)]="SubscriptionProcessType" fxLayout="row" fxLayoutAlign="space-between center"
            class="process_radio">
            <mat-radio-button value="AutoOrderProcessTy1" (click)="processTypeChange('AutoOrderProcessTy1')">ALWAYS SHIP
            </mat-radio-button>
            <mat-radio-button value="AutoOrderProcessTy2" (click)="processTypeChange('AutoOrderProcessTy2')">BACKUP
              ORDER
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <p>If you choose ALWAYS SHIP - your monthly subscription order will Always Ship on your Smart Order date.</p>
          <p>If you choose BACKUP ORDER - If you place another order before your Smart Order date, your Smart Order will
            not be processed for that current month. (Your Smart order Subscription will remain Active for future
            months).</p>
        </div>
        <h1 class="checkout-h1 shipping-h1 dateValidation">Choose A Date</h1>
        <p class="checkout-p">Smart Orders process the 1st through the 21st of each month and ship 1-4 business days
          after the process date.<br>
        </p>
        <div>
          <div>
            <div style="color: #016145; font-weight: bold; margin: 10px;" class="btn btn-outline-secondary"
              mwlCalendarToday [(viewDate)]="viewDate">
              <span class="title">
                {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
              </span>
            </div>
            <div style="text-align: right;">
              <button class="btn btn-basic" (click)="decrement()" [disabled]="prevBtnDisabled">
                 <mat-icon>arrow_left</mat-icon>
              </button>
            
              <button [disabled]="nextBtnDisabled" style="text-align: right;" class="btn btn-basic" (click)="increment()">
                <mat-icon>arrow_right</mat-icon>
              </button>
            </div>
          </div>
          <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [refresh]="refresh"
              (dayHeaderClicked)="dayHeaderClicked.emit($event)" (dayClicked)="dayClicked($event.day)"
              (beforeViewRender)="beforeMonthViewRender($event)">
            </mwl-calendar-month-view>
          </div>
        </div>
        <div class="smartOrderDateBox">
          <div class="p-r-10">
            <label>Your Smart Order Date:</label>
          </div>
          <div>
            <Span>{{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate : '--/--/----'}}</Span>
          </div>
        </div>
        <div class="credit-card-message-div  m-b-30">
          <div class="credit-card-message-icon-div p-5"><img [src]="IMAGE_URL+'/images/image/Notice-Icon.svg'" loading="lazy"
              alt="" class="notice-icon"></div>
          <div class="credit-card-message-text-div p-10">
            <div class="checkout-p credit-card-message-text">
              You have chosen to receive a monthly shipment of products. The shipping and billing information that you
              are using for your initial order will be the default method for your Smart Order. You can update this at
              any time by logging into your Smart Office.<br>
              Your credit card will only be charged today for today's order. Your credit card will <strong>NOT</strong>
              be charged today for your Monthly Subscription order. Your credit card will only be charged for your
              Monthly Subscription Order on the date you choose above.
            </div>
          </div>
        </div>
      </ng-container>
      <app-footercomponent></app-footercomponent>
    </div>
  </div>
</div>