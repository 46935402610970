<!-- ======================= PAGE CONTENT ====================================== -->
<app-header></app-header>
<div id="IncomeOpportunity-page">
    <div class="">
        <!---------------------------------- First- Section Say Yes --------------------------------------------->
        <div class="gradientClass">
            <div class="container smallScreenPadding">
                <div class="sayYesSection">
                    <div class="sayyesSubSection">
                        <img class="sayyestoliveImage" [src]="IMAGE_URL+'/images/IncomeOpportunity/sayyestolive.png'">
                        <a [href]="_enrollSession.data.webAlias ? 'https://puriumenrollment.com/Home?giftcard=' + _enrollSession.data.webAlias : 'https://puriumenrollment.com'"  target="_blank" class="main-btn bpenable-btn"><div>Enroll As A Brand Partner&nbsp;&nbsp;&nbsp;</div>
                            <img class=""
                        [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70"
                        alt=""></a><br>
                    </div>
                    <div class="sayYesSubcustomerImage">
                        <div [ngClass]="{'p-50': !_enrollSession.mobileViewMethod()}" *ngIf="_auth.isLoggedIn()">
                            <div class="imageWrapper">
                                <img class="customerImage" src="{{_enrollSession.customerImage || 'assets/images/IncomeOpportunity/Michelle Boersma-3.svg'}}" [attr.Error]="'this.src=\'' + IMAGE_URL + '/images/IncomeOpportunity/Michelle Boersma-3.svg\''">
                            </div>
                        </div>
                        <div [ngClass]="{'p-50': !_enrollSession.mobileViewMethod()}" *ngIf="!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()">
                            <img class="MichelleImage" [src]="IMAGE_URL+'/images/IncomeOpportunity/Michelle Boersma-3.svg'" [attr.Error]="'this.src=\'' + IMAGE_URL + '/images/IncomeOpportunity/Michelle Boersma-3.svg\''">
                        </div>
                        <div [ngClass]="{'p-50': !_enrollSession.mobileViewMethod()}" *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()">
                            <div class="imageWrapper">
                                <img class="customerImage" src="{{_enrollSession.customerImage || 'assets/images/IncomeOpportunity/Michelle Boersma-3.svg'}}" [attr.Error]="'this.src=\'' + IMAGE_URL + '/images/IncomeOpportunity/Michelle Boersma-3.svg\''">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sayYesBelowSubText">
                    Reach out to the person who shared Purium with you to hear their story.
                </div>
                <div class="sayYesBelowText">
                    Since 2004, Purium’s equal opportunity business model has paid thousands of<br *ngIf="!_enrollSession.mobileViewMethod()"> 
                    people just like you. From getting products paid for to covering the mortgage,<br *ngIf="!_enrollSession.mobileViewMethod()"> 
                    Purium offers part-time to full-time income* opportunity for transforming lives.
                </div>
            </div>
        </div>

        <!---------------------------------- Second- Section Who We Are & How We Live It ------------------------>
        <div class="pillars-container">
            <div class="container smallScreenPadding">
                <h1>Who We Are &amp; How We Live It</h1>
                <p class="subtitle">
                    Purium is the transformation <br>company founded upon 4 strong pillars
                </p>
                
                <div class="pillars-grid" appFadeInOnScroll>
                <div class="pillars-flex">
                    <div class="pillar">
                        <h2 class="c-lightorange">Health</h2>
                        <p>Eat superfoods.</p>
                    </div>
                    <div class="pillar w-40 mt-30">
                    <h2 class="c-green">Wealth</h2>
                    <p>Inspire abundance.</p>
                    </div>
                </div>
                <div class="pillars-flex">
                    <div class="pillar w-70">
                        <h2 class="c-lightgreen">Environment</h2>
                        <p>Love the earth.</p>
                    </div>
                    <div class="pillar mt-30">
                        <h2 class="c-orange">Community</h2>
                        <p>Build community.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Third- Section Real Impact, Real Income ---------------------------->
        <div class="realimpact-container">
            <div class="container smallScreenPadding">
                <h1>Real Impact, Real Income</h1>
                <p class="subtitle">
                    Purium’s business model is its best product
                </p>
                <div class="display-flex" [ngClass]="{'mt-50': !_enrollSession.mobileViewMethod()}">
                    <div class="cursor-pointer color-zoom" (click)="prevRealImpact()">
                        <img [src]="IMAGE_URL+'/icons/left-nav.png'" alt="Previous" class="nav-arrow left-arrow">
                    </div>
                    <ngx-slick-carousel class="carousel customCarousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let slide of realImpactBanner" class="slide">
                            <div class="align-items-center justify-content-center d-flex flex-column"
                                style="width: 100%;height: auto;">
                                <img [src]="slide.url" alt="unable to load please try again">
                            </div>
                        </div>
                    </ngx-slick-carousel>
                    <div class="cursor-pointer color-zoom" (click)="nextRealImpact()">
                        <img [src]="IMAGE_URL+'/icons/right-nav.png'" alt="Next" class="nav-arrow right-arrow">
                    </div>
                </div>
                <div class="realimpact-Headingtext">
                    Learn even more details from your very own Purium Brand Partner, <br>
                    the person who shared Purium with you.
                </div>
                <div class="realimpact-text">
                    Just as we are committed to non-GMO, organic standards and high-quality products,<br *ngIf="!_enrollSession.mobileViewMethod()">
                    we are equally committed to providing fair compensation to those who are <br *ngIf="!_enrollSession.mobileViewMethod()">
                    transforming people’s health and wealth. Whether you’re a creative, stay-at-home <br *ngIf="!_enrollSession.mobileViewMethod()">
                    parent or wellness professional, the Purium business model supports every lifestyle.<br *ngIf="!_enrollSession.mobileViewMethod()">
                </div>
            </div>
        </div>

        <!---------------------------------- Fourth- Section In Business For Yourself ---------------------------->
        <div class="business-container">
            <div class="container smallScreenPadding">
                <h1 class="main-title" appFadeInOnScroll>
                    <img class="heading" [src]="IMAGE_URL+'/images/IncomeOpportunity/businessContainerHeading.svg'">
                </h1>
                <h2 class="subtitle">
                Purium’s Owners Are Passionately <br *ngIf="!_enrollSession.mobileViewMethod()">Committed to the Purium Economy
                </h2>
            
                <div class="content-section">
                <div class="description">
                    <div>Co-founders, Amy Venner and Dave Sandoval are proud to partner with you.<br></div>
                    <div class="mt-10px">After 20 years of business, they lead the corporate team with 6 Core Values to
                        ensure the highest quality from superfood products and customer service to weekly
                        Zoom trainings and business development.<br></div>
                    <div class="mt-10px">Join them on their weekly zooms to see exactly how they are here to support you on 
                        your path to success for both health and wealth.</div>
                </div>
                <img [src]="IMAGE_URL+'/images/IncomeOpportunity/Summit2019-4 2.png'" alt="Purium Owners" class="owner-image">
                </div>

                <div class="business-amy-dave">
                    PURIUM DOES THE HEAVYLIFTING<br> SO YOU CAN DO WHAT YOU LOVE
                </div>

                <div class="display-flex" [ngClass]="{'mt-50': !_enrollSession.mobileViewMethod()}">
                    <div class="cursor-pointer color-zoom" (click)="prevBusiness()">
                        <img [src]="IMAGE_URL+'/icons/left-nav.png'" alt="Previous" class="nav-arrow left-arrow">
                    </div>
                    <ngx-slick-carousel class="carousel customCarousel" #slickModal1="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let slide of BusinessBanner" class="slide">
                            <div class="align-items-center justify-content-center d-flex flex-column"
                                style="width: 100%;height: auto;">
                                <img [src]="slide.url" alt="unable to load please try again">
                            </div>
                        </div>
                    </ngx-slick-carousel>
                    <div class="cursor-pointer color-zoom" (click)="nextBusiness()">
                        <img [src]="IMAGE_URL+'/icons/right-nav.png'" alt="Next" class="nav-arrow right-arrow">
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Fifth- Section Get Started Fast & Risk-free ------------------------->
        <div class="get-started-container">
            <div class="container smallScreenPadding">
                <h1 class="main-title">
                    Get Started Fast & Risk-free
                </h1>
                <h2 class="subtitle">
                    At Purium, health transformations<br>
                    lead to wealth transformations
                </h2>
                <div class="content-section">
                    <img [src]="IMAGE_URL+'/images/IncomeOpportunity/Copy of Opportunity BP Brochure.png'" alt="Purium Owners" class="owner-image">
                    <div>
                        <p class="description">
                            Ask the person who introduced you to Purium<br *ngIf="!_enrollSession.mobileViewMethod()">
                            how to receive a complimentary business<br *ngIf="!_enrollSession.mobileViewMethod()">
                            launch pack ($199 value) and your personalized<br *ngIf="!_enrollSession.mobileViewMethod()">
                            Gift Code at PuriumEnrollment.com
                            <br><br>
                            A great way to kick-start your business is by<br *ngIf="!_enrollSession.mobileViewMethod()">
                            hosting an event with your upline enroller - <br *ngIf="!_enrollSession.mobileViewMethod()">
                            virtually or in person.
                        </p>
                        <a [href]="_enrollSession.data.webAlias ? 'https://puriumenrollment.com/Home?giftcard=' + _enrollSession.data.webAlias : 'https://puriumenrollment.com'"  target="_blank" class="main-btn enrolls-btn mt-40">
                            <div>Enroll Now&nbsp;&nbsp;&nbsp;&nbsp;</div>
                            <img class=""
                            [src]="IMAGE_URL+'/icons/LineArrow.png'" width="70"
                            alt=""></a>
                    </div>
                </div>
                <div class="accordion" id="accordionFlushExample">
                    <div class="accordion-item p-1" style="border: none !important;">
                        <h2 class="accordion-header m-5px" id="headingOne">
                            <button (click)="ClickToOpenToggle(1)" class="accordion-button collapsed togglesButtonPayForProduct" type="button">
                                <p class="lora-bold">
                                    <span style="color: #239481">
                                        Pay For Your Products. Learn More!
                                    </span>
                                </p>
                                <mat-icon class="arrowupdown" *ngIf="togglePayForProducts">keyboard_arrow_up</mat-icon>
                                <mat-icon class="arrowupdown" *ngIf="!togglePayForProducts">keyboard_arrow_down</mat-icon>
                            </button>
                        </h2>
                        <div *ngIf="togglePayForProducts" id="collapseOne" class="">
                            <div class="accordion-body">
                                <img class="img-fluid" [src]="IMAGE_URL+'/images/IncomeOpportunity/Opportunity BP Brochure_RD0924.png'"
                                    alt="Opportunity BP Brochure #5362 RD0924 (1) 2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Sixth- Section Life-Changing Income --------------------------------->
        <div class="life-changing-income-container">
            <div class="container smallScreenPadding">
                <h1>Life-Changing Income</h1>
                <div class="content-section">
                    <img [src]="IMAGE_URL+'/images/IncomeOpportunity/Life-ChangingIncomeDesktopMobile.png'" alt="Life-Changing Income Mobile Desktop" class="owner-image">
                    <div class="sub-content-section">
                        <p class="description">
                            Learn about the qualification, Fast Start <br *ngIf="!_enrollSession.mobileViewMethod()">
                            bonuses, more in Purium’s compensation plan.
                        </p>
                        <a href="https://bit.ly/PuriumCompPlan" target="_blank" class="main-btn compensation-btn mt-40">
                            <div>View Compensation Plan&nbsp;&nbsp;&nbsp;&nbsp;</div>
                            <img class=""
                            [src]="IMAGE_URL+'/icons/LineArrow.png'" width="70"
                            alt=""></a>
                    </div>
                </div>
                <div class="display-flex" [ngClass]="{'mt-50': !_enrollSession.mobileViewMethod()}">
                    <div class="cursor-pointer color-zoom" (click)="prevIncomeChange()">
                        <img [src]="IMAGE_URL+'/icons/left-nav.png'" alt="Previous" class="nav-arrow left-arrow">
                    </div>
                    <ngx-slick-carousel class="carousel customLifeChangingCarousel" #slickModal2="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let slide of IncomeChangeBanner" class="slide">
                            <div class="align-items-center justify-content-center d-flex flex-column"
                                style="width: 90%;height: auto;">
                                <img [src]="slide.url" alt="unable to load please try again">
                            </div>
                        </div>
                    </ngx-slick-carousel>
                    <div class="cursor-pointer color-zoom" (click)="nextIncomeChange()">
                        <img [src]="IMAGE_URL+'/icons/right-nav.png'" alt="Next" class="nav-arrow right-arrow">
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Seventh- living your purpose with purium ---------------------------->
        <div class="living-your-purpose-container">
            <div class="container smallScreenPadding">
                <h1>Are You Ready To Start?</h1>
                <p class="subtitle">
                    living your purpose with purium
                </p>
                <a [href]="_enrollSession.data.webAlias ? 'https://puriumenrollment.com/Home?giftcard=' + _enrollSession.data.webAlias : 'https://puriumenrollment.com'"  target="_blank" class="main-btn enrollAsenable-btn"><div>Enroll As A Brand Partner&nbsp;&nbsp;&nbsp;</div>
                    <img class=""
                [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70"
                alt=""></a>
                <div appFadeInOnScroll>
                    <img [src]="IMAGE_URL+'/images/IncomeOpportunity/Opportunity_Living_Your_Purpose.png'" alt="Living your Purpose" class="living-your-purpose-img">
                </div>
                <p class="living-your-purpose-text">
                    The Japanese philosophical concept called Ikigai (translation:<br *ngIf="!_enrollSession.mobileViewMethod()"> “a reason to live”) is a beautiful method to use to see how<br *ngIf="!_enrollSession.mobileViewMethod()"> much Purium aligns with you and your life path.
                </p>
            </div>
        </div>

        <!---------------------------------- Eighth- Grow A Team & Quickly Earn More ----------------------------->
        <div class="grow-a-team-container">
            <div class="container smallScreenPadding">
                <h1>Grow A Team & Quickly Earn More</h1>
                <p class="subtitle">
                    Support for business and leadership<br *ngIf="!_enrollSession.mobileViewMethod()"> development is everywhere at Purium
                </p>
                <div class="GrowTeamImg">
                    <div class="GrowTeam-img">
                        <img [src]="IMAGE_URL+'/images/IncomeOpportunity/GrowTeamImg3.png'" alt="GrowTeamImg3">
                    </div>
                    <div class="GrowTeam-img">
                        <img [src]="IMAGE_URL+'/images/IncomeOpportunity/GrowTeamImg2.png'" alt="GrowTeamImg2">
                    </div>
                    <div class="GrowTeam-img">
                        <img [src]="IMAGE_URL+'/images/IncomeOpportunity/GrowTeamImg1.png'" alt="GrowTeamImg2">
                    </div>
                </div>
                <div class="accordion" id="accordionFlushExample">
                    <div class="accordion-item p-1" style="border: none !important;">
                        <h2 class="accordion-header m-5px" id="headingOne">
                            <button (click)="ClickToOpenToggle(2)" class="accordion-button collapsed togglesButtonGrowATeam" type="button">
                                <p class="lora-bold">
                                    <span style="color: #E47D4E">
                                        Cover a monthly expense in your first few months!
                                    </span>
                                </p>
                                <mat-icon class="arrowupdown" *ngIf="toggleGrowATeam">keyboard_arrow_up</mat-icon>
                                <mat-icon class="arrowupdown" *ngIf="!toggleGrowATeam">keyboard_arrow_down</mat-icon>
                            </button>
                        </h2>
                        <div *ngIf="toggleGrowATeam" id="collapseOne" class="">
                            <div class="accordion-body">
                                <img class="img-fluid" [src]="IMAGE_URL+'/images/IncomeOpportunity/GrowATeamToggleImg.png'"
                                    alt="Opportunity BP Brochure #5362 RD0924 (1) 2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Nineth- Empowering Success, One step at a time ------------------------>
        <div class="empowering-success-container">
            <div class="container smallScreenPadding">
                <h1 class="main-title">Empowering Success,<br *ngIf="!_enrollSession.mobileViewMethod()"> One Step At A Time</h1>
                <p class="subtitle">
                    Ready to Become a Brand Partner?
                </p>
                <h1 class="quesh1">Questions to Consider</h1>
                <div class="quesh1SubHead">
                    Dive deeper into conversation about enrolling as a Brand Partner with the <br>person who introduced you to Purium.
                </div>
                <div appFadeInOnScroll class="display-flex" [ngClass]="{'mt-20': !_enrollSession.mobileViewMethod()}">
                    <ngx-slick-carousel class="carousel customCarousel" #slickModal3="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let slide of QuesConsiderBanner" class="slide">
                            <div class="align-items-center justify-content-center d-flex flex-column"
                                style="width: 90%;height: auto;">
                                <img [src]="slide.url" alt="unable to load please try again">
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <a [href]="_enrollSession.data.webAlias ? 'https://puriumenrollment.com/Home?giftcard=' + _enrollSession.data.webAlias : 'https://puriumenrollment.com'"  target="_blank" class="main-btn enrollAsenable-btn"><div>Enroll As A Brand Partner&nbsp;&nbsp;&nbsp;</div>
                    <img class=""
                [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70"
                alt=""></a>
                <div class="iframevideo">
                    <iframe class="iframeClass"
                    src="https://player.vimeo.com/video/841954868" frameborder="0"
                    data-resize-to-parent="true" allow="clipboard-write"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                <script src="https://v0.wordpress.com/js/next/videopress-iframe.js?m=1674852142"></script>
            </div>
        </div>

        <!---------------------------------- Tenth- Grow In Leadership, Grow In Profits ---------------------------->
        <div class="grow-in-leadership-container">
            <div class="container smallScreenPadding">
                <h1 class="main-title">Grow In Leadership,<br *ngIf="!_enrollSession.mobileViewMethod()">Grow In Profits</h1>
                <p class="subtitle">
                    Rise to the DIAMOND rankS to <br *ngIf="!_enrollSession.mobileViewMethod()">discover new levels of benefit
                </p>
                <div class="content-section">
                    <p class="description">
                        Diamond unlocks special perks in the 
                        Compensation Plan that will add to 
                        your Purium profits, including North American 
                        profit sharing pools and Lifestyle Bonuses. 
                        Throughout the Diamond ranks there is a vast 
                        range of life-changing income potential.
                    </p>
                    <img [src]="IMAGE_URL+'/images/IncomeOpportunity/Grow_In_Leadership.png'" alt="Purium Owners" class="grow-in-leaders-image">
                </div>
                <div class="accordion" id="accordionFlushExample">
                    <div class="accordion-item p-1" style="border: none !important;">
                        <h2 class="accordion-header m-5px" id="headingOne">
                            <button (click)="ClickToOpenToggle(3)" class="accordion-button collapsed togglesButtonGrowInLeader" type="button">
                                <p class="lora-bold">
                                    <span style="color: #3F76CD">
                                        Diamond unlocks special perks
                                    </span>
                                </p>
                                <mat-icon class="arrowupdown" *ngIf="toggleGrowInLeader">keyboard_arrow_up</mat-icon>
                                <mat-icon class="arrowupdown" *ngIf="!toggleGrowInLeader">keyboard_arrow_down</mat-icon>
                            </button>
                        </h2>
                        <div *ngIf="toggleGrowInLeader" id="collapseOne" class="">
                            <div class="accordion-body">
                                <img class="img-fluid" [src]="IMAGE_URL+'/images/IncomeOpportunity/GrowInLeaderToggleImg.png'"
                                    alt="Grow In Leader Toggle Img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Eleventh- Diamond Unlocks Many Pecks ---------------------------------->
        <div class="diamond-unlocks-many-pecks-container">
            <div class="container smallScreenPadding">
                <h1 class="main-title">Diamond Unlocks Many Perks</h1>
                <p class="subtitle">
                    Unlock limitless earnings: <br *ngIf="!_enrollSession.mobileViewMethod()">Profit Sharing grows with every rank
                </p>
                <div class="mt-30">
                    <img [src]="IMAGE_URL+'/images/IncomeOpportunity/DiamondUnlocksPecks.png'" alt="DiamondUnlocksPecks" class="DiamondUnlocksPecks-img">
                </div>
                <div class="accordion" id="accordionFlushExample">
                <div class="accordion-item p-1" style="border: none !important;">
                    <h2 class="accordion-header m-5px" id="headingOne">
                        <button (click)="ClickToOpenToggle(4)" class="accordion-button collapsed togglesButtonDiamondPecks" type="button">
                            <p class="lora-bold">
                                <span style="color: #3F76CD">
                                    Profit Sharing begins at Diamond
                                </span>
                            </p>
                            <mat-icon class="arrowupdown" *ngIf="toggleDiamondPecks">keyboard_arrow_up</mat-icon>
                            <mat-icon class="arrowupdown" *ngIf="!toggleDiamondPecks">keyboard_arrow_down</mat-icon>
                        </button>
                    </h2>
                    <div *ngIf="toggleDiamondPecks" id="collapseOne" class="">
                        <div class="accordion-body">
                            <img class="img-fluid" [src]="IMAGE_URL+'/images/IncomeOpportunity/DiamondPecksToggle.png'"
                                 alt="Grow In Leader Toggle Img">
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Twelfth- The Sky Is The Limit With Purium Commission ------------------>
        <div class="the-sky-is-the-limit-with-purium-commission-container">
            <div class="container smallScreenPadding">
                <h1 class="main-title">The Sky Is The Limit <br *ngIf="!_enrollSession.mobileViewMethod()">With Purium Commission</h1>
                <p class="subtitle">
                    Discover multiple streams of full-time<br *ngIf="!_enrollSession.mobileViewMethod()"> income as a purium crown
                </p>
                <div class="mt-30">
                    <img [src]="IMAGE_URL+'/images/IncomeOpportunity/SkyLimitCrown.png'" alt="SkyLimitCrown" class="SkyLimitCrown-img">
                </div>
                <div class="accordion" id="accordionFlushExample">
                    <div class="accordion-item p-1" style="border: none !important;">
                        <h2 class="accordion-header m-5px" id="headingOne">
                            <button (click)="ClickToOpenToggle(5)" class="accordion-button collapsed togglesButtonSkyLimit" type="button">
                                <p class="lora-bold">
                                    <span style="color: #E47D4E">
                                        Income as a Purium Crown
                                    </span>
                                </p>
                                <mat-icon class="arrowupdown" *ngIf="toggleSkyLimitCrown">keyboard_arrow_up</mat-icon>
                                <mat-icon class="arrowupdown" *ngIf="!toggleSkyLimitCrown">keyboard_arrow_down</mat-icon>
                            </button>
                        </h2>
                        <div *ngIf="toggleSkyLimitCrown" id="collapseOne" class="">
                            <div class="accordion-body">
                                <img class="img-fluid" [src]="IMAGE_URL+'/images/IncomeOpportunity/SkyLimitCrownToggle.png'"
                                    alt="SkyLimitCrownToggle Img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Thirteen- Purium BP Text ----------------------------------------------->
        <div class="puriumBP-text">
            <div class="container smallScreenPadding">
                <h1 class="PuriumBPHead">Real People, Real Success</h1>
                <p>
                    Think it’s too good to be true? All the photos are real-life<br *ngIf="!_enrollSession.mobileViewMethod()">
                    Purium Brand Partners who said “Yes” and created the lifestyle they desired.
                </p>
                <div class="PuriumBPSubHead">
                    Connect with your Purium Brand Partner to learn more stories!
                </div>
            </div>
        </div>

        <!---------------------------------- Fourteen- Purium Crown Persons Slick ------------------------------------->
        <div class="purium-crown-persons-slick">
            <div class="container smallScreenPadding">
                <div class="display-flex" [ngClass]="{'mt-50': !_enrollSession.mobileViewMethod()}">
                    <div class="cursor-pointer color-zoom" (click)="prevCrownPerson()">
                        <img [src]="IMAGE_URL+'/icons/left-nav.png'" alt="Previous" class="nav-arrow left-arrow">
                    </div>
                    <ngx-slick-carousel class="carousel customCarousel" #slickModal4="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let slide of CrownPersonBanner" class="slide">
                            <div class="align-items-center justify-content-center d-flex flex-column"
                                style="width: 90%;height: auto;">
                                <img [src]="slide.url" alt="unable to load please try again">
                            </div>
                        </div>
                    </ngx-slick-carousel>
                    <div class="cursor-pointer color-zoom" (click)="nextCrownPerson()">
                        <img [src]="IMAGE_URL+'/icons/right-nav.png'" alt="Next" class="nav-arrow right-arrow">
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Fifteen- Last Section Say Yes -------------------------------------------->
        <div class="purium-say-yes-container">
            <div class="container smallScreenPadding">
                <h1 class="main-title">
                    <img class="heading" [src]="IMAGE_URL+'/images/IncomeOpportunity/SayYesLastSection.png'">
                </h1>
                <p class="say-yes-text">
                    Get Your Personalized Gift Code<br *ngIf="!_enrollSession.mobileViewMethod()"> to start building a team and residual income today
                </p>
                <a [href]="_enrollSession.data.webAlias ? 'https://puriumenrollment.com/Home?giftcard=' + _enrollSession.data.webAlias : 'https://puriumenrollment.com'"  target="_blank" class="main-btn enrollAsenable-btn"><div>Enroll As A Brand Partner&nbsp;&nbsp;&nbsp;</div>
                    <img class=""
                [src]="IMAGE_URL+'/icons/WhiteLineArrow.png'" width="70"
                alt=""></a>
            </div>
        </div>

    </div>
    <app-footercomponent style="width: 100%;"></app-footercomponent>
</div>