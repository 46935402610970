<app-header></app-header>
<div class="page-wrapper" id="confirmation-Page">
  <div class="ult-checkout-section wf-section">
    <div class="checkout-div MobileWhitespace" id="checkoutPage">
      <div class="order-confirmation-container">
        <div>
          <a class="b-n m-l-auto color-green" type="button">
            <span class="back-button" (click)="ClickToBack()">Back</span>
        </a>
        </div>
        <h1 class="order-confirmation-h1 f-s-35">Almost Done!</h1>
        <div class="order-number-text f-s-20">Please Review Today's Order</div>
        <div class="map-div">
          <!------------------------------------ Customer Address Details ------------------------------------->
          <div class="confirmation-Details">
            <div class="w-layout-grid customer-info-grid">
                <div class="confirmation-customer-info-text-div">
                  <div class="confirmation-customer-info-h2 p-color-5">Shipping Details  &nbsp;<span class="cursor-pointer" (click)="ClickToRedirect('shippingDetails')"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="15" *ngIf="!_enrollSession.mobileViewMethod()"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="10" *ngIf="_enrollSession.mobileViewMethod()"></span></div>
                  <div class="confirmation-customer-info-p shipping-Details">
                    {{_enrollSession.data.RetailOrderSummary?.shippingFormData?.address1}}
                    {{_enrollSession.data.RetailOrderSummary?.shippingFormData?.address2}},<br>
                    {{_enrollSession.data.RetailOrderSummary?.shippingFormData?.City}},
                    {{_enrollSession.data.RetailOrderSummary?.shippingFormData?.State}}<br>
                    {{_enrollSession.data.RetailOrderSummary?.shippingFormData?.CountryCode}},
                    {{_enrollSession.data.RetailOrderSummary?.shippingFormData?.ZipCode}}</div>
                </div>
                <div class="confirmation-customer-info-text-div">
                  <div class="confirmation-customer-info-h2 p-color-5">Billing Details  &nbsp;<span class="cursor-pointer" (click)="ClickToRedirect('billingDetails')"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="15" *ngIf="!_enrollSession.mobileViewMethod()"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="10" *ngIf="_enrollSession.mobileViewMethod()"></span></div>
                  <div class="confirmation-customer-info-p padding-top-bottom-order-details w-max-content">
                    {{_enrollSession.data.RetailOrderSummary?.billingAddressData?.address1}}
                    {{_enrollSession.data.RetailOrderSummary?.billingAddressData?.address2}},<br>
                    {{_enrollSession.data.RetailOrderSummary?.billingAddressData?.City}},
                    {{_enrollSession.data.RetailOrderSummary?.billingAddressData?.State}}<br>
                    {{_enrollSession.data.RetailOrderSummary?.billingAddressData?.CountryCode}},
                    {{_enrollSession.data.RetailOrderSummary?.billingAddressData?.ZipCode}}</div>
                </div>
                <div class="confirmation-customer-info-text-div">
                  <div class="confirmation-customer-info-h2 p-color-5">Shipping Method  &nbsp;<span class="cursor-pointer" (click)="ClickToRedirect('shippingMethod')"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="15" *ngIf="!_enrollSession.mobileViewMethod()"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="10" *ngIf="_enrollSession.mobileViewMethod()"></span></div>
                  <div class="confirmation-customer-info-p shipping-Method">{{_enrollSession.data.RetailEnroll?.ShippingDescription ||
                    _enrollSession.data?.Shipping_descriptionField}}</div>
                </div>
                <div class="confirmation-customer-info-text-div">
                  <div class="confirmation-customer-info-h2 p-color-5"><span>Payment Method  </span>&nbsp;<span class="cursor-pointer" (click)="ClickToRedirect('paymentMethod')"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="15" *ngIf="!_enrollSession.mobileViewMethod()"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="10" *ngIf="_enrollSession.mobileViewMethod()"></span></div>
                  <div class="confirmation-customer-info-p padding-top-bottom-order-details w-auto">CC ending with {{cardNum}}<br></div>
                </div>
            </div>
          </div>

          <!------------------------------------- Without Subcription Box -------------------------------------->
          <div class="Item-confirmation-Details" *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.InitialOrders && _enrollSession.data.RetailOrderSummary?.InitialOrders?.length > 0 ) || ( _enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
            <div class="text-center text-details">Today's Order Details</div>
            <!-------------------------- Initial Orders ---------------------------------------->
            <div class="w-layout-grid customer-info-grid item-grid"
              *ngFor="let item of _enrollSession.data.RetailOrderSummary?.InitialOrders">
              <div class="confirmation-customer-info-text-div p-t-b-20">
                <div class="confirmation-customer-info-p p-t-5">
                  <img src="{{item?.SmallImage}}" alt="" width="100">
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <div class="confirmation-customer-info-p">
                  {{item?.Description}}
                </div>
                <div class="confirmation-customer-info-p products" *ngIf="!_enrollSession.mobileViewMethod()">
                  BV : {{item?.BV}}
                </div>
                <div class="confirmation-customer-info-p products" *ngIf="_enrollSession.mobileViewMethod()">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) |number : '1.2-2' }} x {{item?.Quantity}}
                    </div>
                </ng-container>
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails" *ngIf="_enrollSession.mobileViewMethod()">
                <div class="confirmation-customer-info-p">
                  BV : {{item?.BV}}
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                  <div class="confirmation-customer-info-p">
                    <button class="cursor-pointer button-minus-plus" [disabled]="item.ParentId ? true : false" (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,2)">-</button>&nbsp;{{item?.Quantity}}&nbsp;<button class="cursor-pointer button-minus-plus" [disabled]="item.ParentId ? true : false" (click)="_sharedMethodService.ContinueRetailOrder(item,1,2);">+</button>
                  </div>
                </ng-container>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod()">
                <div class="confirmation-customer-info-p">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) |number : '1.2-2' }} x {{item?.Quantity}}
                    </div>
                </ng-container>
                <ng-container *ngIf="( item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p"
                      *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                      {{item?.Quantity}}</div>
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                       class="confirmation-customer-info-p"
                       *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                      {{item?.Quantity}}
                  </div>
              </ng-container>
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod()">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                  <div class="confirmation-customer-info-p">
                    <span class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">Remove</span>
                  </div>
                </ng-container>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <div class="confirmation-customer-info-p f-w-bold">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p f-w-bold"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price * item?.Quantity |number : '1.2-2'}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p f-w-bold"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) * item?.Quantity |number : '1.2-2'}}
                    </div>
                </ng-container>
                </div>
                <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                  <span class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">Remove</span>
                  </ng-container>
                  <ng-container *ngIf="( item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId))">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        {{item?.Quantity}}
                    </div>
                </ng-container>
                </div>
              </div>
            </div>

            <!-------------------------- Enrollpack Orders For UltLifestyle ---------------------------------------->
            <ng-container *ngIf="siteType=='ultlifestyle'">
              <div class="w-layout-grid customer-info-grid item-grid-ult"
              *ngFor="let item of _enrollSession.data.RetailOrderSummary?.Enrollpack">
              <div class="confirmation-customer-info-text-div p-t-b-20">
                <div class="confirmation-customer-info-p p-t-5">
                  <img src="{{item?.SmallImage}}" alt="" width="100">
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <div class="confirmation-customer-info-p">
                  {{item?.Description}}
                </div>
                <div class="confirmation-customer-info-p products" *ngIf="!_enrollSession.mobileViewMethod()">
                  BV : {{item?.BV}}
                </div>
                <div class="confirmation-customer-info-p products" *ngIf="_enrollSession.mobileViewMethod()">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) |number : '1.2-2' }} x {{item?.Quantity}}
                    </div>
                </ng-container>
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails" *ngIf="_enrollSession.mobileViewMethod()">
                <div class="confirmation-customer-info-p">
                  BV : {{item?.BV}}
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                  <div class="confirmation-customer-info-p">
                    {{item?.Quantity}}
                  </div>
                </ng-container>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod()">
                <div class="confirmation-customer-info-p">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) |number : '1.2-2' }} x {{item?.Quantity}}
                    </div>
                </ng-container>
                <ng-container *ngIf="( item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p"
                      *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                      {{item?.Quantity}}</div>
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                       class="confirmation-customer-info-p"
                       *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                      {{item?.Quantity}}
                  </div>
              </ng-container>
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <div class="confirmation-customer-info-p f-w-bold">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p f-w-bold"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price * item?.Quantity |number : '1.2-2'}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p f-w-bold"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) * item?.Quantity |number : '1.2-2'}}
                    </div>
                </ng-container>
                </div>
                <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
                  <ng-container *ngIf="( item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId))">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        {{item?.Quantity}}
                    </div>
                </ng-container>
                </div>
              </div>
            </div>
            </ng-container>

            <!-------------------------- Enrollpack Orders For Not UltLifestyle ---------------------------------------->
            <ng-conatiner *ngIf="siteType!='ultlifestyle'">
              <div class="w-layout-grid customer-info-grid item-grid"
              *ngFor="let item of _enrollSession.data.RetailOrderSummary?.Enrollpack">
              <div class="confirmation-customer-info-text-div p-t-b-20">
                <div class="confirmation-customer-info-p p-t-5">
                  <img src="{{item?.SmallImage}}" alt="" width="100">
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <div class="confirmation-customer-info-p">
                  {{item?.Description}}
                </div>
                <div class="confirmation-customer-info-p products" *ngIf="!_enrollSession.mobileViewMethod()">
                  BV : {{item?.BV}}
                </div>
                <div class="confirmation-customer-info-p products" *ngIf="_enrollSession.mobileViewMethod()">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) |number : '1.2-2' }} x {{item?.Quantity}}
                    </div>
                </ng-container>
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails" *ngIf="_enrollSession.mobileViewMethod()">
                <div class="confirmation-customer-info-p">
                  BV : {{item?.BV}}
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                  <div class="confirmation-customer-info-p">
                    <button class="cursor-pointer button-minus-plus" [disabled]="item.ParentId ? true : false" (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,1)">-</button>&nbsp;{{item?.Quantity}}&nbsp;<button class="cursor-pointer button-minus-plus" [disabled]="item.ParentId ? true : false" (click)="_sharedMethodService.ContinueRetailOrder(item,1,1);">+</button>
                  </div>
                </ng-container>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod()">
                <div class="confirmation-customer-info-p">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) |number : '1.2-2' }} x {{item?.Quantity}}
                    </div>
                </ng-container>
                <ng-container *ngIf="( item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p"
                      *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                      {{item?.Quantity}}</div>
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                       class="confirmation-customer-info-p"
                       *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                      {{item?.Quantity}}
                  </div>
              </ng-container>
                </div>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod()">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                  <div class="confirmation-customer-info-p">
                    <span class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'transformation')">Remove</span>
                  </div>
                </ng-container>
              </div>
              <div class="confirmation-customer-info-text-div itemDetails">
                <div class="confirmation-customer-info-p f-w-bold">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p f-w-bold"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        ${{item?.Price * item?.Quantity |number : '1.2-2'}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p f-w-bold"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        ${{_commonService.getItemPrice(item) * item?.Quantity |number : '1.2-2'}}
                    </div>
                </ng-container>
                </div>
                <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
                  <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                  <span class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'transformation')">Remove</span>
                  </ng-container>
                  <ng-container *ngIf="( item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId))">
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                        class="confirmation-customer-info-p"
                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                        {{item?.Quantity}}</div>
                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                         class="confirmation-customer-info-p"
                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                        {{item?.Quantity}}
                    </div>
                </ng-container>
                </div>
              </div>
            </div>
            </ng-conatiner>

          </div>
          <div class="confirmation-Details p-0 m-b-20" *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.InitialOrders && _enrollSession.data.RetailOrderSummary?.InitialOrders?.length > 0 ) || ( _enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
            <div *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.InitialOrders && _enrollSession.data.RetailOrderSummary?.InitialOrders?.length > 0 ) || ( _enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
              <div class="order-summary-totals-div-block"
                  *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                  <div class="order-summary-totals-text-div-block">
                      <div class="order-summary-text  ">SubTotal</div>
                      <div class="order-summary-text  ">Shipping</div>
                      <div class="order-summary-text green-color" *ngIf="_enrollSession.data.RetailOrderSummary?.InitialOrderDetails?.SubTotal > 250">Shipping Discount (orders over $250)</div>
                      <div class="order-summary-text  ">Tax</div>
                      <div class="order-summary-text  " *ngIf="_sharedMethodService.validCustomerType()">QV</div>
                      <div class="order-summary-text  " *ngIf="_sharedMethodService.validCustomerType()">BV</div>
                      <div class="order-summary-text  ">Order Total</div>
                      <div class="order-summary-text green-color" *ngIf="_calculateRetailOrderService.isSelected">Rewards Points</div>
                      <div class="order-summary-text  " *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                      <div class="order-summary-text  ">Amount Charged</div>
                      <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                      <div class="order-summary-text  " *ngIf="false">Promo Savings:</div>
                  </div>
                  <div class="order-summary-totals-text-div-block">
                      <div class="order-summary-text t-a-r  ">
                          <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number: '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r  ">
                          <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal
                              |number : '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r green-color" *ngIf="_enrollSession.data.RetailOrderSummary?.InitialOrderDetails?.SubTotal > 250">
                        <span>You saved $10 on shipping</span>
                      </div>
                      <div class="order-summary-text t-a-r  ">
                          <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                              '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r  " *ngIf="_sharedMethodService.validCustomerType()">
                          <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.BV}} </span>
                      </div>
                      <div class="order-summary-text t-a-r  " *ngIf="_sharedMethodService.validCustomerType()">
                          <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.CV}}</span>
                      </div>
                      <div class="order-summary-text t-a-r  ">
                          <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                              '1.2-2'}} USD</span>
                      </div>
                      <div class="order-summary-text t-a-r green-color" *ngIf="_calculateRetailOrderService.isSelected">
                          <span>(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}} Redeemed)</span>
                      </div>
                      <div class="order-summary-text t-a-r  " *ngIf="_calculateRetailOrderService.isSelected">
                          <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r  ">
                        <span *ngIf="!_calculateRetailOrderService.isSelected">${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                          '1.2-2'}} USD</span>
                        <span *ngIf="_calculateRetailOrderService.isSelected">${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r" >
                          <span id="totalsaving"> <b> ${{ _commonService.getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                      </div>
                      <div class="order-summary-text t-a-r  " *ngIf="false">
                        <span>$</span>
                      </div>
                  </div>
              </div>

              <div class="order-summary-totals-div-block"
                  *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                  <div class="order-summary-totals-text-div-block">
                      <div class="order-summary-text  ">SubTotal</div>
                      <div class="order-summary-text  ">Shipping</div>
                      <div class="order-summary-text green-color" *ngIf="_enrollSession.data.RetailOrderSummary?.InitialOrderDetails?.SubTotal > 250">Shipping Discount (orders over $250)</div>
                      <div class="order-summary-text  ">Tax</div>
                      <div class="order-summary-text  " *ngIf="_sharedMethodService.validCustomerType()">QV</div>
                      <div class="order-summary-text  " *ngIf="_sharedMethodService.validCustomerType()">BV</div>
                      <div class="order-summary-text  ">Order Total</div>
                      <div class="order-summary-text green-color" *ngIf="_calculateRetailOrderService.isSelected">Rewards Points</div>
                      <div class="order-summary-text  " *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                      <div class="order-summary-text  ">Amount Charged</div>
                      <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                  </div>
                  <div class="order-summary-totals-text-div-block">
                      <div class="order-summary-text t-a-r  ">
                          <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number
                              :
                              '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r  ">
                          <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal
                              |number : '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r green-color" *ngIf="_enrollSession.data.RetailOrderSummary?.InitialOrderDetails?.SubTotal > 250">
                        <span>You saved $10 on shipping</span>
                      </div>
                      <div class="order-summary-text t-a-r  ">
                          <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                              '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r  " *ngIf="_sharedMethodService.validCustomerType()">
                          <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.BV}} </span>
                      </div>
                      <div class="order-summary-text t-a-r  " *ngIf="_sharedMethodService.validCustomerType()">
                          <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.CV}}</span>
                      </div>
                      <div class="order-summary-text t-a-r  ">
                          <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                              '1.2-2'}} USD</span>
                      </div>
                      <div class="order-summary-text t-a-r green-color" *ngIf="_calculateRetailOrderService.isSelected">
                          <span>(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}} Redeemed)</span>
                      </div>
                      <div class="order-summary-text t-a-r  " *ngIf="_calculateRetailOrderService.isSelected">
                          <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r  ">
                        <span *ngIf="!_calculateRetailOrderService.isSelected">${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                          '1.2-2'}} USD</span>
                        <span *ngIf="_calculateRetailOrderService.isSelected">${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                      </div>
                      <div class="order-summary-text t-a-r" >
                          <span id="totalsaving"> <b> ${{ _commonService.getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------------------------------- Subscription Box ---------------------------------------------->
    <div class="subscriptionBoxOutside" *ngIf="_enrollSession.data.RetailOrderSummary && _enrollSession.data.RetailOrderSummary?.SmartOrders && _enrollSession.data.RetailOrderSummary?.SmartOrders?.length > 0">
      <div class="smartOrderDateBoxCart " fxLayout="column" *ngIf="ultLifestyleFlow === 'false'">
        <div class="p-r-10 text-center smartDateText">
          <label>Your subscription is scheduled to begin on {{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate :
            '--/--/----'}}</label>
        </div>
      </div>
      <div class="smartOrderDateBoxCart " fxLayout="column" *ngIf="ultLifestyleFlow === 'true'">
        <div class="p-r-10 text-center smartDateText">
          <label>Your subscription is scheduled to begin on {{smartOrderDateForLifestyle ? smartOrderDateForLifestyle :
            '--/--/----'}}</label>
        </div>
      </div>
      <div class="subscription-box">
        <div class="text-center authorize" *ngIf="ultLifestyleFlow === 'false'">
          <label class="w-checkbox">
            <input type="checkbox" class="authorizecheck" [formControl]="AuthorizeCheckBox" value="isAuthorize" [checked]='isAuthorize' (click)="AuthorizeChecked()" required>
            <span class="checkbox-label w-form-label" [ngClass]="{'red-color':(isAuthorize === false && !AuthorizeCheckBox.valid && AuthorizeCheckBox.touched && AuthorizeCheckBox.errors['required'])}"><b>I authorize the {{processTypeDisplay}} monthly subscription to begin on {{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate :
              '--/--/----'}}.</b></span>
          </label>
          <div class="error-box" *ngIf="isAuthorize === false">
            <span class="help-block"
              *ngIf="!AuthorizeCheckBox.valid && AuthorizeCheckBox.touched">
              <span class="font-small" *ngIf="AuthorizeCheckBox.errors['required']">This field is required</span>
            </span>
          </div>
        </div>
        <div class="text-center authorize" *ngIf="ultLifestyleFlow === 'true'">
          <label class="w-checkbox">
            <input type="checkbox" class="authorizecheck" [formControl]="AuthorizeCheckBox" value="isAuthorize" [checked]='isAuthorize' (click)="AuthorizeChecked()" required>
            <span class="checkbox-label w-form-label" [ngClass]="{'red-color': (isAuthorize === false && !AuthorizeCheckBox.valid && AuthorizeCheckBox.touched && AuthorizeCheckBox.errors['required'])}"><b>I authorize the {{processTypeDisplayAutoOrder || processTypeDisplay}} monthly subscription to begin on {{smartOrderDateForLifestyle ? smartOrderDateForLifestyle :
              '--/--/----'}}.</b></span>
          </label>
          <div class="error-box" *ngIf="isAuthorize === false">
            <span class="help-block"
              *ngIf="!AuthorizeCheckBox.valid && AuthorizeCheckBox.touched">
              <span class="font-small" *ngIf="AuthorizeCheckBox.errors['required']">This field is required</span>
            </span>
          </div>
        </div>
        <div class="Item-confirmation-Details" *ngIf="siteType=='ultlifestyle'">
          <div class="w-100-div">
            <div class="w-100">
                <div class="order-summary-h1">Smart Order Subscription: Months 2-3</div>
            </div>
          </div>
          <div class="w-layout-grid-subscribe customer-info-grid subscribe-item-grid-ult"
            *ngFor="let item of _enrollSession.data.RetailOrderSummary?.SmartOrders">
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="item.type=='2Month'">
              <div class="confirmation-customer-info-p f-w-bold">
                {{item?.Description}}
              </div>
              <div class="confirmation-customer-info-p products" *ngIf="!_enrollSession.mobileViewMethod()">
                BV : {{item?.BV}}
              </div>
              <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p">${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
              </ng-container>
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="_enrollSession.mobileViewMethod() && item.type=='2Month'">
              <div class="confirmation-customer-info-p">
                BV : {{item?.BV}}
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="item.type=='2Month'">
              <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                <div class="confirmation-customer-info-p">
                  {{item?.Quantity}}
                </div>
              </ng-container>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod() && item.type=='2Month'">
              <div class="confirmation-customer-info-p">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p">${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                </ng-container>
                <ng-container *ngIf="((item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                    class="confirmation-customer-info-p">
                    {{item?.Quantity}}</div>
                </ng-container>
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="item.type=='2Month'">
              <div class="confirmation-customer-info-p f-w-bold">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p f-w-bold">${{item?.Price * item?.Quantity |number : '1.2-2'}}</div>
              </ng-container>
              </div>
              <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
                <ng-container *ngIf="((item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                    class="confirmation-customer-info-p">
                    {{item?.Quantity}}</div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="order-summary-divider-confirmation-page" *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrders.length > 1"></div>
          <div class="w-100-div">
            <div class="w-100">
                <div class="order-summary-h1">Smart Order Subscription: Month 4+</div>
            </div>
          </div>
          <div class="w-layout-grid-subscribe customer-info-grid subscribe-item-grid-ult"
            *ngFor="let item of _enrollSession.data.RetailOrderSummary?.SmartOrders">
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="item.type=='4Month'">
              <div class="confirmation-customer-info-p f-w-bold">
                {{item?.Description}}
              </div>
              <div class="confirmation-customer-info-p products" *ngIf="!_enrollSession.mobileViewMethod()">
                BV : {{item?.BV}}
              </div>
              <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p">${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
              </ng-container>
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="_enrollSession.mobileViewMethod() && item.type=='4Month'">
              <div class="confirmation-customer-info-p">
                BV : {{item?.BV}}
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="item.type=='4Month'">
              <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                <div class="confirmation-customer-info-p">
                  {{item?.Quantity}}
                </div>
              </ng-container>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod() && item.type=='4Month'">
              <div class="confirmation-customer-info-p">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p">${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                </ng-container>
                <ng-container *ngIf="((item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                    class="confirmation-customer-info-p">
                    {{item?.Quantity}}</div>
                </ng-container>
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="item.type=='4Month'">
              <div class="confirmation-customer-info-p f-w-bold">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p f-w-bold">${{item?.Price * item?.Quantity |number : '1.2-2'}}</div>
              </ng-container>
              </div>
              <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
               <ng-container *ngIf="((item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                    class="confirmation-customer-info-p">
                    {{item?.Quantity}}</div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="Item-confirmation-Details" *ngIf="siteType!='ultlifestyle'">
          <div class="w-layout-grid-subscribe customer-info-grid border-subscribe-items subscribe-item-grid"
            *ngFor="let item of _enrollSession.data.RetailOrderSummary?.SmartOrders">
            <div class="confirmation-customer-info-text-div itemDetails">
              <div class="confirmation-customer-info-p f-w-bold">
                {{item?.Description}}
              </div>
              <div class="confirmation-customer-info-p products" *ngIf="!_enrollSession.mobileViewMethod()">
                BV : {{item?.BV}}
              </div>
              <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p">${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
              </ng-container>
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="_enrollSession.mobileViewMethod()">
              <div class="confirmation-customer-info-p">
                BV : {{item?.BV}}
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails">
              <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                <div class="confirmation-customer-info-p">
                  <button class="cursor-pointer button-minus-plus" [disabled]="item.ParentId ? true : false" (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,3)">-</button>&nbsp;{{item?.Quantity}}&nbsp;<button class="cursor-pointer button-minus-plus" [disabled]="item.ParentId ? true : false" (click)="_sharedMethodService.ContinueRetailOrder(item,1,3);">+</button>
                </div>
              </ng-container>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod()">
              <div class="confirmation-customer-info-p">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p">${{item?.Price |number : '1.2-2'}} x {{item?.Quantity}}</div>
                </ng-container>
                <ng-container *ngIf="((item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                    class="confirmation-customer-info-p">
                    {{item?.Quantity}}</div>
                </ng-container>
              </div>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails" *ngIf="!_enrollSession.mobileViewMethod()">
              <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                <div class="confirmation-customer-info-p">
                  <span class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'smartOrder')">Remove</span>
                </div>
              </ng-container>
            </div>
            <div class="confirmation-customer-info-text-div itemDetails">
              <div class="confirmation-customer-info-p f-w-bold">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                      class="confirmation-customer-info-p f-w-bold">${{item?.Price * item?.Quantity |number : '1.2-2'}}</div>
              </ng-container>
              </div>
              <div class="confirmation-customer-info-p" *ngIf="_enrollSession.mobileViewMethod()">
                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId && item.ItemCode!='7800GC'">
                  <span class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'smartOrder')">Remove</span>
                </ng-container>
                <ng-container *ngIf="((item.ParentId != item.ItemCode && item.ParentId))">
                  <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                    class="confirmation-customer-info-p">
                    {{item?.Quantity}}</div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="order-summary-divider-confirmation-page" *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrders.length > 1"></div>
        </div>
        <div class="subscription-confirmation-Details p-0 m-b-20" *ngIf="_enrollSession.data.RetailOrderSummary && _enrollSession.data.RetailOrderSummary?.SmartOrders && _enrollSession.data.RetailOrderSummary?.SmartOrders.length > 0 && siteType!='ultlifestyle'">
          <div *ngIf="_enrollSession.data.RetailOrderSummary && _enrollSession.data.RetailOrderSummary?.SmartOrders && _enrollSession.data.RetailOrderSummary?.SmartOrders.length > 0">
            <div class="order-summary-totals-div-block"
                *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                <div class="order-summary-totals-text-div-block">
                    <div class="order-summary-text  ">SubTotal</div>
                    <div class="order-summary-text  ">Shipping</div>
                    <div class="order-summary-text green-color" *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrderDetails?.SubTotal > 250">Shipping Discount (orders over $250)</div>
                    <div class="order-summary-text  ">Re-order</div>
                    <div class="order-summary-text  ">Estimated Tax</div>
                    <div class="order-summary-text  " *ngIf="_sharedMethodService.validCustomerType()">QV</div>
                    <div class="order-summary-text  " *ngIf="_sharedMethodService.validCustomerType()">BV</div>
                    <div class="order-summary-text  ">Order Total</div>
                    <div class="order-summary-text  ">Amount Charged</div>
                    <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                </div>
                <div class="order-summary-totals-text-div-block">
                    <div class="order-summary-text t-a-r  ">
                        <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.SubTotal |number
                            :
                            '1.2-2'}}</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                        <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.ShippingTotal
                            |number : '1.2-2'}}</span>
                    </div>
                    <div class="order-summary-text t-a-r green-color" *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrderDetails?.SubTotal > 250">
                      <span>You saved $10 on shipping</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                      <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.SubTotal
                          |number : '1.2-2'}}</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                        <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.Tax|number :
                            '1.2-2'}}</span>
                    </div>
                    <div class="order-summary-text t-a-r  " *ngIf="_sharedMethodService.validCustomerType()">
                        <span>{{_enrollSession.data?.RetailOrderSummary.SmartOrderDetails?.BV}} </span>
                    </div>
                    <div class="order-summary-text t-a-r  " *ngIf="_sharedMethodService.validCustomerType()">
                        <span>{{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.CV}}</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                        <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.Total|number :
                            '1.2-2'}} USD</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                      <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.Total|number :
                        '1.2-2'}} USD</span>
                    </div>
                    <div class="order-summary-text t-a-r" >
                        <span id="totalsaving"> <b> ${{ _commonService.getDiscountTotalForSmartOrder() | number : '1.2-2'}} USD</b></span>
                    </div>
                </div>
            </div>

            <div class="order-summary-totals-div-block"
                *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                <div class="order-summary-totals-text-div-block">
                    <div class="order-summary-text  ">SubTotal</div>
                    <div class="order-summary-text  ">Shipping</div>
                    <div class="order-summary-text green-color" *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrderDetails?.SubTotal > 250">Shipping Discount (orders over $250)</div>
                    <div class="order-summary-text  ">Re-order</div>
                    <div class="order-summary-text  ">Estimated Tax</div>
                    <div class="order-summary-text  " *ngIf="_sharedMethodService.validCustomerType()">QV</div>
                    <div class="order-summary-text  " *ngIf="_sharedMethodService.validCustomerType()">BV</div>
                    <div class="order-summary-text  ">Order Total</div>
                    <div class="order-summary-text  ">Amount Charged</div>
                    <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                </div>
                <div class="order-summary-totals-text-div-block">
                    <div class="order-summary-text t-a-r  ">
                        <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.SubTotal |number
                            :
                            '1.2-2'}}</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                        <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.ShippingTotal
                            |number : '1.2-2'}}</span>
                    </div>
                    <div class="order-summary-text t-a-r green-color" *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrderDetails?.SubTotal > 250">
                      <span>You saved $10 on shipping</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                      <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.SubTotal
                          |number : '1.2-2'}}</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                        <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.Tax|number :
                            '1.2-2'}}</span>
                    </div>
                    <div class="order-summary-text t-a-r  " *ngIf="_sharedMethodService.validCustomerType()">
                        <span>{{_enrollSession.data?.RetailOrderSummary.SmartOrderDetails?.BV}} </span>
                    </div>
                    <div class="order-summary-text t-a-r  " *ngIf="_sharedMethodService.validCustomerType()">
                        <span>{{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.CV}}</span>
                    </div>
                    <div class="order-summary-text t-a-r  ">
                        <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.Total|number :
                            '1.2-2'}} USD</span>
                    </div>
                  <div class="order-summary-text t-a-r  ">
                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails.Total|number :
                      '1.2-2'}} USD</span>
                  </div>
                    <div class="order-summary-text t-a-r" >
                        <span id="totalsaving"> <b> ${{ _commonService.getDiscountTotalForSmartOrder() | number : '1.2-2'}} USD</b></span>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!------------------------------------- Complete Buttton ---------------------------------------------->
    <div>
      <a data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
          class="complete-button-subscription" (click)="onClickChange()" *ngIf="_enrollSession.data.RetailOrderSummary && _enrollSession.data.RetailOrderSummary?.SmartOrders && _enrollSession.data.RetailOrderSummary?.SmartOrders.length > 0">
          Complete Today's Order & Authorize Subscription
      </a>
      <a data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
          class="complete-button" (click)="onClickChange()" *ngIf="_enrollSession.data.RetailOrderSummary && !_enrollSession.data.RetailOrderSummary?.SmartOrders || (_enrollSession.data.RetailOrderSummary?.SmartOrders && _enrollSession.data.RetailOrderSummary?.SmartOrders?.length === 0)">
          Complete Today's Order
      </a><br *ngIf="_enrollSession.mobileViewMethod()">
    </div>
  </div>
</div>