import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'underscore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth.service';
import { EnrollSessionService } from '../enrollSession.service';
import { RestService } from '../rest.service';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { SharedMethodService } from '../sharedMethod.service';
import { Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatDrawerMode } from '@angular/material/sidenav';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { API_URL, IMAGE_URL, objectToParams } from 'app/constants';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CommonService } from '../common.service';
import { MatOption } from '@angular/material/core/option';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      state('out', style({ transform: 'translateX(-100%)' })),
      transition('in => out', animate('300ms ease-out')),
      transition('out => in', animate('300ms ease-in')),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;
  @ViewChild('slickModalBanner', { static: false }) slickModalBanner: SlickCarouselComponent;
  @ViewChild('option', { static: false }) option: MatOption;
  public slideConfig = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 1,
    "adaptiveHeight": true,
    "autoplay": true,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false
  };
  backofficeUrl: any;
  productList: any[] = [];
  EnhanceTranformation: any[] = [];
  allProductList: any;
  customertype: any;
  headerMenubar: boolean = false;
  searchedValue: any;
  userData: any;
  loginContent: boolean = false;
  navSupportContent: boolean = true;
  navInputSearch: boolean = false;
  screenWidth: any;
  mobileView: any;
  mediumScreen: any;
  retailOrderSummary: any;
  InitialOrderQuantity: any;
  showCart: boolean = true;
  isshowmenu: boolean = false;
  isshowreward: boolean = false;
  isshowAccount:boolean = false;
  siteType: any;
  hideListForUltlifestyle: boolean = true;
  SubscriptionProcessType: any;
  DropdownMenu: any[] = [];
  routPath: any = '';
  SmartOrderDate: any;
  public collection:any;
  LoggedInUser:any = JSON.parse(localStorage.getItem("LoggedInUserData")) || {}
  mode = new FormControl('over' as MatDrawerMode);
  public SidemenuBar: boolean = false;
  public SideAccountBar: boolean = false;
  orderHistoryUrl:any;
  subscriptionURL: any;
  settingsURL:any;
  BPBackOfficeUrl:any;
  learnMoreRewardPoints:any;
  sharelinkURL:any;
  bannerstext: any = [];
  webalias: string;
  ultLifestyleFlow:any;
  bgColor:any;
  siteFlow:any;
  ULTBrainProducts:any = [];
  private _unsubscribeAll: Subject<any>;
  @Input() customerInfoForm: any;
  @Input() shippingForm: any;
  @Input() shippingMethodForm: any;
  @Input() referredForm: any;
  @Input() billingAddressForm: any;
  @Input() paymentTypeForm: any;
  @Input() accountInfoForm: any;
    constructor(
    public _matDialog: MatDialog,
    public _auth: AuthService,
    public cookieService: CookieService,
    public _enrollSession: EnrollSessionService,
    public _router: Router,
    public _restService: RestService,
    public _sharedMethodService: SharedMethodService,
    private _activateRoute: ActivatedRoute,
    private _toastr: ToastrService,
    public _commonService: CommonService
  ) {
    this._unsubscribeAll = new Subject();
    this.siteType = window.location.pathname;
    this.sharelinkURL = sessionStorage.getItem('FromShareLinkCart');
    this.ultLifestyleFlow = sessionStorage.getItem("onUltLifestyleFlow");
    this.siteFlow = sessionStorage.getItem('siteType');
    sessionStorage.setItem("collection", 'true');
    if (this.siteType) {
      if (this.siteType == '/Ultlifestyle' || this.siteType == '/OrderReview' || this.siteType == '/Add-ons' || (this.siteType == '/Shipping' && this.siteFlow == 'ultlifestyle') || (this.siteType == '/Checkout' && this.siteFlow == 'ultlifestyle')) {
        this.hideListForUltlifestyle = false;
      }
      else {
        this.hideListForUltlifestyle = true;
      }
    }
    if (this._router.url == '/homepage') {
      this.headerMenubar = true;
      this.showCart = true;
      this.hideListForUltlifestyle = true;
    }
    else if (this._router.url.includes('/Ultlifestyle')) {
      this.showCart = false;
    }
    else if (this._router.url == '/Ultpack') {
      this.showCart = false;
    }
    else {
      this.showCart = true;
    }
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth
    if(this.screenWidth < 550){
      this.mobileView = true;
    }
    else{
      this.mobileView = false;
    }
    var url = new URL(window.location.href);
    this.webalias = this._activateRoute.snapshot.queryParamMap?.get('giftcard') || url?.searchParams.get("giftcard") || sessionStorage.getItem('giftCardName');
    this.getAllProduct(472, 22);
    this.userData = JSON.parse(this._auth.getUserData());
    this.InitialOrderQuantity = this._sharedMethodService;
    let ProductCode = this._activateRoute.snapshot.paramMap.get('id');
    if((this.siteType.includes("Subscribe") && this.ultLifestyleFlow === 'false') || ( this.siteType == "/Subscribe/"+ProductCode)){
      this.routPath = 'Shipping';
    }
    else if(this._auth.isLoggedIn() && localStorage.getItem("isExistingAutoship") == "true" && (this.siteType.includes("AllProducts") || this.siteType.includes("Collection") ||  this.siteType.includes("IRT") || this.siteType.includes("cantepack") || (this.siteFlow !== 'ultlifestyle' && this.siteType.includes("homepage")))){
      this.routPath = 'Shipping';
    }
    else if(localStorage.getItem("isExistingAutoship") != "true" && (this.siteType.includes("AllProducts") || this.siteType.includes("Collection")  || this.siteType.includes("IRT") || this.siteType.includes("cantepack") || (this.siteFlow !== 'ultlifestyle' && this.siteType.includes("homepage")))){
      this.routPath = 'Subscribe';
    }
    else if(this.siteType.includes("Shipping")){
      this.routPath = 'Checkout';
    }
    else if(this.siteType.includes("Checkout")){
      this.routPath = 'Review-Page';
    }
    else if(this.siteType.includes("OrderReview") || (this.siteFlow === 'ultlifestyle' && this.siteType.includes("homepage"))){
      this.routPath = 'Add-ons';
    }
    else if(this.siteType.includes("Add-ons")){
      this.routPath = 'Shipping';
    }
    else if((this.siteType.includes("Subscribe") && this.ultLifestyleFlow === 'true') || ( this.siteType == "/Subscribe/"+ProductCode)){
      this._router.navigate(['/Add-ons']);
    }
    this.collection = [628, 629, 630, 631, 632, 634, 635, 636, 637];
    if (sessionStorage.getItem("this.DropdownMenu") !== null && sessionStorage.getItem("this.DropdownMenu") !== undefined) {
      this.DropdownMenu = JSON.parse(sessionStorage.getItem("this.DropdownMenu"));
    }
    else {
      this.getCollectionCategories(); 
    }
    if(this.screenWidth > 550){
      this._enrollSession.SummaryCartMobOnly = this.mobileView;
    }
    this._sharedMethodService.headerSearchValue = '';
    localStorage.setItem('mobileView', this.mobileView)
    sessionStorage.setItem('MobileView', JSON.stringify(this.mobileView));
    sessionStorage.getItem('balanceFieldPoint') !== null && sessionStorage.getItem('balanceFieldPoint') !== undefined ? (this._sharedMethodService.balanceField = sessionStorage.getItem('balanceFieldPoint')) : 0
      if (!localStorage.getItem("authtoken") || localStorage.getItem("authtoken") == "null" || localStorage.getItem("authtoken") == null) {
          this._auth.clearLoggedInData();
      }
        this.GetIshopBannerContent();
  }
  GetIshopBannerContent() {
    const self = this;
    self._restService.GetIshopBannerContent().subscribe(
        (res: any) => {
            if (res?.Status === "Success") {
                let data = res.Data.BannerContent;
                this.bgColor = data?.backgroundColor?.split(";");
                this.bannerstext = [
                    { text: data.bannerText1 || 'Celebrating 20 Years of Direct Scales of Pure + Premium Superfoods', url: data.bannerUrl1 || '',bgcolor: this.bgColor?.length > 0 ? (this.bgColor[0]): '#f99f27' },
                    { text: data.bannerText2 || 'We are experiencing delivery delays due to severe weather conditions.', url: data.bannerUrl2 || '',bgcolor: this.bgColor?.length > 1 ? this.bgColor[1]: '#C688B9' },
                    { text: data.bannerText3 || 'Try our New Mushroom Products today!', url: data.bannerUrl3 || 'https://ishoppurium.com/Collection5',bgcolor: this.bgColor?.length > 2 ? this.bgColor[2]: '#566A26 ' }
                ];
                localStorage.setItem("bannerColorTextURL",JSON.stringify(this.bannerstext));
                $('#header-banner a').css('color', this.bannerstext[1].bgcolor);
                $('#header-banner mat-icon').css('color', this.bannerstext[0].bgcolor);
                $('.text-underline-banner').css('text-decoration-color', this.bannerstext[1].bgcolor);
                this.getColor();
            }
            else{
                this._router.navigate(['/homepage']);
            }
        },
        error => {
            console.log(error , 'in error');
            this._router.navigate(['/homepage']);
        });
}
getColor(){
  $('.sliding').on('beforeChange', function(event,slick,currentSlide, nextSlide){
    changeColors(nextSlide);
});
}

  giftcardFunctionality() {
    if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
      const request = '?WebAlias=' + this.webalias;
      this._restService.GetCustomerSite(request).subscribe((result: any) => {
        if (result.Data != null) {
          this._enrollSession.data.webAlias = this.webalias;
          sessionStorage.setItem('giftCardName', this._enrollSession.data.webAlias);
          this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
          this.getImage(result.Data.customerIDField);
        } else {
          this._toastr.error('Invalid WebAlias.', 'Error');
        }
      }, error => {
        this._toastr.error('Somthing is wrong Please try again', 'Error');
      });
    }
  }
  getImage(id) {
    this._enrollSession.customerImage =  API_URL + "/NonProfitEnrollment/GetCustomerImageData?customerId=" + id || IMAGE_URL+"/images/avatars/profile_icon.png";
  }
  nextBanner() {
    this.slickModalBanner.slickNext();
  }
  prevBanner() {
    this.slickModalBanner.slickPrev();
  }

  redirectProduct(item) {
    this._router.navigate(['AllProducts'], { queryParams: { giftcard: this._enrollSession.data.webAlias } }).then(() => {
      this.productInfo(item);
    })
  }
  
  productInfo(item) {
    sessionStorage.setItem('productDisplay', JSON.stringify(item))
    this._router.navigate(['/AllProducts', item.ItemCode])
  }

  getCollectionCategories(){
    const request = {
      "WebCategoryList" : this.collection
    };
    let customDropdown = {
      "WebCategoryDescription":"BP Products",
      "WebCategoryID" : 544,
    };
    return this._restService.getCollectionCategories(request).subscribe((res: any) => {
        res.push(customDropdown);
        this.DropdownMenu.push(res);
        this.DropdownMenu = res;
        sessionStorage.setItem("this.DropdownMenu", JSON.stringify(this.DropdownMenu));
      });
  }
  ClickToWebCategory(WebCategoryID){
      let URL = '';
      switch (WebCategoryID) {
          case 628:
            URL = '/Collection1'
            break;
          case 629:
            URL = '/Collection2'
            break;
          case 630:
            URL = '/Collection3'
            break;
          case 631:
            URL = '/Collection4'
            break;
          case 632:
            URL = '/Collection5'
            break;
          case 634:
            URL = '/Collection7'
            break;
          case 635:
            URL = '/Collection8'
            break;
          case 636:
            URL = '/Collection9'
            break;
          case 544:
            URL = '/Collection10'
            break;
          case 654:
            URL = '/IRT'
            break;
        }
        this._router.navigate([URL]);
  }

  disableLogin():boolean
  {
    return this._router.url != '/Shipping' && this._router.url != '/Checkout' && this._router.url != '/Subscribe' && this._router.url != '/Add-ons'
  }

  login() {
    const dialogRef = this._matDialog.open(UserDialogComponent, {
      width: '500px',
      maxWidth: '500px',
      height: '356px',
      panelClass: '',
      disableClose: true,
      data: {
        header: true,
      },
      autoFocus:false
    });
    this._sharedMethodService.isOpenDialog = true;
    dialogRef.afterClosed().subscribe(result => {

      this._sharedMethodService.isOpenDialog = false;
      if (result) {
        this.userData = JSON.parse(this._auth.getUserData());
        this.getUserData(this.userData);
        this._restService.GetAutoOrders(this.userData.CustomerID).subscribe((res: any) => {
          if (res.AutoOrderList.length > 0 && this.siteType != 'ultlifestyle') {
              localStorage.setItem("isExistingAutoship", "true");
            this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || {}
            let FromShareLinkCart = sessionStorage.getItem("FromShareLinkCart");
            this._enrollSession.data.RetailOrderSummary.InitialOrders =  FromShareLinkCart == "true" ? this._enrollSession.data.RetailOrderSummary.InitialOrders  : [];
            let CustomAutoOrderList = [];
            res.AutoOrderList.forEach(item => {
              if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                CustomAutoOrderList.push(item);
              }
            });
            var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
            CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
              return (item.AutoOrderID == minAutoOrderId);
            });
              localStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
              localStorage.setItem("AutoOrderDetails", JSON.stringify(CustomAutoOrderList[0]));
            this.SubscriptionProcessType = CustomAutoOrderList[0].ProcessType == 0 ? "AutoOrderProcessTy1" : "AutoOrderProcessTy2" || JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
            localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
            this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
          }
        }, error => {
          console.log("In error")
        })
      }
    });
  }

  getUserData(customerDetail) {
    if (this._auth.isLoggedIn()) {
      this.customertype = customerDetail.CustomerType || 22;
      this.productList = [];
      this.getAllProduct(472, this.customertype);
    }
  }
  getAllProduct(webCategoryId, customertype) {
    var listData;
    if (webCategoryId === 472) {
      this._sharedMethodService.AllProducts472.pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged()).subscribe(res => {
        listData = res;
        this.productList = [];
        if (this.productList?.length == 0) {
          this.productList = listData;
        }
        sessionStorage.setItem("AllProducts472", JSON.stringify(listData));
      })
    } else {
      const request = {
        'EnrollmentType': '',
        'CustomerType': this._auth.isLoggedIn() ? customertype : 22,
        'WebCategoryId': webCategoryId,
        'isUpgradeCustomer': false,
        'IsSubscriptionOrder': false,
        'PriceType': ''
      };
      this._restService.getProducts(request).subscribe((res: any) => {
        listData = res.Items;
      });
    }
    if (listData != null) {
      var uniqueproductListData = listData.filter((tag, index, array) => array.findIndex(t => t.ItemCode == tag.ItemCode && t.Description == tag.Description && t.Price == tag.Price) == index);
      this.allProductList = uniqueproductListData;
      this.allProductList.forEach((item) => {
        if (item.CategoryID === 482) {
          this.EnhanceTranformation.push(item);
        }
        if (item.CategoryID === 495) {
          this.EnhanceTranformation.push(item);
        }
        if (item.CategoryID === 472) {
          const isUnique = !this.productList.some(existingItem => existingItem.CategoryID === item.CategoryID);

          if (isUnique) {
            this.productList.push(item);
          }
        }
      });
    }
  }
  
  getQuanity() {
    var quantity = 0;
    var uniqueItemCodes = new Set();
  
    _.each(this._enrollSession.data.RetailOrderSummary?.Enrollpack, (item) => {
      if (!item.ParentId || item.ParentId === item.ItemCode) {
        uniqueItemCodes.add(item.ItemCode);
        quantity += (item.Quantity ? Number(item.Quantity) : 0);
      }
    });
  
    _.each(this._enrollSession.data.RetailOrderSummary?.InitialOrders, (item) => {
      if (!item.ParentId || item.ParentId === item.ItemCode) {
        uniqueItemCodes.add(item.ItemCode);
        quantity += (item.Quantity ? Number(item.Quantity) : 0);
      }
    });

    return quantity;
  }
  

    logout() {
        this.cookieService.delete('access_token', '/');
        this.cookieService.delete('X-Auth', '/');
        this.cookieService.deleteAll('/');
        this._enrollSession.data.RetailOrderSummary = {};
        this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
        this._enrollSession.data.IsLoggedIn = false;
        this._enrollSession.data.LoggedInUserData = {};
        this._enrollSession.data.priceType = 0;
	sessionStorage.removeItem('this.DropdownMenu');
        this._auth.clearLoggedInData();
        sessionStorage.setItem('onUltLifestyleFlow', 'false');
        this._router.navigate(['/Home']).then(() => {
            if (window.localStorage && window.sessionStorage) {
                window.localStorage.removeItem('user');
                window.localStorage.clear();
                window.sessionStorage.clear();
            }
            window.location.reload();
        });
        localStorage.setItem("isExistingAutoship", "false");
    }

  redirectToBackoffice() {
    this.userData = JSON.parse(this._auth.getUserData());
    let customerId = this._enrollSession.submitApplicationData.CustomerID || this.userData.CustomerID;
    let windowref = window.open()
    this._restService.SilentLogin(customerId).subscribe((res: any) => {
      this.backofficeUrl = res;
      windowref.location = this.backofficeUrl, "_blank";
    }, error => {
      console.log("In error")
    })
  }

  ClicktoOrderHistory(){
    this.userData = JSON.parse(this._auth.getUserData());
    let customerId = this._enrollSession.submitApplicationData.CustomerID || this.userData.CustomerID;
    let windowref = window.open();
    this._restService.SilentLogin(customerId).subscribe((res: any) => {
      this.orderHistoryUrl = res;
      windowref.location = this.orderHistoryUrl, "_blank";
    }, error => {
      console.log("In error")
    });
  }

  ClicktoSubscription() {
    let ssoToken = sessionStorage.getItem('SSOToken');
    if (ssoToken && ssoToken != undefined && ssoToken != null) {
      this.manageSubscriptionAutoship(ssoToken);
    } else {
      this.getSSOToken();
    }
  }
  isUpgradableCustomer(){
    let customerType = [20,22];
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let customerFound = customerType.find((element) => element === this.LoggedInUser.CustomerType);
    return customerFound ? true : false;
  }
  ClicktoUpgrade() {
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let currentUrl = window.location.href;
    let url = currentUrl.includes('ishoppurium') ? 'https://puriumenrollment.com/TodaysOrder?customerId=' : 'http://phpenrollnew.wsicloud.net/TodaysOrder?customerId='; 
    window.open(url+ this.LoggedInUser.CustomerID, '_blank');
  }
  getSSOToken()
  {
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let ssoRequest = 
    objectToParams({
      client_id: this.LoggedInUser.CustomerID,
      grant_type:'password',
      userName: this.LoggedInUser.Email
    });
    this._restService.Token(ssoRequest).subscribe((res: any) => {
      if (res.SSOToken) {
        this.manageSubscriptionAutoship(res.SSOToken);
      }else{
        alert("Your session has been expired. Please logout and login again.");
      }
    });
  }

  manageSubscriptionAutoship(ssoToken) {
    let windowref = window.open();
    this.subscriptionURL = 'https://monthlysubsription.puriumenrollment.com/login?SSOToken=' + ssoToken;
    windowref.location = this.subscriptionURL, "_blank";
  }

  ClicktoBPBackOffice(){
    let url:any = 'https://backoffice.mypurium.com/orders#';
    let urlBPOffice:any = 'https://backoffice.mypurium.com/';
    let windowref = window.open();
    this.userData = JSON.parse(this._auth.getUserData());
    this._restService.SilentLogin(this.userData.CustomerID).subscribe((res: any) => {
      this.BPBackOfficeUrl = res;
      windowref.location = this.BPBackOfficeUrl, "_blank";
      windowref.location = url;
      windowref.location = urlBPOffice;
    }, error => {
      console.log("In error")
    });
  }

  ClicktoSettings(){
    let url:any = 'https://backoffice.mypurium.com/account/settings';
    let windowref = window.open();
    this.userData = JSON.parse(this._auth.getUserData());
    this._restService.SilentLogin(this.userData.CustomerID).subscribe((res: any) => {
      this.settingsURL = res;
      windowref.location = this.settingsURL, "_blank";
      setTimeout(() => {
        windowref.location = url;
      }, 1000);
    }, error => {
      console.log("In error")
    });
  }

  ClickToLearnMoreRewardPoints(){
    let url:any = 'https://backoffice.mypurium.com/puriumrewards';
    let windowref = window.open();
    this.userData = JSON.parse(this._auth.getUserData());
    this._restService.SilentLogin(this.userData.CustomerID).subscribe((res: any) => {
      this.learnMoreRewardPoints = res;
      windowref.location = this.learnMoreRewardPoints, "_blank";
      setTimeout(() => {
        windowref.location = url;
      }, 1000);
    }, error => {
      console.log("In error")
    });
  }

  redirectToTodaysOrder() {
    sessionStorage.setItem("siteType", '');
    this._router.navigate(['/AllProducts'], { queryParams: { giftcard: this._enrollSession.data.webAlias } });
  }

  onSearchChange(event) {
    if (event?.target?.value == undefined || event?.target?.value == null) {
      let afterSplit = this?.searchedValue?.split('');
      let joinForSearch = afterSplit?.join('');
      this._sharedMethodService.headerSearchValue = joinForSearch || event?.target?.value;
    } else {
      this._sharedMethodService.headerSearchValue = event?.target?.value;
    }
  }

  redirectToProduct(input?) {

    if (input instanceof MatAutocompleteSelectedEvent) {
      this._sharedMethodService.headerSearchValue = input.option.value;
      this.searchedValue = input.option.value;
      input = input.option.value;
    }

    if (input != '') {
      document.getElementById("open_input").focus();
    }

    if (this._router.url != "/Subscribe" && _.isUndefined(this._sharedMethodService.headerSearchValue) == false && input != '') {
      this._sharedMethodService.headerSearchValue = input;
      this.selectProdcut(input);
      this.onSearchChange(undefined);
      this._router.navigate(['AllProducts'], { queryParams: { giftcard: this._enrollSession.data.webAlias } }).then(() => {
        this.selectProdcut(input);
        this.onSearchChange(undefined);
      })
    }
  }

  searchIconHandle() {
    this.searchedValue = ''
  }

  selectProdcut(item) {
    this._sharedMethodService.headerSearchValue = item;
  }

  getHeaderSearchedValue() {
    return this._sharedMethodService.headerSearchValue;
  }
  
  openMobileSummaryCart() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 550) {
      if(this.mobileView == true){
        this.mobileView = this.mobileView;
      }
      else{
        this.mobileView = !this.mobileView;
      }
    }
    else {
      this.mobileView = false;
    }

    localStorage.setItem('mobileView', this.mobileView)
    this._enrollSession.SummaryCartMobOnly = this.mobileView;
    this.loginContent = false;
    this.navSupportContent = false;
    this.navInputSearch = false;
    sessionStorage.setItem('SummaryCartMobOnly', JSON.stringify(this._enrollSession.SummaryCartMobOnly));
  }

  RedirectToUltLifestyle(){
      this._router.navigate(['/Ultlifestyle']);
  }
  RedirectToSALE() {
    this._router.navigate(['/Collection4']);
  }
  RedirectToHome(){
    this._router.navigate(['/homepage']);
  }

  navSearchContent() {
    this.navInputSearch = !this.navInputSearch;
    this.loginContent = false;
    this.navSupportContent = false;
    this.SideAccountBar = false;
    this.SidemenuBar = false;
  }

  navLoginContent() {
    this.loginContent = !this.loginContent;
    this.navSupportContent = false;
    this.navInputSearch = false;
    this.SideAccountBar = false;
    this.SidemenuBar = false;
  }

  navSupported() {
    this.navSupportContent = true;
    this.loginContent = false;
    this.navInputSearch = false;
    this.SideAccountBar = false;
    this.SidemenuBar = false;
  }
  toggleSidenav() {
    this.SidemenuBar = !this.SidemenuBar;
    this.loginContent = false;
    this.navInputSearch = false;
    this.navSupportContent = false;
    this.SideAccountBar = false;
  }
  toggleAccountSideBar(){
    this.SideAccountBar = !this.SideAccountBar;
    this.loginContent = false;
    this.navInputSearch = false;
    this.navSupportContent = false;
    this.SidemenuBar = false;
  }

  isBPCustomerExist(){
    let customerType = [4, 5, 6, 7, 8, 10, 17, 19, 21, 24,25];
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let customerFound = customerType.find((element) => element === this.LoggedInUser.CustomerType);
    return customerFound ? true : false;
  }
  ClickToPrint(){
    var printButton = document.getElementById("printButtonNone");
    printButton.style.visibility = 'hidden';
    window.print()
    printButton.style.visibility = 'visible';
  }
  OpenCartForShareLink(){
    if(this.sharelinkURL === 'true'){
      this._sharedMethodService.toggleSidenavforaddtocart();
    }
  }
  getEnrollerDetails()
  {
      let enrollerInfo =  JSON.parse(localStorage.getItem('EnrollerData'));
      let enrollername = enrollerInfo?.EnrollerFirstName + " " + enrollerInfo?.EnrollerLastName;
      return enrollername;
  }
  openCart(){
    if(window.innerWidth < 550){
      this._sharedMethodService.openMobileSummaryCart();
    }
    else{
      this._sharedMethodService.toggleSidenavforaddtocart();
    }
  }

  isDescriptionDisplayed(): boolean {
    return this.option && this.option._getHostElement().offsetHeight > 0;
  }
  ngAfterViewInit() {
    this.isDescriptionDisplayed();
  }
  
}
function changeColors(nextSlide: any) {
  var bannersCss = JSON.parse(localStorage.getItem('bannerColorTextURL'));
  if(nextSlide === 0){
    $('#header-banner a').css('color', bannersCss[0].bgcolor);
    $('#header-banner mat-icon').css('color', bannersCss[0].bgcolor);
    $('#header-banner .text-underline-banner').css('text-decoration-color', bannersCss[0].bgcolor);
  }
  if(nextSlide === 1){
    $('#header-banner a').css('color', bannersCss[1].bgcolor);
    $('#header-banner mat-icon').css('color', bannersCss[1].bgcolor);
    $('#header-banner .text-underline-banner').css('text-decoration-color', bannersCss[1].bgcolor);
  }
  if(nextSlide === 2){
    $('#header-banner a').css('color', bannersCss[2].bgcolor);
    $('#header-banner mat-icon').css('color', bannersCss[2].bgcolor);
    $('#header-banner .text-underline-banner').css('text-decoration-color', bannersCss[2].bgcolor);
  }
  
}

