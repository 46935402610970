import { Component, OnInit } from '@angular/core';
import { IMAGE_URL } from 'app/constants';

@Component({
  selector: 'app-returnpolicy',
  templateUrl: './returnpolicy.component.html',
  styleUrls: ['./returnpolicy.component.scss']
})
export class ReturnpolicyComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;

  constructor() { }

  ngOnInit(): void {
  }

}
