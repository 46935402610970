import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { RestService } from 'app/shared/rest.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { UserDialogComponent } from 'app/shared/user-dialog/user-dialog.component';
import { AuthService } from 'app/shared/auth.service';
import { LifestyleSubscriptionComponent } from 'app/shared/LifestyleSubscription/LifestyleSubscription.component';
import { Subject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
import { CommonService } from 'app/shared/common.service';
import { IMAGE_URL } from 'app/constants';

@Component({
    selector: 'app-todays-order',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Todays-order.component.html',
    styleUrls: ['./Todays-order.component.scss']
})
export class TodaysOrderComponent implements OnInit {
    IMAGE_URL = IMAGE_URL;
    productList: any[] = [];
    routPath: any = '';
    EnhanceTranformation: any[] = [];
    onSearchEnhance: any;
    panelOpenState: boolean = true;
    siteType: any;
    location: Location;
    url: any;
    webalias: any;
    customerDetail: any;
    customertype: any;
    allProductList: any;
    smarOrderallProductList: any;
    FirstItem: any;
    filterItems: any = [];
    filterItemsBuild: any = [];
    itemsmemberkitBuilder: any;
    itemsmemberkit: any;
    IsUltPerformance: any;
    SubscriptionProcessType: any;
    categoryList: any[] = [
        { name: 'Gluten Free', selected: false },
        { name: 'Kosher', selected: false },
        { name: 'Organic', selected: false },
        { name: 'Vegan', selected: false }
    ];
    sortList: any = ['A - Z', 'Z - A', 'Price - High to Low', 'Price - Low to High'];
    filterCategory: string = "";
    SortCategory: string = "";
    dropdownOpen = false;
    selectedCategories: string[] = [];
    originalproducts: any[] = [];
    selectedDisplayName: any = 'Filter by Dietary Preference';
    selectedSortOption: string = 'A - Z'; 
    emergencyProduct: any[] = [];
    public AllProductsList;
    mobileView:any;
    sharelinkURL:any;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _restService: RestService,
        public _enrollSession: EnrollSessionService,
        public _sharedMethodService: SharedMethodService,
        private _matDialog: MatDialog,
        private router: Router,
        private _location: Location,
        private _toastr: ToastrService,
        private _activateRoute: ActivatedRoute,
        private _auth: AuthService,
        private cdr: ChangeDetectorRef,
        public _calculateRetailOrderService: CalculateRetailOrderService,
        public _commonService: CommonService
    ) {
        this._unsubscribeAll = new Subject();
        this._enrollSession.data.customertype = this._enrollSession.data.customertype ? this._enrollSession.data.customertype : 22;
        this.location = this._location;
        this.sharelinkURL = sessionStorage.getItem('FromShareLinkCart');
        if(this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary?.Enrollpack !== undefined && this._enrollSession.data.RetailOrderSummary?.Enrollpack !== null && this._enrollSession.data.RetailOrderSummary.Enrollpack?.length > 0 && (this.sharelinkURL === undefined || this.sharelinkURL === null || this.sharelinkURL==="false")){
            this._enrollSession.data.RetailOrderSummary.InitialOrders = [];
            this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
            this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
            this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
        }
        this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || {};
        this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
        if (this._enrollSession.data && this._enrollSession.editTransformationPack != 'true' && Object.keys(this._enrollSession.data).length === 0 && !sessionStorage.getItem("collection")) {
            this._enrollSession.data.IsShowCart = false;
            this._enrollSession.data.page = this.location.path();
            this._enrollSession.data.TotalItemLength = 0;
            this._enrollSession.data.RetailEnroll = {};
            this._enrollSession.data.InitOrder = [];
            this._enrollSession.data.InitialOrdersSubTotal = 0;
            this._enrollSession.data.RetailOrderSummary = {};
            this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
            this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
            this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
            this._enrollSession.data.EligibleForFreeUpgrade = false;
            this._enrollSession.data.RetailEnroll.ShippingMethod = '';
            this._enrollSession.data.usePrimaryCard = null;
            this._enrollSession.data.primaryCreditCardField = {};
            this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
            this._enrollSession.data.IsUpGrade =
                this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
            if (this._enrollSession.data.IsUpGrade) {
                localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
            }
            this._enrollSession.data.Volume109 = 0;
            this._enrollSession.data.SmartOrderBVTotal = 0;
            this._enrollSession.data.InitialOrdersQVTotal = 0;
            this._enrollSession.data.AddOnOrdersQVTotal = 0;
        }
        this.url = window.location.href;
        this.webalias = this._activateRoute.snapshot.queryParamMap.get('giftcard') || sessionStorage.getItem('giftCardName');
        this.siteType = this._activateRoute.snapshot.queryParamMap.get('site');
        if (this.siteType) {
            sessionStorage.setItem("siteType", this.siteType);
            var snapshot = this._activateRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.site
            this.router.navigate([], { queryParams: params });
        } else if (sessionStorage.getItem("siteType") == 'ultlifestyle') {
            this.siteType = '';
            sessionStorage.setItem("siteType", '');
        } else {
            this.siteType = sessionStorage.getItem("siteType") || '';
        }
        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
        let screenWidth = window.innerWidth
        if (screenWidth < 550) {
            this.mobileView = true;
        }
        else {
            this.mobileView = false;
        }
        if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
            var snapshot = this._activateRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.giftcard
            this.router.navigate([], { queryParams: params });
            const request = '?WebAlias=' + this.webalias;
            this._restService.GetCustomerSite(request).subscribe((result: any) => {
                if (result.Data != null) {
                    this._enrollSession.data.webAlias = this.webalias;
                    this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
                } else {
                    this._toastr.error('Invalid WebAlias.', 'Error');
                }
            }, error => {
                this._toastr.error('Something is wrong Please try again', 'Error');
            });
        }

        this.siteType = sessionStorage.getItem("siteType");
        const customertype = JSON.parse(this._auth.getUserData()) || {}

        if (this.siteType == 'collagen') {
            this.getAllProduct(495, customertype.CustomerType || 22);
        } else {
            this.getAllProduct(472, customertype.CustomerType || 22);
        }
        if (this.siteType == 'ultpack') {
            this.getAllProduct('', 22);
            this.getAllProduct(538, 22);
            this.getAllProduct(463, 22);
        }
        if(this._auth.isLoggedIn() && localStorage.getItem("isExistingAutoship") == "true"){
            this.routPath = this.siteType == 'ultpack' ? "Add-ons" : "Shipping";
        }
        else{
            this.routPath = this.siteType == 'ultpack' ? "Add-ons" : "Subscribe";
        }
        this._calculateRetailOrderService.DiscountValue = '';
        this._calculateRetailOrderService.totalRemainingBalance = '';
        this._calculateRetailOrderService.isSelected = false;
        sessionStorage.setItem('onUltLifestyleFlow', 'false');
    }

    searchFilter(value, keys, term) {
        if (!term) {
            return value;
        }

        const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(escapedTerm, 'gi').test(item[key])));
    }

    getAllProduct(webCategoryId, customertype) {
        if (webCategoryId === 472 && sessionStorage.getItem("AllProducts472") != null  && sessionStorage.getItem("AllProducts472") != "null") {
            this.AllProductsList = JSON.parse(sessionStorage.getItem("AllProducts472"));
            this.getAllProductsList();
        }
        else {
            const request = {
                'EnrollmentType': '',
                'CustomerType': this._auth.isLoggedIn() ? customertype : 22,
                'WebCategoryId': webCategoryId,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': false,
                'PriceType': ''
            };
            this._restService.getProducts(request).subscribe((res: any) => {
                this.AllProductsList = res.Items;
                this.getAllProductsList();
            });
        }

    }
    getAllProductsList() {
        if (this.AllProductsList !== null && this.AllProductsList !== undefined) {
            var uniqueproductListData = this.AllProductsList.filter((tag, index, array) => array.findIndex(t => t.ItemCode == tag.ItemCode && t.Description == tag.Description && t.Price == tag.Price) == index);
            this.allProductList = uniqueproductListData;
            this.allProductList.forEach((item) => {
                this.productList.push(item);
            });
            this.originalproducts = this.productList;
            this.onSortChange('A - Z');
            if (this?._sharedMethodService?.headerSearchValue != '' && this?._sharedMethodService?.headerSearchValue != undefined && this?._sharedMethodService?.headerSearchValue != null) {
                this.emergencyProduct = this.searchFilter(this.productList, 'Description,ItemCode', this?._sharedMethodService?.headerSearchValue)
            }
            this.AllProductsList.forEach((item) => {
                if (item.CategoryID === 441 && item.ItemCode === "11200" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                    this.filterItemsBuild.push(item);
                }
                if (item.CategoryID === 420 && item.ItemCode === "11208" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                    this.filterItemsBuild.push(item);
                }
                if (item.CategoryID === 441 && item.ItemCode === "11210") {
                    this.filterItemsBuild.push(item);
                }
                if (item.CategoryID === 441 && item.ItemCode === "11235" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                    this.filterItemsBuild.push(item);
                }
                if (item.CategoryID === 441 && item.ItemCode === "11236" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                    this.filterItemsBuild.push(item);
                }
                if (item.CategoryID === 442 && item.ItemCode === "11205" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                    this.filterItems.push(item);
                }
                if (item.CategoryID === 442 && item.ItemCode === "11209" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                    this.filterItems.push(item);
                }
                if (item.CategoryID === 442 && item.ItemCode === "11220") {
                    this.filterItems.push(item);
                }
                if (item.ItemCode === "ULTLIFESTYLE") {
                    this.FirstItem = item; this.filterItemsBuild.push(item);
                }
                if (item.CategoryID === 540 && item.ItemCode === "11238" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                    this.filterItems.push(item);
                }
                if (item.CategoryID === 541 && item.ItemCode === "11239" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                    this.filterItems.push(item);
                }
            });
            if (this.filterItems && this.filterItems.length > 0) {
                this.itemsmemberkit = this.filterItems;
            }
            if (this.filterItemsBuild && this.filterItemsBuild.length > 0) {
                this.itemsmemberkitBuilder = this.filterItemsBuild.sort(
                    (a, b) => (a.ItemCode < b.ItemCode ? -1 : 1)
                );
            }
            if (this.siteType?.toLowerCase() == "ultperformance" && (!this.siteType || this.siteType == null || this.siteType?.toLowerCase() != "ultperformance")) {
                this.IsUltPerformance = true;
            }
            if (this.itemsmemberkitBuilder?.length == 6) {
                const index = [5, 0, 3, 1, 2, 4];
                this.itemsmemberkitBuilder = index.map(
                    (i) => this.itemsmemberkitBuilder[i]
                );
            }
        }
    }
    
    getUserData(customerDetail) {
        if (this._auth.isLoggedIn()) {
            this.customertype = customerDetail.CustomerType || 22;
            this.getAllProduct(472, this.customertype);
        }
    }

    productInfo(item) {
        sessionStorage.setItem('productDisplay', JSON.stringify(item))
        this.router.navigate(['/AllProducts', item.ItemCode])
    }

    onSearchChangeEnhance(event) {
        this.onSearchEnhance = event.target.value;
    }

    addToCart(item: any, quantity: any, type: any, sideNav: MatSidenav) {

        if (this._auth.isLoggedIn() || sessionStorage.getItem('newCustomer') == 'true') {

            if ((this.siteType == "ultpack" || this.siteType == "" || !this.siteType) && (item.ItemCode == '11200' || item.ItemCode == '11205' || item.ItemCode == '11235' || item.ItemCode == '11238' || item.ItemCode == '11208' || item.ItemCode == '11209' || item.ItemCode == '11210' || item.ItemCode == '11220' || item.ItemCode == '11270' || item.ItemCode == '11271')) {
                this.ultlifestylePoup(item, quantity, type);
            }
            else if (item.KitMembers && item.KitMembers.length > 0) {
                const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                    width: '600px',
                    panelClass: ['order-sub-item', 'sub-item-modal'],
                    data: {
                        item: item,
                        type: type

                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result.event == 'submit') {
                        this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
                    }
                });
            } else {
                this._sharedMethodService.ContinueRetailOrder(item, quantity, type)
                this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
            }
        }
        else {
            const dialogRef = this._matDialog.open(UserDialogComponent, {
                width: '500px',
                maxWidth: '500px',
                height: '356px',
                panelClass: '',
                disableClose: true,
                autoFocus: false
            });
            this._sharedMethodService.isOpenDialog = true;
            dialogRef.afterClosed().subscribe(result => {
                this._sharedMethodService.isOpenDialog = false;
                if (result) {
                    const customerDetail = JSON.parse(this._auth.getUserData()) || {}
                    
                    if (sessionStorage.getItem('newCustomer') != 'true') {
                        this._restService.GetAutoOrders(customerDetail.CustomerID).subscribe((res: any) => {
                            if (res.AutoOrderList.length > 0) {
                                localStorage.setItem("isExistingAutoship", "true");
                                let CustomAutoOrderList = [];
                                res.AutoOrderList.forEach(item => {
                                    if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                                        CustomAutoOrderList.push(item);
                                    }
                                });
                                
                                
                                var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
                                CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
                                    return (item.AutoOrderID == minAutoOrderId);
                                });
                                localStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
                                localStorage.setItem("AutoOrderDetails", JSON.stringify(CustomAutoOrderList[0]));
                                this.SubscriptionProcessType = CustomAutoOrderList[0].ProcessType == 0 ? "AutoOrderProcessTy1" : "AutoOrderProcessTy2" || JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
                                localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
                                this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                            }
                        }, error => {
                            console.log("In error")
                        })
                    }
                    if (this._auth.isLoggedIn()) {
                        this.productList = this.productList.filter(item => item.CategoryID !== 472);
                    }
                    this.getUserData(customerDetail);
                    if ((this.siteType == "ultpack" || this.siteType == "" || !this.siteType) && (item.ItemCode == '11200' || item.ItemCode == '11205' || item.ItemCode == '11235' || item.ItemCode == '11238' || item.ItemCode == '11208' || item.ItemCode == '11209' || item.ItemCode == '11210' || item.ItemCode == '11220' || item.ItemCode == '11270' || item.ItemCode == '11271')) {
                        this.ultlifestylePoup(item, quantity, type);
                    }
                    else if (item.KitMembers && item.KitMembers.length > 0) {
                        const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                            width: '600px',
                            panelClass: ['order-sub-item', 'sub-item-modal'],
                            data: {
                                item: item,
                                type: type

                            }
                        });

                        dialogRef.afterClosed().subscribe(result => {
                            if (result.event == 'submit') {
                                this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
                            }
                        });
                    }
                    else {
                        this._sharedMethodService.ContinueRetailOrder(item, quantity, type);
                        this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
                    }
                }
            });
        }
    }

    getHeaderSearchedValue() {
        return this._sharedMethodService.headerSearchValue;
    }

    navigateToUltlifestayle() {
        if (this._enrollSession.data.webAlias && this._enrollSession.data.webAlias != '' && this._enrollSession.data.webAlias != null && this._enrollSession.data.webAlias != "null") {
            var queryString = "?giftcard=" + this._enrollSession.data.webAlias;
            this.router.navigateByUrl('/Ultlifestyle' + queryString);
        }
        else {
            if (sessionStorage.getItem("SSOToken")) {
                var queryString = "?SSOToken=" + encodeURIComponent(sessionStorage.getItem("SSOToken"));
                this.router.navigateByUrl('/Ultlifestyle' + queryString);
            }
            else {
                this.router.navigateByUrl('/Ultlifestyle');
            }
        }
    }

    updateEnrollmentType(customertype): any {
        this._enrollSession.data.customertype = customertype;
        localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
        sessionStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
    }

    popupLoad(itemCode, qty, type): any {
        if ((this.siteType == "ultpack" || this.siteType == "" || !this.siteType) && (itemCode == '11200' || itemCode == '11205' || itemCode == '11235' || itemCode == '11238' || itemCode == '11208' || itemCode == '11209' || itemCode == '11210' || itemCode == '11220' || itemCode == '11270' || itemCode == '11271')) {
            this.ultlifestylePoups(itemCode);
        }
        var itemKit = _.find(this.itemsmemberkit,
            (item: any) => {
                if (itemCode == item.ItemCode) {
                    return item;
                }
            });

        if (itemKit.KitMembers && itemKit.KitMembers.length > 0) {
            const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                width: '600px',
                panelClass: ['order-sub-item', 'sub-item-modal'],
                data: {
                    item: itemKit
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                this._sharedMethodService.subOrderItem = false;
            });
        }
    }

    ultlifestylePoups(itemCode) {
        const dialogRef = this._matDialog.open(LifestyleSubscriptionComponent, {
            width: '500px',
            maxWidth: '500px',
            height: '356px',
            panelClass: '',
            disableClose: true,
            data: {
                item: itemCode,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    ultlifestylePoup(itemCode, quantity?: any, type?: any) {
        const dialogRef = this._matDialog.open(LifestyleSubscriptionComponent, {
            width: '500px',
            maxWidth: '500px',
            height: '356px',
            panelClass: '',
            disableClose: true,
            data: {
                item: itemCode.ItemCode,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (result == true) {
                if (itemCode.KitMembers && itemCode.KitMembers.length > 0) {
                    const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                        width: '600px',
                        panelClass: ['order-sub-item', 'sub-item-modal'],
                        data: {
                            item: itemCode,
                            type: type
                        }
                    });
                    dialogRef.afterClosed().subscribe(results => {
                    });
                } else {
                    this._sharedMethodService.ContinueRetailOrder(itemCode, quantity, type)
                }
            }
        });
    }

    onSortChange(sortOption) {
        this.productList = [];
        this.productList = this.originalproducts;
        switch (sortOption) {
            case 'A - Z':
                this.productList.sort((a, b) => a.Description.localeCompare(b.Description));
                break;
            case 'Z - A':
                this.productList.sort((a, b) => b.Description.localeCompare(a.Description));
                break;
            case 'Price - High to Low':
                this.productList.sort((a, b) => b.Price - a.Price);
                break;
            case 'Price - Low to High':
                this.productList.sort((a, b) => a.Price - b.Price);
                break;
            default:
                this.productList = [...this.originalproducts];
                break;
        }
        this.cdr.detectChanges();
    }
    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    updateFilteredProducts(): void {
        const selectedCategoryNames = this.categoryList.filter(category => category.selected).map(category => category.name);

        if (selectedCategoryNames.length != 0) {
            this.selectedDisplayName = selectedCategoryNames;
        } else {
            this.selectedDisplayName = 'Filter by Dietary Preference';
        }
        if (selectedCategoryNames.length === 0) {
            this.productList = [...this.originalproducts];
        } else {
            this.productList = this.originalproducts.filter(product => {
                const productCategories = product.Field2.split(':');
                return selectedCategoryNames.every(category => productCategories.includes(category.trim()));
            });
        }

        switch (this.selectedSortOption) {
            case 'A - Z':
                this.productList.sort((a, b) => a.Description.localeCompare(b.Description));
                break;
            case 'Z - A':
                this.productList.sort((a, b) => b.Description.localeCompare(a.Description));
                break;
            case 'Price - High to Low':
                this.productList.sort((a, b) => b.Price - a.Price);
                break;
            case 'Price - Low to High':
                this.productList.sort((a, b) => a.Price - b.Price);
                break;
            default:
                break;
        }
    }

    isSelected(category: string): boolean {
        return this.selectedCategories.includes(category);
    }

    closeDropdown() {
        this.dropdownOpen = false;
    }
    RedirectToUltLifestyle(){
        this.router.navigateByUrl('/Ultlifestyle');
    }
    isBPCustomerExist(){
        let customerType = [4, 5, 6, 7, 8, 10, 17, 19, 21, 24,25];
        var LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
        let customerFound = customerType.find((element) => element === LoggedInUser?.CustomerType);
        return customerFound ? true : false;
    }
}
