import { Component, OnInit } from '@angular/core';
import { IMAGE_URL } from 'app/constants';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-shippingpolicy',
  templateUrl: './shippingpolicy.component.html',
  styleUrls: ['./shippingpolicy.component.scss']
})
export class ShippingpolicyComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;
  BaseURL = environment.BucketURl;
  constructor() { }

  ngOnInit(): void {
  }

}
