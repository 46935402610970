import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import * as _ from 'underscore';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '../pipe/pipe.module';
import { StepperModule } from '../stepper/stepper.module';
import { AppComponent } from 'app/app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { footerModule } from 'app/shared/footer/footer.module';
import { UltlifestyleComponent } from './ultlifestyle.component';
import { CoreSectionPopupDialogModule } from './powerShakePopup/powerShakePopup.component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SubscriptionTextPopupDialogModule } from './subscriptionTextBox-dialog/subscriptionTextpopup-popup-dialog.module';
import { NgRatingBarModule } from 'ng-rating-bar';
import { CommonModule } from '@angular/common';

const routes: Routes = [
    {
        path: 'Transformation',
        component: UltlifestyleComponent,
        pathMatch: 'full',
    }
];

@NgModule({
    declarations: [
        UltlifestyleComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        MatExpansionModule,
        SummaryCartModule,
        MatTooltipModule,
        FlexLayoutModule,
        PipesModule,
        StepperModule,
        footerModule,
        MatDialogModule,
        SlickCarouselModule,
        CoreSectionPopupDialogModule,
        SubscriptionTextPopupDialogModule,
        NgRatingBarModule,
    ], entryComponents: [
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class  UltlifestyleModule {
}

