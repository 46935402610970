<app-header></app-header>
<div class="page-wrapper subscribe" id="Subscribe-Page">
  <div class="ult-checkout-section wf-section">
    <app-stepper *ngIf="_enrollSession.mobileViewMethod()"></app-stepper>
    <app-stepper class="collection-padding-subscribe" *ngIf="!_enrollSession.mobileViewMethod()"></app-stepper><br>
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" *ngIf="!_enrollSession.mobileViewMethod()" class="banner-images collection-padding-subscribe">
        <img class="banner-image-width" [src]="IMAGE_URL+'/images/Banner/MonthlyWeb.png'">
        <img class="banner-image-width" [src]="IMAGE_URL+'/images/Banner/Calender.png'">
      </div>
      <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-between" *ngIf="_enrollSession.mobileViewMethod()" class="banner-images collection-padding-subscribe">
        <img class="banner-image-widthMob" [src]="IMAGE_URL+'/images/Banner/MonthlySubscription.png'">
        <img class="banner-image-widthMob padding-calender-banner" [src]="IMAGE_URL+'/images/Banner/AutomateYourSuperfood.png'">
      </div>
      <div class="smart-order-text-box">
        <div class="text-wrapper" fxLayoutGap="20px">
          <div class="marquee text-smart-order">Earn 50% more Rewards Points on every order. | Ensure your 25% discount by ordering every 30 days. | Qualify for a free product in your 4th month with 3 & Then Free Products.</div>
          <div class="marquee text-smart-order">Earn 50% more Rewards Points on every order. | Ensure your 25% discount by ordering every 30 days. | Qualify for a free product in your 4th month with 3 & Then Free Products.</div>
          <div class="marquee text-smart-order">Earn 50% more Rewards Points on every order. | Ensure your 25% discount by ordering every 30 days. | Qualify for a free product in your 4th month with 3 & Then Free Products.</div>
        </div>
      </div>
      <div class="processType-Outsidebox">
        <div class="processType-box">
          <div class="processtypeText">Processing Type:</div><br>
          <div>
            <div *ngIf="_enrollSession.mobileViewMethod()" fxLayout.lt-sm="column" fxLayout="row" fxLayoutAlign="space-around center"
              class="">
              <div class="subscription-radio-button">
                <label id="process1" class="flex-radio">
                  <input checked="true" type="radio" [formControl]="AutoOrderProcessTy" value="AutoOrderProcessTy1" (click)="processTypeChange('AutoOrderProcessTy1')"
                ><span class="radio-button-label-text">Always Ship</span>
                </label><img (click)="OpenAlwaysShipPopup(1)" class="margin-info" [src]="IMAGE_URL+'/icons/info 9.png'" width="15">
              </div>
              <div class="subscription-radio-button m-t-5">
                <label id="process2" class="flex-radio">
                  <input type="radio" [formControl]="AutoOrderProcessTy" value="AutoOrderProcessTy2" (click)="processTypeChange('AutoOrderProcessTy2')"
                ><span class="radio-button-label-text">Backup Order</span>
                </label><img (click)="OpenAlwaysShipPopup(2)" class="margin-info" [src]="IMAGE_URL+'/icons/info 9.png'" width="15">
              </div>
            </div>

            <div *ngIf="!_enrollSession.mobileViewMethod()" fxLayout.lt-sm="column" fxLayout="row" fxLayoutAlign="space-around center"
              class="">
              <div class="subscription-radio-button">
                <label id="process1" class="flex-radio">
                  <input checked="true" type="radio" [formControl]="AutoOrderProcessTy" value="AutoOrderProcessTy1" (click)="processTypeChange('AutoOrderProcessTy1')"
                ><span class="radio-button-label-text">Always Ship</span><img (click)="OpenAlwaysShipPopup(1)" class="margin-info" [src]="IMAGE_URL+'/icons/info 9.png'" width="15">
                </label>
              </div>
              <div class="subscription-radio-button">
                <label id="process2" class="flex-radio">
                  <input type="radio" [formControl]="AutoOrderProcessTy" value="AutoOrderProcessTy2" (click)="processTypeChange('AutoOrderProcessTy2')"
                ><span class="radio-button-label-text">Backup Order</span><img (click)="OpenAlwaysShipPopup(2)" class="margin-info" [src]="IMAGE_URL+'/icons/info 9.png'" width="15">
                </label>
              </div>
            </div>
          </div><br>
          <div class="SubscriptionDateText">
            <div class="subscriptionText ">Your Subscription <br *ngIf="_enrollSession.mobileViewMethod()"> Date: {{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate : (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 ? smartOrderDateForLifestyle : '--/--/----')}} <img (click)="SubscriptionInfoPopup()" class="margin-info subscribe-info" [src]="IMAGE_URL+'/icons/info 9.png'" width="15"></div>
            <br>
            <span class="changeDateText cursor-pointer" (click)="SubscriptionDatePopup()">Want to change your date?</span>
            &nbsp;<span class="cursor-pointer" (click)="SubscriptionDatePopup()"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="15" *ngIf="!_enrollSession.mobileViewMethod()"><img [src]="IMAGE_URL+'/icons/edit-text 6.png'" width="15" *ngIf="_enrollSession.mobileViewMethod()"></span>
          </div>
          <div class="NoSubscription">
          <span class="changeDateText color-brown cursor-pointer" (click)="subscribeDialog()">No subscription, please.</span>
          </div>
        </div>
      </div>
    <div class="collection-padding-subscribe">
      <h1 class="checkout-h1 smart-order-h1-choose">Choose your product for your monthly subscription</h1>
      <div class="w-layout-grid enhance-grid-subscribe cen m-b-30" *ngIf="!isDesktopView">
        <div *ngFor="let topPick of topPicks | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()" class="m-b-30">
          <div class="itemBorder" (click)="productInfo(topPick)">
            <img src="{{topPick.SmallPicture}}" loading="lazy"
              class="checkout-product-img checkout-product-img-grid cursor-pointer">
          </div>
          <div class="">
            <div class="checkout-h2 enhance-grid-h2 text-truncate">{{topPick.Description}}
            </div>
          </div>
          <div class="enhance-grid-price mh-22">LOYAL CUSTOMER : ${{topPick.Price |number : '1.2-2'}} USD</div>
          <div class="enhance-grid-price mh-22 r-b">RETAIL : ${{topPick.Other1Price |number : '1.2-2'}} USD</div>
          <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
          <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button hover-white"
            (click)="addToCart(topPick,1,3,sideNav);">Subscribe</a>
        </div>
      </div>
      <div class="w-layout-grid enhance-grid-subscribe" *ngIf="isDesktopView">
        <div *ngFor="let topPick of topPicks | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()" class="m-b-30">
          <div class="itemBorder" (click)="productInfo(topPick)">
            <img src="{{topPick.SmallPicture}}" loading="lazy"
              class="checkout-product-img checkout-product-img-grid cursor-pointer">
          </div>
          <div class="">
            <div class="checkout-h2 enhance-grid-h2 text-truncate">{{topPick.Description}}
            </div>
          </div>
          <div class="enhance-grid-price mh-22">LOYAL CUSTOMER : ${{topPick.Price |number : '1.2-2'}} USD</div>
          <div class="enhance-grid-price mh-22 r-b">RETAIL : ${{topPick.Other1Price |number : '1.2-2'}} USD</div>
          <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
          <a class="button enhance-grid-button w-button hover-white" (click)="addToCart(topPick,1,3,sideNav);">Subscribe</a>
        </div>
      </div>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h1 class="order-summary-h1 all-products-accordion">Alternatives</h1>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="w-layout-grid enhance-grid-subscribe" *ngIf="isDesktopView">
            <div *ngFor="let alternate of alternates | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(alternate)">
                <img src="{{alternate.SmallPicture}}" loading="lazy"
                  class="checkout-product-img checkout-product-img-grid cursor-pointer">
              </div>
              <div class="">
                <div class="checkout-h2 enhance-grid-h2 text-truncate">{{alternate.Description}}
                </div>
              </div>
              <div class="enhance-grid-price mh-22">LOYAL CUSTOMER : ${{alternate.Price |number : '1.2-2'}} USD
              </div>
              <div class="enhance-grid-price mh-22 r-b">RETAIL : ${{alternate.Other1Price |number : '1.2-2'}} USD
              </div>
              <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{alternate.ShortDetail3}}</div>
              <a class="button enhance-grid-button w-button hover-white" (click)="addToCart(alternate,1,3,sideNav);">Subscribe</a>
            </div>
          </div>
          <div class="w-layout-grid enhance-grid-subscribe cen" *ngIf="!isDesktopView">
            <div *ngFor="let alternate of alternates | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(alternate)">
                <img src="{{alternate.SmallPicture}}" loading="lazy"
                  class="checkout-product-img checkout-product-img-grid cursor-pointer">
              </div>
              <div class="">
                <div class="checkout-h2 enhance-grid-h2 text-truncate">{{alternate.Description}}
                </div>
              </div>
              <div class="enhance-grid-price mh-22">LOYAL CUSTOMER : ${{alternate.Price |number : '1.2-2'}} USD
              </div>
              <div class="enhance-grid-price mh-22 r-b">RETAIL : ${{alternate.Other1Price |number : '1.2-2'}} USD
              </div>
              <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{alternate.ShortDetail3}}</div>
              <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button hover-white"
                (click)="addToCart(alternate,1,3,sideNav);">Subscribe</a>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h1 class="order-summary-h1 all-products-accordion">3 & Then Free Products</h1>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="w-layout-grid enhance-grid-subscribe" *ngIf="isDesktopView">
            <div *ngFor="let freeProduct of freeProducts | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(freeProduct)">
                <img src="{{freeProduct.SmallPicture}}" loading="lazy"
                  class="checkout-product-img checkout-product-img-grid cursor-pointer">
              </div>
              <div class="">
                <div class="checkout-h2 enhance-grid-h2 text-truncate">{{freeProduct.Description}}
                </div>
              </div>
              <div class="enhance-grid-price mh-22">LOYAL CUSTOMER : ${{freeProduct.Price |number : '1.2-2'}} USD
              </div>
              <div class="enhance-grid-price mh-22 r-b">RETAIL : ${{freeProduct.Other1Price |number : '1.2-2'}}
                USD</div>
              <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{freeProduct.ShortDetail3}}</div>
              <a class="button enhance-grid-button w-button hover-white" (click)="addToCart(freeProduct,1,3,sideNav);">Subscribe</a>
            </div>
          </div>
          <div class="w-layout-grid enhance-grid-subscribe cen" *ngIf="!isDesktopView">
            <div *ngFor="let freeProduct of freeProducts | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(freeProduct)">
                <img src="{{freeProduct.SmallPicture}}" loading="lazy"
                  class="checkout-product-img checkout-product-img-grid cursor-pointer">
              </div>
              <div class="">
                <div class="checkout-h2 enhance-grid-h2 text-truncate">{{freeProduct.Description}}
                </div>
              </div>
              <div class="enhance-grid-price mh-22">LOYAL CUSTOMER : ${{freeProduct.Price |number : '1.2-2'}} USD
              </div>
              <div class="enhance-grid-price mh-22 r-b">RETAIL : ${{freeProduct.Other1Price |number : '1.2-2'}}
                USD</div>
              <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{freeProduct.ShortDetail3}}</div>
              <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button hover-white"
                (click)="addToCart(freeProduct,1,3,sideNav);">Subscribe</a>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="allProductHeader">
            <mat-panel-title>
              <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start" fxLayoutAlign.gt-xs="space-between none"
                fxFlex>
                <div fxLayoutAlign="start center" fxFlex="100" fxFlex.gt-md="49">
                  <h1 class="order-summary-h1 all-products-accordion">All Purium Products</h1>
                </div>
                <div *ngIf="false" fxLayoutAlign="end center" fxLayoutAlign.lt-lg="center start" fxFlex="100"
                  fxFlex.gt-md="49" class="searchBox" (keydown)="$event.stopImmediatePropagation();"
                  (click)="$event.stopPropagation();">
                  <input class="searchInput" type="text" (input)="onSearchChange($event)"
                    placeholder="Search Your Product" fxFlex>
                  <mat-icon matSuffix>search</mat-icon>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="all-products-accordion-item">
            <div class="w-layout-grid enhance-grid-subscribe"  *ngIf="isDesktopView">
              <div
              *ngFor="let product of smarOrderallProductList | sortBy: 'asc':'Description' | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(product)">
                    <img src="{{product.SmallPicture}}" loading="lazy"
                      id="w-node-_421021f4-112c-5413-f715-320b54c43fe5-db337a59" alt=""
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div>
                    <div id="w-node-_97ec39c2-7213-1023-164a-ea6b31ad721f-db337a59"
                      class="checkout-h2 enhance-grid-h2 text-truncate">
                      {{product.Description}}
                    </div>
                  </div>
                  <div id="w-node-cd5718ae-6526-5955-b798-aee0dccf7998-db337a59">
                    <div class="enhance-grid-price mh-22">LOYAL CUSTOMER : ${{product.Price |number : '1.2-2'}} USD</div>
                    <div class="enhance-grid-price mh-22 r-b">RETAIL : ${{product.Other1Price |number : '1.2-2'}} USD</div>
                  <a id="w-node-_263a773b-b3b9-3901-44ef-9e82a85a78f1-db337a59"
                    class="button enhance-grid-button w-button hover-white" (click)="addToCart(product,1,3)">Add To Cart</a>
                </div>
              </div>
            </div>
            <div class="w-layout-grid cen enhance-grid-subscribe enhance-grid-mobile" *ngIf="!isDesktopView">
              <div
                *ngFor="let product of smarOrderallProductList | sortBy: 'asc':'Description' | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()">
                <div class="itemBorder" (click)="productInfo(product)">
                  <img src="{{product.SmallPicture}}" loading="lazy"
                    class="checkout-product-img checkout-product-img-grid cursor-pointer">
                </div>
                <div class="">
                  <div class="checkout-h2 enhance-grid-h2 text-truncate">{{product.Description}}
                  </div>
                </div>
                <h3 class="enhance-grid-price mh-22 ">LOYAL CUSTOMER :${{product.Price |number : '1.2-2'}} USD
                </h3>
                <h3 class="enhance-grid-price mh-22 r-b">RETAIL : ${{product.Other1Price |number : '1.2-2'}} USD
                </h3>
                <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59"
                  class="button enhance-grid-button w-button m-b-10 hover-white" (click)="addToCart(product,1,3,sideNav);">Add To Cart</a>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <app-footercomponent></app-footercomponent>
    </div>
  </div>
</div>