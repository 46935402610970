import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMAGE_URL } from 'app/constants';

@Component({
  selector: 'app-footercomponent',
  templateUrl: './footercomponent.component.html',
  styleUrls: ['./footercomponent.component.scss']
})
export class FootercomponentComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;

  constructor(
    public _router: Router,
    private _activateRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  navigateToIncomeOpportunity(): void {
    const currentQueryParams = this._activateRoute.snapshot.queryParams;
    const queryParamsString = new URLSearchParams(currentQueryParams).toString();
    let fullUrl = '/IncomeOpportunity';
    if (queryParamsString) {
      fullUrl += `?${queryParamsString}`;
    }

    // Open the constructed URL in a new tab
    window.open(fullUrl, '_blank');
  }

}
