import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';
import { IMAGE_URL } from 'app/constants';

@Component({
  selector: 'powerShakePopup-dialog',
  templateUrl: './powerShakePopup.component.html',
  styleUrls: ['./powerShakePopup.component.scss']
})
export class CoreSectionPopupDialogComponent implements OnInit {
  currentRouterPath: any;
  headingText: any;
  descriptionText:any;
  smallimageUrl: any;
  bigImage:any;
   IMAGE_URL = IMAGE_URL;
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<CoreSectionPopupDialogComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService) {
  }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(){
    var id = this.data[1];
    switch(id) {
      case 1:
        this.headingText = 'Power Shake';
        this.descriptionText = 'Nourishing Greens';
        this.smallimageUrl = this.data[0].image;
        this.bigImage = `${IMAGE_URL}/images/ultlifestyleLanding/PowerShakePopupImage.png`;
        break;
      case 2:
        this.headingText = 'Super Amino 23';
        this.descriptionText = 'Build Lean <br> Muscle';
        this.smallimageUrl = this.data[0].image;
        this.bigImage = `${IMAGE_URL}/images/ultlifestyleLanding/Amino23PopupImage.png`;  
        break;
      case 3:
        this.headingText = 'Biome Medic';
        this.descriptionText = 'Supports a Healthy <br> Gut Microbiome';
        this.smallimageUrl = this.data[0].image;
        this.bigImage = `${IMAGE_URL}/images/ultlifestyleLanding/BioMedicPopupImage.png`;
        break;
      case 4:
        this.headingText = 'Apothe-cherry';
        this.descriptionText = 'Anti-Aging Sleep<br> Support';
        this.smallimageUrl = this.data[0].image;
        this.bigImage = `${IMAGE_URL}/images/ultlifestyleLanding/ApothoCherryPopupImage.png`;
        break;
      case 5:
        this.headingText = 'Super CleansR';
        this.descriptionText = 'Parasite Cleanse';
        this.smallimageUrl = this.data[0].image;
        this.bigImage = `${IMAGE_URL}/images/ultlifestyleLanding/SuperCleanersPopupImage.png`;
        break;
      default:
    }
  }



  closePopup(){
    this.dialogRef.close();
  }
}
